<template>
  <div class="submit-modal">
    <div class="submit-modal__wrapper">
      <h2 class="camembert mb-6 titanic--text">
        Выбрать другого исполнителя?
      </h2>
      <p class="mb-8 parmigiano">
        Сейчас обращение в работе у исполнителя {{ executorName }}.
      </p>
      <div class="submit-modal__buttons">
        <r-button
          class="submit-modal__button"
          @click="$root.$modal.closeModal()"
          color="secondary"
          title="Нет, вернуться назад"
        > 
        </r-button>
        <r-button
          class="submit-modal__button ml-8"
          @click="sendForm"
          :isLoading="sending"
          :disabled="sending"
          title="Да, выбрать другого"
        >
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import InfoModal from '@/views/incident/modal/InfoModal';
import { mapActions } from 'vuex';

export default {
  name: 'SubmitModal',
  props: {
    formData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    executorName: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      sending: false,
    };
  },
  methods: {
    ...mapActions('incident', ['getExecutor', 'moveIncident']),
    async sendForm() {
      this.sending = true;
      await this.moveIncident([this.formData])
        .then(res => {
          this.$root.$modal.openModal(InfoModal, {
            icon: 'selected',
            title: 'Обращение запущено в работу'
          });
        })
        .catch(() => {
          this.$root.$modal.openModal(InfoModal, {
            icon: 'close',
            fill: 'fargo',
            title: 'Ответ на обращение не отправлен',
            subtitle: `Произошла внутренняя ошибка сервера во время отправки.
        Пожалуйста, повторите отправку. Если проблема повторится, то обратитесь в службу поддержки.`
          });
        });
      this.sending = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.submit-modal {
  &__wrapper {
    margin: auto;
    max-width: 550px;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    flex: 1;
  }
}
</style>

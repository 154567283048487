import IncidentListToolbar from '@/views/incident/toolbars/IncidentListToolbar';
import IncidentViewToolbar from '@/views/incident/toolbars/IncidentViewToolbar';
import IncidentView from '@/views/incident/IncidentView';
import IncidentList from '@/views/incident/IncidentList';
import DefaultLayout from '@/layout/DefaultLayout';
import { STATES } from '@/constants';

export const incidentCompletedRoutes = [
  {
    path: '/incident-completed',
    name: 'incident-completed',
    components: {
      default: IncidentList,
      toolbar: IncidentListToolbar
    },
    meta: {
      type: STATES.SOLVED,
      sortBy: 'UPDATED_AT',
      title: 'Решенные обращения',
      layout: DefaultLayout
    }
  },
  {
    path: '/incident-completed/:id',
    name: 'incident-completed-view',
    components: {
      default: IncidentView,
      toolbar: IncidentViewToolbar
    },
    meta: {
      layout: DefaultLayout,
      type: STATES.SOLVED
    }
  },
  {
    path: '/incident-completed/:id/more/:moreId?',
    name: 'incident-completed-view-more',
    components: {
      default: IncidentView,
      toolbar: IncidentViewToolbar
    },
    meta: {
      layout: DefaultLayout,
      type: STATES.SOLVED
    }
  }
];

<template>
  <section class="sidebar">
    <div
      class="sidebar__title"
      :class="{'sidebar__title--shadowed': scrollTop > 0}"
    >
      <span
        v-if="isSidebarOpen"
        class="taleggio ameile--text"
      >
        Рабочее место <br> координатора/модератора
      </span>
    </div>
    <div
      class="sidebar__menu"
      ref="menu"
    >
      <router-link
        v-for="item in menuItems"
        :key="item.title"
        :to="{name: item.route}"
        class="sidebar__item"
        :class="{disabled: !!item.isDisabled}"
      >
        <div
          v-if="isSidebarOpen"
          class="sidebar__item-wrapper"
          :class="{active: $route.name.match(item.route)}"
        >
          <r-icon
            :icon="item.icon"
            fill="amelie"
          />
          <span class="sulguni">{{ item.title }}</span>
          <span
            v-if="item.counter"
            class="sidebar__count"
            :class="appealSystemCounter[item.counter].total.exp ? 'fargo--text' : 'rocky--text'"
          >
            {{ showCount(appealSystemCounter[item.counter].total.count) || '' }}
          </span>
        </div>

        <tooltip
          v-else
          sidebar
        >
          <div
            class="sidebar__item-wrapper collapsed"
            :class="{active: $route.name.match(item.route)}"
            slot="activator"
          >
            <r-icon
              class="sidebar__menu-item-icon"
              :icon="item.icon"
              fill="amelie"
            />
            <div
              v-if="item.counter && appealSystemCounter[item.counter].total.count"
              class="sidebar__count--small"
              :class="{'sidebar__count--expired': appealSystemCounter[item.counter].total.exp}"
            />
          </div>
          <span class="mozzarella titanic--text">{{ item.title }}</span>
        </tooltip>
      </router-link>

      <!-- issue collector -->
      <div v-if="isSidebarOpen">
        <div
          class="sidebar__report mt-6"
          @click="report"
        >
          <r-icon
            icon="complaint"
            fill="rocky"
          />
          <span class="sulguni rocky--text">Сообщить об ошибке</span>
        </div>
      </div>

      <tooltip
        v-else
        sidebar
        title="Сообщить об ошибке"
      >
        <div
          class="sidebar__report sidebar__report--small mt-6"
          slot="activator"
          @click="report"
        >
          <r-icon
            fill="rocky"
            class="sidebar__item-icon"
            icon="complaint"
          />
        </div>
      </tooltip>
    </div>
    <div
      class="sidebar__logo"
      :class="{'sidebar__logo--shadowed': scrollBottom > 0}"
    >
      <div
        v-if="isSidebarOpen"
        class="sidebar__logo-svg"
      />
    </div>
    <!--    <div class="sidebar__version marynae amelie&#45;&#45;text">{{ version }}</div>-->
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Tooltip from '@/components/Tooltip';

export default {
  name: 'Sidebar',
  components: { Tooltip },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: null,
      scrollTop: null,
      scrollBottom: null
    };
  },
  computed: {
    ...mapState('directory', ['appealSystemCounter']),
    ...mapState('incident', ['isLoading', 'isSelectionMode']),
    menuItems() {
      return [
        {
          title: 'Создать',
          icon: 'add',
          route: 'create',
          isDisabled: this.isSelectionMode
        },
        {
          title: 'Новые',
          icon: 'new',
          route: 'incident-new',
          counter: 'NEW',
          isDisabled: this.isLoading
        },
        {
          title: 'В работе',
          icon: 'in-progress',
          route: 'incident-work',
          counter: 'IN_WORK',
          isDisabled: this.isLoading
        },
        {
          title: 'Ожидают решения',
          icon: 'waiting',
          route: 'incident-analyze',
          counter: 'WAITING_DECISION',
          isDisabled: this.isLoading
        },
        {
          title: 'Дозапрос',
          icon: 'help',
          route: 'incident-additional-question',
          counter: 'ADDITIONAL_QUESTION',
          isDisabled: this.isLoading
        },
        {
          title: 'Отложенные',
          icon: 'timer',
          route: 'incident-paused',
          counter: 'PAUSED',
          isDisabled: this.isLoading
        },
        {
          title: 'Ошибка отправки',
          icon: 'warning',
          route: 'incident-error',
          counter: 'ERROR',
          isDisabled: this.isLoading || this.isSelectionMode
        },
        {
          title: 'Решённые',
          icon: 'selection',
          route: 'incident-completed',
          counter: 'SOLVED',
          isDisabled: this.isLoading || this.isSelectionMode
        },
        {
          title: 'Удалённые',
          icon: 'delete',
          route: 'incident-deleted',
          isDisabled: this.isLoading || this.isSelectionMode
        },
        {
          title: 'Статистика',
          icon: 'chart',
          route: 'statistics',
          isDisabled: this.isSelectionMode
        },
        {
          title: 'Настройки',
          icon: 'settings',
          route: 'settings',
          isDisabled: this.isSelectionMode
        }
      ];
    }
  },
  mounted() {
    this.menu = this.$refs.menu;
    this.menu.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy() {
    this.menu.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    showCount(count) {
      const value = count > 999 ? '999+' : count;
      return value;
    },
    report() {
      window.showCollectorDialog();
    },
    scrollHandler() {
      this.scrollTop = this.menu.scrollTop;
      this.scrollBottom = this.menu.scrollHeight - this.menu.scrollTop - this.menu.clientHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: overlay;
  transition: color .3s ease;
  color: rgba(#E4EDFB, 0);

  &:hover {
    color: rgba(#E4EDFB, 0.48);
  }

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    box-shadow: inset 0 0 0 10px;
    border-radius: 9999px;
    background: none;
  }

  &__title{
    position: relative;
    height: 112px;
    transition: box-shadow 0.3s ease;

    span {
      display: block;
      margin: 32px;
      white-space: nowrap;
      color: var(--rir-amelie);
    }

    &--shadowed {
      box-shadow: 0px 10px 36px rgba(4, 21, 62, 0.72);
    }
  }
  &__logo {
    position: relative;
    height: 112px;
    transition: box-shadow 0.3s ease;

    &-svg {
      margin: 32px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: start;
      background-image: url("/img/img/logo.svg");
    }

    &--shadowed {
      box-shadow: 0px 10px 36px rgba(4, 21, 62, 0.72);
    }
  }
  &__version{
    margin-top: 12px;
    text-align: center;
  }
  &__menu {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__item {
    &-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 44px;
      padding-left: 32px;
      max-height: 48px;

      &.collapsed {
        padding-left: 24px;
      }

      & path {
        //@include fill-opacity($white, 0.72)
        fill: var(--rir-amelie);
        opacity: .72;
      }
      & span {
        margin-left: 12px;
        color: var(--rir-amelie);
        opacity: .72;
        white-space: nowrap;
      }
      &:hover {
        background-color: var(--rir-interstellar);

        span {
          color: var(--rir-amelie);
          opacity: 1;
        }

        & path {
          opacity: 1;
        }
      }

      &.active{
        position: relative;
        width: 100%;
        background-color: var(--rir-titanic);

        span {
          color: var(--rir-amelie);
          opacity: 1;
        }

        & path {
          opacity: 1;
        }

        &::before {
          position: absolute;
          left: -4px;
          display: block;
          width: 8px;
          height: 32px;
          content: '';
          background-color: var(--rir-rocky);
          border-radius: 5px;
        }
      }
    }
  }

  &__report {
    height: fit-content;
    padding: 12px 16px 12px 32px;
    width: 100%;
    cursor: pointer;
    transition: padding .3s ease;
    display: flex;
    align-items: center;

    & span {
      margin-left: 12px;
      white-space: nowrap;
    }

    &--small {
      padding: 12px 16px 12px 24px;
    }
  }

  &__count {
    position: absolute;
    right: 16px;
    font-size: 13px;

    &--small {
      position: absolute;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      right: 14px;
      top: 8px;
      background: var(--rir-rocky);
      box-shadow: 0px 2px 8px rgba(61, 117, 228, 0.64);
    }

    &--expired {
      background: var(--rir-fargo);
      box-shadow: 0px 2px 8px rgba(238, 88, 126, 0.64);
    }
  }
}

.disabled {
  opacity: 0.72;
  cursor: auto;
  pointer-events: none;
}
</style>

export default {
  loadDirectories({ commit, rootGetters }) {
    rootGetters.GET('executor').then(data => {
      commit('setProperty', { name: 'executors', value: data });
    });
    rootGetters.GET('ui/appeal-system').then(data => {
      commit('setProperty', { name: 'messageSources', value: data });
    });
    rootGetters.GET('ui/problem').then(data => {
      commit('setProperty', { name: 'problems', value: data });
    });
    rootGetters.GET('ui/department').then(data => {
      commit('setProperty', { name: 'departments', value: data });
    });
    rootGetters.GET('ui/appeal-tag').then(data => {
      commit('setProperty', { name: 'tags', value: data });
    });
    rootGetters.GET('ui/account-channel', { systemCode: 'FRONT' }).then(data => {
      commit('setProperty', { name: 'appealTypes', value: data });
    });
    rootGetters.GET('ui/valid-time').then(data => {
      commit('setProperty', { name: 'validTime', value: data });
    });
    rootGetters.GET('ui/appeal-count-by-system').then(data => {
      commit('setProperty', { name: 'appealSystemCounter', value: data });
    });
    rootGetters.GET('ui/appeal-tag').then(data => {
      commit('setProperty', { name: 'tags', value: data });
    });
  },
  getAppealSystem({ commit, rootGetters }) {
    rootGetters.GET('ui/appeal-system').then(data => {
      commit('setProperty', { name: 'messageSources', value: data });
    });
  },
  async getDivision({ commit, rootGetters }, payload) {
    return await rootGetters.GET('common/division', payload).then(data => {
      commit('setProperty', { name: 'divisions', value: data });
    });
  },
  async getCountBySystem({ commit, rootGetters }) {
    return rootGetters.GET('ui/appeal-count-by-system').then(data => {
      commit('setProperty', { name: 'appealSystemCounter', value: data });
    });
  },
  async getTagsList({ commit, rootGetters }, payload) {
    return rootGetters.GET('ui/appeal-tag', payload).then(data => {
      commit('setProperty', { name: 'tags', value: data });
    });
  },
  async getAccounts({ commit, rootGetters }, payload) {
    return rootGetters.GET('ui/account', payload).then(data => {
      commit('setProperty', { name: 'accounts', value: data });
    });
  },

  // getAppealSystemCounter ({ commit, rootGetters }, payload) {
  //   rootGetters.GET('ui/appeal-system/counter', payload).then(data => {
  //     commit('setProperty', { name: 'appealSystemCounter', value: data })
  //   })
  // }
};

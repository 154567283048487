export default {
  async getValidTime({ rootGetters, commit }) {
    const res = await rootGetters.GET('ui/valid-time');
    commit('setProperty', { name: 'timeList', value: res });
  },
  async updateValidTime({ rootGetters, commit }, payload) {
    commit('setProperty', { name: 'updatingValidTime', value: true });
    await rootGetters.POST('ui/valid-time', payload);
    commit('setProperty', { name: 'updatingValidTime', value: false });
  }
};

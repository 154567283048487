<template>
  <div
    class="form-group__wrapper"
    :class="{ error: error }"
  >
    <slot
      :id="id"
      :hasError="!!error"
    />
    <template v-if="error && errorMessage">
      <div class="invalid-message">
        {{ errorMessage }}
      </div>
    </template>
    <slot name="after"></slot>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import validateMixin from '@/plugins/vuelidate/validateMixin'

  export default {
    name: 'FormGroup',
    mixins: [validationMixin, validateMixin],
    props: {
      error: {
        type: Boolean,
        default: false, 
      },
      errorMessage: {
        type: String,
        default: '',
      },
      attributes: {
        type: Object,
        default: () => ({}), 
      },
    },
    computed: {
      id () {
        return `field-${this.$nanoid()}`
      }
    },
    watch: {
      errorMessage (val) {
        console.log('err', val)
      }
    }
  }
</script>

<style scoped lang="scss">
  .form-group {
    &__wrapper {
      width: 100%;

      .invalid-message {
        color: var(--rir-fargo);
        font-size: 14px;
        line-height: normal;
      }
    }
  }
</style>

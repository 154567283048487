<template>
  <div class="right-side-notification">
    <transition-group name="slide-fade">
      <div
        v-for="overdue in overdueList"
        :key="overdue.appealId"
        class="right-side-notification__item"
        @click="openIncident(overdue)"
      >
        <r-spinner
          v-if="loading === overdue.appealId"
          :size="32"
        />
        <div v-else>
          <p class="right-side-notification__item-title bryndza mb-1">
            {{ overdue.text }}
          </p>
          <p class="right-side-notification__item-date mozzarella anie">
            {{ overdue.ts | dayMonthHours }}
          </p>
        </div>
        <r-icon
          fill="rocky"
          @click.native.stop="closeOverdue(overdue.appealId)"
          class="right-side-notification__item-icon ml-6 ekas"
          icon="close"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { StatesRoutes } from '@/constants';

export default {
  name: 'RightSideNotification',
  data() {
    return {
      loading: null
    };
  },
  methods: {
    ...mapMutations('incident', ['removeOverdue']),
    ...mapActions('incident', ['getIncident']),
    closeOverdue(id) {
      this.removeOverdue({ id });
    },
    async openIncident(overdue) {
      this.loading = overdue.appealId;
      const incident = await this.getIncident({ id: overdue.appealId });
      this.$router.push(`/${StatesRoutes[incident.category]}/${incident.id}`);
      this.loading = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.right-side-notification {
  right: 32px;
  top: 20px;
  position: fixed;
  z-index: 9999;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--rir-amelie);
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    border-radius: 16px;
    padding: 12px 20px;
    height: 64px;
    cursor: pointer;
    margin-bottom: 8px;

    &-title {
      transition: 0.3s ease;
    }
    &-date {
      transition: 0.3s ease;
    }
    &-icon {
      &:hover {
        opacity: 1;
      }
    }

    &:hover {
      .right-side-notification__item {
        &-title {
          color: var(--rir-interstellar);
        }
        &-date {
          color: var(--rir-godfather);
          opacity: 0.72;
        }
      }
    }
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: .3s ease;
  }
  .slide-fade-enter {
    transform: translateX(10px);
    opacity: 0;
  }
  .slide-fade-leave-to {
    padding: 0;
    opacity: 0;
    height: 0;
    margin: 0;

    p {
      font-size: 0;
    }
  }
}
</style>

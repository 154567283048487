<template>
  <div class="incident-postpone">
    <FormDateTimePicker
      class="mb-8"
      :disabled="isDisabledDatePicker"
      v-model="deadline"
      label="Отложить обращение до"
      :startDate="new Date()"
      :error="$v.deadline.$error"
    />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required } from '@/plugins/vuelidate/customValidate'
import { mapState } from 'vuex'
import { DELEGATION_ACTIONS } from '@/constants'
import FormDateTimePicker from "@/components/FormDateTimePicker.vue";

export default {
  name: 'IncidentPostpone',
  mixins: [validationMixin, validateMixin],
  components: { FormDateTimePicker },
  props: {
    incident: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      deadline: null
    }
  },
  computed: {
    ...mapState('incident', ['appealActions']),
    isDisabledDatePicker () {
      return this.appealActions?.publication?.length > 1 && this.incident.delegationAction === DELEGATION_ACTIONS.DELEGATE_APPEAL
    }
  },
  validations: {
    deadline: {
      required: required('Обязательное поле')
    }
  }
}
</script>

<template>
  <div class="settings-view mb-6">
    <h4 class="mb-6 burrata">
      Время реакции
    </h4>
    <r-button-action
      class="mb-6"
      title="Добавить"
      icon="add"
      @click="addNewTime"
    />
    <div class="settings-view__table">
      <div class="settings-view__table-header mb-4">
        <span class="settings-view__table-position mozzarella anie">
          Позиция в списке
        </span>
        <span class="mozzarella anie ml-6">
          Время реакции
        </span>
      </div>
      <draggable
        class="settings-view__items"
        draggable=".settings-view__item"
        v-bind="dragOptions"
        :list="validTime"
      >
        <div
          v-for="(item, index) in validTime"
          :key="item.id"
          class="settings-view__item"
        >
          <div class="settings-view__item-position">
            <r-icon
              class="settings-view__item-burger"
              icon="menu"
              fill="titanic"
            />
            <span class="feta anie ml-6">{{ index }}</span>
          </div>
          <div class="settings-view__item-time">
            <div v-if="editableItem === item.id">
              <reaction-time
                class="settings-view__reaction-time"
                :time="time"
                @change="changeTime"
              />
            </div>
            <span
              v-else
              class="sulguni ekas"
            >{{ item.value | formatDuration }}</span>
          </div>
          <div class="settings-view__item-actions">
            <div v-show="editableItem === item.id">
              <r-icon
                fill="rocky"
                class="settings-view__item-btn"
                icon="selected"
                @click.native="submitEditing(item.id)"
              />
              <r-icon
                class="settings-view__item-btn ml-8"
                icon="close"
                fill="fargo"
                @click.native="closeEditing"
              />
            </div>
            <div v-show="editableItem !== item.id">
              <r-icon
                fill="rocky"
                class="settings-view__item-btn"
                icon="edit"
                @click.native="editItem(item)"
              />
              <r-icon
                class="settings-view__item-btn ml-8"
                icon="delete"
                fill="fargo"
                @click.native="removeItem(item.id)"
              />
            </div>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions, mapState } from 'vuex';
import ReactionTime from '@/components/ReactionTime';
import moment from 'moment';

export default {
  name: 'SettingsView',
  components: {
    draggable,
    ReactionTime
  },
  data() {
    return {
      time: {
        days: null,
        hours: null,
        minutes: null
      },
      editableItem: null,
      modifiedTime: null,
      validTime: []
    };
  },
  computed: {
    ...mapState('settings', ['timeList']),
    dragOptions() {
      return {
        animation: 300,
        group: 'slides',
        ghostClass: 'settings-view__item--ghost',
        chosenClass: 'settings-view__item--chosen',
        forceFallback: true
      };
    }
  },
  async mounted() {
    await this.getValidTime();
    this.validTime = this.timeList.map(el => ({
      id: this.$nanoid(),
      value: el.value
    }));
    window.onbeforeunload = () => {
      this.addNewValidTime();
    };
  },
  beforeDestroy() {
    this.addNewValidTime();
  },
  methods: {
    ...mapActions('settings', ['getValidTime', 'updateValidTime']),
    addNewTime() {
      const item = {
        id: this.$nanoid(),
        value: this.timeList.length
          ? this.timeList[this.timeList.length - 1].value + 1
          : 600000
      };
      this.validTime.unshift(item);
    },
    addNewValidTime() {
      const timeList = this.validTime.map(el => {
        delete el.id;
        return el;
      });
      this.updateValidTime(timeList);
    },
    submitEditing(id) {
      if (this.modifiedTime) {
        this.validTime = this.validTime.map(el => (el.id === id
          ? {
            id: el.id,
            value: this.modifiedTime
          }
          : el));
      }
      this.editableItem = null;
      this.modifiedTime = null;
    },
    closeEditing() {
      this.editableItem = null;
    },
    editItem(item) {
      this.time.days = null;
      this.time.hours = null;
      this.time.minutes = null;
      const duration = moment.duration(item.value);
      if (duration.days() >= 1) {
        const days = Math.floor(duration.days());
        this.time.days = days;
      }
      if (duration.hours() >= 1) {
        const hours = Math.floor(duration.hours());
        this.time.hours = hours;
      }
      if (duration.minutes() >= 1) {
        const minutes = Math.floor(duration.minutes());
        this.time.minutes = minutes;
      }
      this.editableItem = item.id;
    },
    changeTime(time) {
      this.time = time;
      const days = time.days ? time.days * 86400000 : 0;
      const hours = time.hours ? time.hours * 3600000 : 0;
      const minutes = time.minutes ? time.minutes * 60000 : 0;
      this.modifiedTime = days + hours + minutes;
    },
    removeItem(id) {
      this.validTime = this.validTime.filter(el => el.id !== id);
    }
  }
};
</script>

<style lang="scss" scoped>
.settings-view {
  padding: 0 32px;

  &__report {
    position: absolute;
    bottom: 24px;
    left: 24px;
  }

  &__action-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    span {
      color: var(--rir-rocky)
    }

    &:hover {
      &::v-deep {
        & path {
          fill: var(--rir-rocky--hover);
        }
      }

      span {
        color: var(--rir-rocky--hover);
      }
    }
  }

  &__table {
    &-position {
      width: 140px;
    }
  }

  &__reaction-time {
    padding-right: 16px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 72px;
    border-top: 1px solid var(--rir-arrival);

    &--ghost {
      background: var(--rir-rocky);
      border-radius: 8px;
      opacity: 0.08;
    }

    &--chosen {
      cursor: grabbing;
      background: #e8edf7;
      border-radius: 8px;
    }

    &-btn {
      cursor: pointer;
    }

    &-burger {
      opacity: 0.16;
    }

    &-position {
      display: flex;
      align-items: center;
      width: 140px;
    }
    &-time {
      flex: 1;
    }
    &-actions {
      flex: 2;
    }
  }
}
</style>

<template>
  <div class="action-buttons">
    <div
      v-if="!executionType && !unavailable && showActionsButton"
      class="action-buttons__actions mb-8"
    >
      <r-button
        v-for="(action, i) in croppedActions"
        :key="action"
        class="action-buttons__btn"
        :class="{'ml-6': i > 0}"
        :type="i === 0 ? 'primary' : 'secondary'"
        @click="perform(action)"
        :title="getTitle(action)"
        :icon="getIcon(action)"
      />
      <div
        v-if="innerActions && innerActions.length"
        class="action-buttons__context ml-6"
        v-inner-click-outside="closeActionsPopover"
      >
        <r-button-simple
          size="larishae"
          type="secondary"
          icon-size="20"
          icon="menu-context"
          @click="toggleActionsPopover"
        />
        <actions-list
          class="action-buttons__popover"
          :show="showActionsPopover"
          :actions="innerActions"
          @perform="perform"
          @close="closeActionsPopover"
        />
      </div>
    </div>
    <div class="d-flex">
      <r-button
        v-if="showRestoreButton"
        class="action-buttons__btn mb-10"
        type="secondary"
        @click="restore"
        icon="update"
        :isLoading="pending"
        :disabled="pending"
        title="Восстановить"
      />
      <r-button
        v-if="unavailable && inProgress"
        class="action-buttons__btn"
        :isLoading="completeButtonLoading"
        :disabled="completeButtonLoading"
        @click="moveToResolved"
        icon="selection"
        title="Завершить"
      />
    </div>
    <div
      v-if="showErrorButtons"
      class="d-flex"
    >
      <r-button
        class="action-buttons__btn"
        type="secondary"
        :isLoading="retryButtonLoading"
        :disabled="retryButtonLoading"
        @click="returnSendIncident"
        icon="update"
        title="Повторить отправку"
      />
      <r-button
        class="action-buttons__btn ml-6"
        :isLoading="completeButtonLoading"
        :disabled="completeButtonLoading"
        @click="toComplete"
        icon="selection"
        title="Завершить без ответа"
      />
    </div>
  </div>
</template>

<script>
import ActionsList from '@/views/incident/components/ActionsList';
import InfoModal from '@/views/incident/modal/InfoModal';
import { mapState, mapActions, mapMutations } from 'vuex';
import { ActionIcons, ActionTitles, ACTIONS, STATES } from '@/constants';

export default {
  name: 'ActionButtons',
  components: { ActionsList },
  props: {
    incident: {
      type: Object
    },
    actions: {
      type: Array
    }
  },
  data() {
    return {
      showActionsPopover: false,
      pending: false,
      completeButtonLoading: false,
      retryButtonLoading: false,
      croppedActions: [],
      innerActions: []
    };
  },
  computed: {
    ...mapState('session', ['user']),
    ...mapState('incident', ['executionType']),
    showRestoreButton() {
      return this.$route.meta.type === STATES.REMOVED;
    },
    showErrorButtons() {
      return this.$route.meta.type === STATES.ERROR;
    },
    showActionsButton() {
      return [
        STATES.NEW,
        STATES.IN_WORK,
        STATES.WAITING_DECISION,
        STATES.ADDITIONAL_QUESTION,
        STATES.PAUSED
      ].includes(this.$route.meta.type);
    },
    inProgress() {
      return this.$route.meta.type !== STATES.REMOVED
        && this.$route.meta.type !== STATES.SOLVED
        && this.$route.meta.type !== STATES.ERROR;
    },
    unavailable() {
      return this.croppedActions.includes(ACTIONS.MOVE_TO_RESOLVED);
    },
    isMedialogy() {
      return ['MEDIALOGIA-IM'].includes(this.incident.systemCode);
    }
  },
  watch: {
    actions: {
      handler() {
        this.innerActions = this.actions?.length ? [...this.actions] : [];
        this.sortActions();
        this.croppedActions = this.innerActions.splice(0, 2);
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('incident', ['restoreIncident', 'moveIncident']),
    ...mapMutations('apps', ['setAnchor']),
    sortActions() {
      if (this.innerActions.includes(ACTIONS.EXECUTE_APPEAL)) {
        const two = ACTIONS.EXECUTE_APPEAL;
        this.innerActions.sort((x, y) => (x === two ? -1 : y === two ? 1 : 0));
      }
      if (this.innerActions.includes(ACTIONS.COMPLETE_APPEAL || ACTIONS.REPLY_DELEGATED_APPEAL || ACTIONS.COMPLETE_WITHOUT_RESPONSE)) {
        const first = this.innerActions.includes(ACTIONS.COMPLETE_APPEAL)
          ? ACTIONS.COMPLETE_APPEAL
          : this.innerActions.includes(ACTIONS.REPLY_DELEGATED_APPEAL)
            ? ACTIONS.REPLY_DELEGATED_APPEAL
            : ACTIONS.COMPLETE_WITHOUT_RESPONSE;
        this.innerActions.sort((x, y) => (x === first ? -1 : y === first ? 1 : 0));
      }
    },
    getIcon(action) {
      return ActionIcons[action];
    },
    getTitle(action) {
      return ActionTitles[action];
    },
    toggleActionsPopover() {
      this.showActionsPopover = !this.showActionsPopover;
    },
    closeActionsPopover() {
      this.showActionsPopover = false;
    },
    perform(type) {
      this.$emit('perform', type);
    },
    async restore() {
      this.pending = true;
      await this.restoreIncident({ id: this.$route.params.id })
        .then(() => {
          this.$root.$modal.openModal(InfoModal, {
            icon: 'selected',
            title: 'Обращение успешно восстановлено'
          });
        })
        .catch(() => {
          this.$root.$modal.openModal(InfoModal, {
            icon: 'close',
            fill: 'fargo',
            title: 'Обращение не восстановлено',
            subtitle: `Произошла внутренняя ошибка сервера во время отправки.
        Пожалуйста, повторите отправку. Если проблема повторится, то обратитесь в службу поддержки.`
          });
        });
      this.pending = false;
    },
    async performMoveIncident({ decision, success, error }) {
      await this.moveIncident([{
        id: this.incident.id,
        statusCode: this.incident.action,
        moderatorId: this.user.id,
        decision
      }])
        .then(() => {
          this.$rir.notification.send({
            title: success,
            seconds: 5
          })
          this.setAnchor(0);
          const path = this.$route.path?.split('/');
          this.$router.push(`/${path[1]}`);
        })
        .catch(() => {
          this.$rir.notification.send({
            title: error,
            seconds: 5
          })
        });
    },
    async returnSendIncident() {
      this.retryButtonLoading = true;
      await this.performMoveIncident({
        decision: 'RETRY',
        success: 'Повторная попытка отправки',
        error: 'Ошибка повторной отправки'
      });
      this.retryButtonLoading = false;
    },
    async toComplete() {
      this.completeButtonLoading = true;
      await this.performMoveIncident({
        decision: 'COMPLETE_WITHOUT_RESPONSE',
        success: 'Обращение перемещено в "Решенные"',
        error: 'Не удалось перемеместить в "Решенные"'
      });
      this.completeButtonLoading = false;
    },
    async moveToResolved() {
      this.completeButtonLoading = true;
      await this.performMoveIncident({
        decision: ACTIONS.MOVE_TO_RESOLVED,
        success: 'Обращение перемещено в "Решенные"',
        error: 'Не удалось перемеместить в "Решенные"'
      });
      this.completeButtonLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.action-buttons {
  margin-bottom: 32px;
  &__actions {
    display: flex;
  }

  &__btn {
    flex: 1;
  }

  &__context {
    position: relative;
  }

  &__popover {
    position: absolute;
    bottom: 48px;
    left: 0;
    z-index: 90;
  }
}
</style>

<template>
  <div
    class="separate-modal"
    id="modal"
  >
    <div class="separate-modal__wrapper">
      <div class="separate-modal__header">
        <div class="separate-modal__title">
          <h2
            class="camembert mb-4 titanic--text"
          >
            Разъединение обращений
          </h2>
          <p class="mb-4 parmigiano">
            Выберите обращения, которые необходимо отсоединить от объединённого,
            {{ selectedIncidents ? `(выбрано ${selectedIncidents.length})` : '' }}
          </p>
          <r-input
            v-model="searching"
            @input="debounceInput"
            label="Поиск"
          />
        </div>
      </div>

      <section class="mb-6">
        <div class="d-flex align-items-center mb-4">
          <p class="feta">Главное обращение</p>
          <div
            @mouseenter="toggleTooltip"
            @mouseleave="closeTooltip"
            class="separate-modal__tooltip-activator"
          >
            <r-icon
              class="ml-2"
              fill="rocky"
              icon="info"
              size="16"
            />
            <transition name="fade">
              <div
                v-if="isShowTooltip"
                class="separate-modal__tooltip"
              >
                <div class="bryndza titanic--text mb-2">
                  Это обращение — главное
                </div>
                <div class="mozzarella titanic--text mb-2">
                  Отсоединение главного обращения приведет к расформированию объединения
                </div>
                <div class="mozzarella titanic--text">
                  Если выделить главное, то все остальные обращения тоже выделятся.
                </div>
              </div>
            </transition>
          </div>
        </div>
        <incident-item
          :incident="mainIncident"
          :is-shown-buttons="false"
          @select="selectIncident"
        />
      </section>

      <section class="mb-6">
        <div class="d-flex align-items-center mb-4">
          <p class="feta">Прикреплённые обращения</p>
        </div>

        <div
          class="separate-modal__content"
          v-if="innerConsolidatedAppeals.length"
        >
          <div
            v-for="inc in innerConsolidatedAppeals"
            :key="inc.id"
            class="separate-modal__incidents-wrapper"
          >
            <incident-item
              :incident="inc"
              :is-shown-buttons="false"
              @select="selectIncident"
            />
          </div>
        </div>
      </section>

      <div
        v-if="isLoading"
        class="separate-modal__loading-ring"
      >
        <r-spinner />
      </div>
      <empty
        v-else-if="!innerConsolidatedAppeals.length"
        class="mt-8"
        title="К сожалению, ничего не найдено"
      />

      <div id="RModal--actions" class="separate-modal__footer">
        <div class="separate-modal__buttons">
          <r-button
            class="separate-modal__button"
            @click="сloseModal()"
            color="secondary"
            title="Отменить"
          >
          </r-button>
          <r-button
            class="separate-modal__button ml-8"
            @click="openSeparateSubmitModal"
            :disabled="isButtonDisabled"
            title="Разъединить"
          >
          </r-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce } from '@/plugins/lib';
import SeparateSubmitModal from '@/views/incident/modal/SeparateSubmitModal';
import Empty from '@/components/Empty';

export default {
  name: 'SeparateIncidentsModal',
  components: {
    Empty,
    IncidentItem: () => import('../components/IncidentItem.vue')
  },
  props: {
    mainIncident: {
      type: Object,
      default: () => ({}),
      require: true
    }
  },
  data() {
    return {
      searching: null,
      innerConsolidatedAppeals: [],
      isShowTooltip: false,
      consolidatedAppeals: [],
      isLoading: false
    };
  },
  computed: {
    ...mapState('incident', ['filters', 'incidents', 'isSelectionMode', 'selectedIncidents', 'incident']),
    isButtonDisabled () {
      return !this.selectedIncidents.length
    }
  },
  async mounted() {
    this.mainIncident?.consolidatedAppeals
      ? this.consolidatedAppeals = this.mainIncident?.consolidatedAppeals
      : await this.getIncidentForConsolidatedAppeals();
    this.innerConsolidatedAppeals = [...this.consolidatedAppeals];
    this.setSelectionMode(true);
  },
  beforeDestroy() {
    this.setSelectionMode(false);
  },
  methods: {
    ...mapMutations('incident', ['setProperty', 'setFilterProperty', 'setSelectionMode', 'clearFilterProperties', 'setSelectedIncident', 'setSelectedIncidents', 'setExecutionType']),
    ...mapActions('incident', ['getIncidents', 'consolidateIncidents', 'getIncident']),
    async getIncidentForConsolidatedAppeals() {
      this.isLoading = true;
      await this.getIncident({ id: this.mainIncident.id })
      this.consolidatedAppeals = this.incident.consolidatedAppeals
      this.isLoading = false;
    },
    openSeparateSubmitModal() {
      this.$root.$modal.openModal(SeparateSubmitModal, {
        mainIncidentId: this.mainIncident.id,
        selectedIncidents: this.selectedIncidents.filter(incident => incident.data.id !== this.mainIncident.id).map(incident => incident.data)
      });
    },
    toggleTooltip() {
      this.isShowTooltip = !this.isShowTooltip;
    },
    closeTooltip() {
      this.isShowTooltip = false;
    },
    selectIncident(incident) {
      const mainIncident = this.selectedIncidents.find(incident => incident.data.id === this.mainIncident.id);
      const innerIncidents = [this.mainIncident, ...this.consolidatedAppeals]
      if (incident.id === this.mainIncident.id) {
        const preparedIncidents = innerIncidents.map(incident => ({
          data: incident,
          actions: []
        }));
        if (mainIncident) {
          this.setSelectedIncidents(preparedIncidents);
        } else {
          const index = preparedIncidents?.findIndex(incident => incident.data.id === this.mainIncident.id);
          preparedIncidents.splice(index, 1);
          this.setSelectedIncidents(preparedIncidents);
        }
      } else {
        if (!mainIncident) return;
        this.setSelectedIncident({
          data: this.mainIncident,
          actions: []
        });
      }
    },
    resetIncidents () {
      this.clearFilterProperties()
      this.setProperty({ name: 'incidents', value: [] })
      this.setProperty({ name: 'appealActions', value: [] })
      this.setFilterProperty({ attr: 'pageIndex', value: 0 })
    },
    сloseModal () {
      this.setExecutionType('')
      this.$rir.modal.close()
      if (this.$route.params.id) return;
      this.resetIncidents()
      this.getIncidents({
        category: [this.$route.meta.type],
        sortBy: this.$route.meta.sortBy
      });
    },
    debounceInput: debounce(function () {
      if (this.searching) {
        this.innerConsolidatedAppeals = this.consolidatedAppeals.filter(incident => incident.number.match(new RegExp(this.searching)) || incident.text.match(new RegExp(this.searching)) || incident.authorName.match(new RegExp(this.searching)));
      } else {
        this.innerConsolidatedAppeals = [...this.consolidatedAppeals];
      }
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>
.separate-modal {
  height: calc(100% - 132px);
  position: relative;
  top: 150px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D4D5D932;
  }

  &__tooltip {
    background-color: var(--rir-amelie);
    box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
    border-radius: 16px;
    padding: 20px;
    top: 24px;
    width: 300px;
    left: 0;
    position: absolute;
    z-index: 500;

    &-activator {
      position: relative;
      top: 2px;
    }
  }
  &__wrapper {
    width: 99%;
    margin-right: auto;
  }

  &__header {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 64px;
    padding-bottom: 24px;
    width: 100%;
  }

  &__title {
    max-width: 1360px;
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
    padding: 24px 120px 32px 120px;
    width: 100%;
  }

  &__buttons {
    display: flex;
    max-width: 1360px;
    width: 100%;
  }

  &__button {
    flex: 1;
  }

  &__loading-ring {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>

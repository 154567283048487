<template>
  <div
    class="move-to-resolved-incidents-modal"
    id="modal"
  >
    <div class="move-to-resolved-incidents-modal__wrapper">
      <div class="move-to-resolved-incidents-modal__header">
        <div class="move-to-resolved-incidents-modal__title">
          <h2 class="camembert mb-4 titanic--text">
            Выборочное завершение без ответа
          </h2>
          <p class="mb-4 parmigiano">
            Выберите обращения, которые следует завершить без ответа,
            {{ selectedIncidents ? `(выбрано ${selectedIncidents.length})` : '' }}
          </p>
          <r-input
            v-model="searching"
            @input="debounceInput"
            label="Поиск"
          />
        </div>
      </div>

      <section class="mb-6">
        <div class="d-flex align-items-center mb-4">
          <p class="feta">Главное обращение</p>
          <div
            @mouseenter="toggleTooltip"
            @mouseleave="closeTooltip"
            class="move-to-resolved-incidents-modal__tooltip-activator"
          >
            <r-icon
              class="ml-2"
              fill="rocky"
              icon="info"
              size="16"
            />
            <transition name="fade">
              <div
                v-if="isShowTooltip"
                class="move-to-resolved-incidents-modal__tooltip"
              >
                <div class="bryndza titanic--text mb-2">
                  Это обращение — главное
                </div>
                <div class="mozzarella titanic--text mb-2">
                  При завершении главного все обращения переместятся во вкладку решённые
                </div>
                <div class="mozzarella titanic--text">
                  Если выделить главное, то все остальные обращения тоже выделятся.
                </div>
              </div>
            </transition>
          </div>
        </div>
        <incident-item
          :incident="mainIncident"
          :is-shown-buttons="false"
          @select="selectIncident"
        />
      </section>
      
    <section class="mb-6">
      <div class="d-flex align-items-center mb-4">
        <p class="feta">Прикреплённые обращения</p>
      </div>
      <div
        class="mt-4"
        v-if="innerConsolidatedAppeals.length"
      >
        <div
          v-for="inc in innerConsolidatedAppeals"
          :key="inc.id"
          class="move-to-resolved-incidents-modal__incidents-wrapper"
        >
          <incident-item
            :incident="inc"
            :is-shown-buttons="false"
            @select="selectIncident"
          />
        </div>
      </div>
    </section>

      <div
        v-if="isLoading"
        class="move-to-resolved-incidents-modal__loading-ring"
      >
        <r-spinner/>
      </div>
      <empty
        v-else-if="!innerConsolidatedAppeals.length"
        class="mt-8"
        title="К сожалению, ничего не найдено"
      />

      <div id="RModal--actions" class="move-to-resolved-incidents-modal__footer">
        <div class="move-to-resolved-incidents-modal__buttons">
          <r-button
            class="move-to-resolved-incidents-modal__button"
            @click="$rir.modal.close()"
            color="secondary"
            title="Отменить"
          >
          </r-button>
          <r-button
            class="move-to-resolved-incidents-modal__button ml-8"
            @click="moveToResolved"
            :isLoading="loading"
            :disabled="isButtonDisabled"
            title="Завершить выбранные"
          >
          </r-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce, goBack } from '@/plugins/lib';
import IncidentItem from '@/views/incident/components/IncidentItem';
import Empty from '@/components/Empty';
import InfoModal from '@/views/incident/modal/InfoModal';
import { ACTIONS } from '@/constants';

export default {
  name: 'MoveToResolvedIncidentsModal',
  components: { Empty, IncidentItem },
  props: {
    mainIncident: {
      type: Object,
      default: () => ({}),
      require: true
    },
    model: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      searching: null,
      innerConsolidatedAppeals: [],
      loading: false,
      isShowTooltip: false
    };
  },
  computed: {
    ...mapState('incident', ['filters', 'isLoading', 'incidents', 'isSelectionMode', 'selectedIncidents']),
    ...mapState('session', ['user']),
    consolidatedAppeals() {
      return this.mainIncident?.consolidatedAppeals
    },
    isButtonDisabled () {
      return !this.selectedIncidents.length || this.loading
    }
  },
  async mounted() {
    this.innerConsolidatedAppeals = [...this.consolidatedAppeals];
    this.setSelectionMode(true);
  },
  beforeDestroy() {
    this.setSelectionMode(false);
  },
  methods: {
    ...mapMutations('incident', ['setProperty', 'setFilterProperty', 'setSelectionMode', 'clearFilterProperties', 'setSelectedIncident', 'setSelectedIncidents']),
    ...mapActions('incident', ['getIncidents', 'consolidateIncidents', 'moveIncident']),
    debounceInput: debounce(function () {
      if (this.searching) {
        this.innerConsolidatedAppeals = this.consolidatedAppeals.filter(incident => incident.number.match(new RegExp(this.searching)) || incident.text.match(new RegExp(this.searching)) || incident.authorName.match(new RegExp(this.searching)));
      } else {
        this.innerConsolidatedAppeals = [...this.consolidatedAppeals];
      }
    }, 1000),
    toggleTooltip() {
      this.isShowTooltip = !this.isShowTooltip;
    },
    closeTooltip() {
      this.isShowTooltip = false;
    },
    selectIncident(incident) {
      const mainIncident = this.selectedIncidents.find(incident => incident.data.id === this.mainIncident.id);
      const innerIncidents = [this.mainIncident, ...this.consolidatedAppeals]
      if (incident.id === this.mainIncident.id) {
        const preparedIncidents = innerIncidents.map(incident => ({
          data: incident,
          actions: []
        }));
        if (mainIncident) {
          this.setSelectedIncidents(preparedIncidents);
        } else {
          const index = preparedIncidents?.findIndex(incident => incident.data.id === this.mainIncident.id);
          preparedIncidents.splice(index, 1);
          this.setSelectedIncidents(preparedIncidents);
        }
      } else {
        if (!mainIncident) return;
        this.setSelectedIncident({
          data: this.mainIncident,
          actions: []
        });
      }
    },
    async moveToResolved() {
      const payload = this.selectedIncidents.map(incident => ({
        id: incident.data.id,
        statusCode: incident.data.action,
        moderatorId: this.user.id,
        feedback: this.model.feedback,
        decision: ACTIONS.COMPLETE_WITHOUT_RESPONSE
      }));
      this.loading = true;
      await this.moveIncident(payload)
        .then(async () => {
          goBack();
          this.$root.$modal.closeModal();
          this.$rir.notification.send({
            title: 'Обращения перемещены в "Решённое"',
            seconds: 5
          });
        })
        .catch(() => this.errorModal())
        .finally(() => this.setSelectionMode(false));
      this.loading = false;
    },
    errorModal() {
      this.$root.$modal.openModal(InfoModal, {
        icon: 'close',
        fill: 'fargo',
        title: 'Обращения не были перемещены в "Решённое"',
        subtitle: `Произошла внутренняя ошибка сервера во время отправки.
        Пожалуйста, повторите отправку. Если проблема повторится, то обратитесь в службу поддержки.`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.move-to-resolved-incidents-modal {
  height: calc(100% - 132px);
  position: relative;
  top: 150px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D4D5D932;
  }

  &__tooltip {
    background-color: var(--rir-amelie);
    box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
    border-radius: 16px;
    padding: 20px;
    top: 24px;
    width: 300px;
    left: 0;
    position: absolute;
    z-index: 500;

    &-activator {
      position: relative;
      top: 2px;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 64px;
    padding-bottom: 24px;
    width: 100%;
  }

  &__incidents-wrapper {
    display: flex;
    align-items: center;
  }

  &__wrapper {
    width: 99%;
    margin-right: auto;
  }

  &__title {
    max-width: 1360px;
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
    padding: 24px 120px 32px 120px;
    width: 100%;
  }

  &__buttons {
    display: flex;
    max-width: 1360px;
    width: 100%;
  }

  &__button {
    flex: 1;
  }

  &__loading-ring {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>

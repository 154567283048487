<template>
  <div class="notification-list" v-inner-click-outside="closePopover">
    <div
      @click="togglePopover"
      class="notification-list__activator"
    >
      <r-icon
        icon="notifications"
        fill="titanic"
        size="20"
      />
      <div
        v-if="counter"
        class="notification-list__counter amelie--text edam"
      >
        {{ counter }}
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isShownPopover"
        class="notification-list__wrapper"
      >
        <div class="notification-list__header">
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="taleggio">Оповещения</div>
            <r-tooltip
              activator-hover
              color="amelie"
              title="Пометить все как прочитанные"
            >
              <template #activator>
                <r-icon
                  style="cursor: pointer;"
                  @click.native="readAll"
                  icon="selection"
                  size="20"
                  fill="rocky"
                />
              </template>
            </r-tooltip>
          </div>
          <div class="d-flex align-items-center">
            <r-button-tag
              title="Все"
              :value="isShownAll"
              @input="shownAll"
            />
            <r-button-tag 
              class="ml-2"
              title="Непрочитанные"
              v-model="isShownUnread"
              @input="shownUnread"
            />
          </div>
        </div>
        <div
          v-if="isShownNotificationList"
          class="notification-list__items"
          ref="notifications"
        >
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="notification-list__item"
            @click="read(notification)"
          >
            <div class="notification-list__item-info">
              <p
                class="mozzarella mb-2"
                :class="notification.isRead ? 'metropolis--text' : 'harakiri--text ekas'"
              >
                {{ notification.createdAt | dateTime }}
              </p>
              <p class="sulguni" :class="notification.isRead ? 'metropolis--text' : 'harakiri--text'">
                {{ notification.content }}
              </p>
            </div>
            <r-tooltip
              activator-hover
              color="amelie"
              position="center-left"
              :title="notification.isRead ? 'Прочитанное' : 'Пометить как прочитанное'"
            >
              <template #activator>
                <r-icon
                  class="notification-list__item-icon"
                  :icon="notification.isRead ? 'selection' : 'selected'"
                  :size="notification.isRead ? 20 : 16"
                  fill="rocky"
                />
              </template>
            </r-tooltip>
          </div>
          <r-spinner
            v-if="isLoading"
            class="notification-list__spinner"
          />
        </div>
        <div
          v-else
          class="notification-list__empty sulguni metropolis--text"
        >
          Уведомлений нет
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'NotificationList',
  data () {
    return {
      isShownPopover: false,
      isShownUnread: false,
      isShownAll: true,
      popover: null
    }
  },
  computed: {
    ...mapState('notification', ['notifications', 'notificationCount', 'filters', 'isLoading']),
    isShownNotificationList () {
      return this.notifications?.length || this.isLoading
    },
    counter () {
      return this.notificationCount.unread > 99 ? '99+' : this.notificationCount.unread
    }
  },
  mounted () {
    this.getNotifications()
    this.getNotificationCount()
  },
  methods: {
    ...mapActions('notification', ['getNotifications', 'readNotification', 'readAllNotification', 'getNotificationCount']),
    ...mapMutations('notification', ['setProperty', 'clearFilters']),
    async togglePopover () {
      this.isShownPopover = !this.isShownPopover
      if (this.isShownPopover) {
        await this.getNotifications()
        this.popover = this.$refs.notifications;
        this.popover.addEventListener('scroll', this.showNewNotifications);
      } else {
        this.popover.removeEventListener('scroll', this.showNewNotifications);
      }
    },
    closePopover () {
      this.isShownPopover = false
    },
    shownAll() {
      this.isShownAll = true
      this.isShownUnread = false
      this.clearFilters()
      this.getNotifications()
    },
    shownUnread () {
      this.isShownAll = false
      this.isShownUnread = true
      this.clearFilters()
      this.setProperty({
        name: 'filters',
        value: {
          ...this.filters,
          isRead: false
        }
      })
      this.getNotifications()
    },
    async read (notification) {
      if (notification.isRead) return
      await this.readNotification([notification.id])
      this.getNotificationCount()
      this.setProperty({
        name: 'notifications',
        value: this.notifications.map((el) => {
          if (el.id === notification.id) el.isRead = true
          return el
        })
      });
    },
    async readAll () {
      await this.readAllNotification()
      this.getNotifications()
      this.getNotificationCount()
    },
    showNewNotifications() {
      if ((this.popover.clientHeight + this.popover.scrollTop) >= this.popover.scrollHeight - 50) {
        if (this.notifications?.length > this.filters.pageIndex * 20) {
          this.setProperty({
            name: 'filters',
            value: {
              ...this.filters,
              pageIndex: this.filters.pageIndex + 1
            }
          })
          this.getNotifications()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .notification-list {
    position: relative;

    &__spinner {
      margin: auto;
      margin-top: 16px;
    }

    &__activator {
      margin-top: 2px;
      cursor: pointer;
      position: relative;
    }

    &__counter {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: var(--rir-fargo);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -8px;
      left: 8px
    }

    &__wrapper {
      position: absolute;
      width: 392px;
      height: fit-content;
      background-color: var(--rir-amelie);
      top: 40px;
      right: 0;
      padding: 8px;
      z-index: 1000;
      border-radius: 16px;
      box-shadow: 0px 8px 32px 0px rgba(12, 16, 24, 0.12);
    }

    &__header {
      padding: 16px;
      width: 100%;
    }

    &__items {
      overflow: scroll;
      max-height: 490px;
      color: rgba(#CDD3DF, 0.48) !important;

      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 2px;
      }
      &::-webkit-scrollbar-corner {
        background: rgba(0,0,0,0);
      }
      &::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        box-shadow: inset 0 0 0 10px;
        border-radius: 9999px;
        background: none;
      }
    }

    &__empty {
      width: fit-content;
      margin: auto;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    &__item {
      transition: background-color .2s;
      cursor: pointer;
      padding: 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &:hover {
        background-color: var(--rir-walle);

        .notification-list__item-icon {
          opacity: 1;
          pointer-events: auto;
        }
      }

      &-icon {
        transition: opacity .2s;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
</style> 
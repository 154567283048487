<template>
  <div class="incident-new">
    <r-row class="incident-new__row">
      <r-col
        :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
        :offset="{ widest: 0, wide: 0, middle: 0, narrow: 0 }"
      >
        <FormSelect
          class="mb-6"
          label="Тип упоминания"
          idValue="code"
          titleValue="name"
          :items="appealTypes"
          v-model="$v.modelIncident.accountChannelCode.$model"
          :error="$v.modelIncident.accountChannelCode.$error"
          :errorMessage="mesErr($v.modelIncident.accountChannelCode)"
        />
        <FormInput
          class="mb-6"
          label="Ссылка на первоисточник"
          v-model="$v.modelIncident.url.$model"
          :error="$v.modelIncident.url.$error"
          :errorMessage="mesErr($v.modelIncident.url)"
        />
      </r-col>
      <r-col
        :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
        :offset="{ widest: 0, wide: 0, middle: 0, narrow: 0 }"
      >
        <FormInput
          class="mb-6"
          label="Автор обращения"
          v-model="$v.modelIncident.authorName.$model"
          :error="$v.modelIncident.authorName.$error"
          :errorMessage="mesErr($v.modelIncident.authorName)"
        />
        <MultiselectTags
          v-if="isShownKeywords"
          class="mb-6"
          @input="changeTagsList"
        />
      </r-col>
    </r-row>
    <r-row class="incident-new__row">
      <r-col
        :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
        :offset="{ widest: 0, wide: 0, middle: 0, narrow: 0 }"
      >
          <FormTextarea
            class="mb-6"
            :rows="9"
            label="Текст обращения"
            v-model.lazy="$v.modelIncident.text.$model"
            :error="$v.modelIncident.text.$error"
            :errorMessage="mesErr($v.modelIncident.text)"
          />
          <FormTextarea
            v-if="isShownMap"
            class="mb-6"
            :rows="9"
            label="Примечание"
            v-model.lazy="$v.modelIncident.note.$model"
          />
      </r-col>
      <r-col
        :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
        :offset="{ widest: 0, wide: 0, middle: 0, narrow: 0 }"
      >
        <Map
          v-if="isShownMap"
          v-model="modelIncident.location"
        />
        <FormTextarea
          v-else
          class="mb-6"
          :rows="9"
          label="Примечание"
          v-model.lazy="$v.modelIncident.note.$model"
        />
      </r-col>
    </r-row>
    <r-row class="incident-new__row">
      <FileUploader
        v-model="modelIncident.attachments"
        mode="edit"
      />
    </r-row>
    <r-checkbox
      class="sulguni mb-6 ml-8"
      style="width: fit-content"
      title="Запустить в работу"
      v-model="inWork"
    />
    <template>
      <r-row class="incident-new__row">
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
          :offset="{ widest: 0, wide: 0, middle: 0, narrow: 0 }"
        >
          <FormSelect
            class="mb-6"
            label="Департамент"
            :items="departments"
            idValue="id"
            titleValue="name"
            :disabled="!inWork"
            v-model="departmentId"
          />
          <FormSelect
            class="mb-6"
            label="Тип проблемы"
            :items="problemsInDepartment(departmentId)"
            idValue="id"
            titleValue="name"
            :disabled="!inWork || !departmentId"
            no-data-items="Отсутствуют типы проблем"
            v-model.lazy="$v.modelIncident.conversation.problemId.$model"
            :error="$v.modelIncident.conversation.problemId.$error"
            :errorMessage="mesErr($v.modelIncident.conversation.problemId)"
          />
          <FormTextarea
            :rows="5"
            label="Комментарий для исполнителя"
            :readonly="!inWork"
            v-model.lazy="$v.modelIncident.conversation.moderatorMessage.$model"
            :error="$v.modelIncident.conversation.moderatorMessage.$error"
            :errorMessage="mesErr($v.modelIncident.conversation.moderatorMessage)"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
          :offset="{ widest: 0, wide: 0, middle: 0, narrow: 0 }"
        >
          <FormSelect
            class="mb-6"
            label="Ответственный"
            :items="executorsInDepartment"
            idValue="id"
            titleValue="fullName"
            :disabled="!inWork || !departmentId"
            :no-data-items="noItemsExecutorText"
            v-model.lazy="$v.modelIncident.conversation.executorId.$model"
            :error="$v.modelIncident.conversation.executorId.$error"
            :errorMessage="mesErr($v.modelIncident.conversation.executorId)"
          />
          <required-duration-component
            class="mb-6"
            :readonly="!inWork"
            v-model="$v.modelIncident.conversation.dueDate.$model"
            :error="$v.modelIncident.conversation.dueDate.$error"
            :errorMessage="mesErr($v.modelIncident.conversation.dueDate)"
          />
        </r-col>
      </r-row>
    </template>
    <div class="incident-new__btn mt-8">
      <r-button
        :isLoading="isLoadingSendForm"
        :disabled="isLoadingSendForm"
        :title="!inWork ? 'Создать' : 'Создать и запустить в работу'"
        width="wide"
        @click="submitForm"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required, maxLength, isUrl } from '@/plugins/vuelidate/customValidate'
import InfoModal from '@/views/incident/modal/InfoModal'
import RequiredDurationComponent from '@/components/RequiredDurationComponent'
import Map from '@/views/incident/components/Map'
import MultiselectTags from '@/components/MultiselectTags'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import FormTextarea from '@/components/FormTextarea'
import FileUploader from '@/components/FileUploader'
import getEnv from '@/plugins/env'

export default {
  name: 'incident-new',
  components: {
    RequiredDurationComponent,
    Map,
    MultiselectTags,
    FormInput,
    FormSelect,
    FormTextarea,
    FileUploader,
  },
  mixins: [validationMixin, validateMixin],
  data: () => ({
    isLoadingSendForm: false,
    incidentTypes: [
      { name: 'Комментарий', type: 'comment' },
      { name: 'Публикация', type: 'publish' },
      { name: 'Личное сообщение', type: 'message' }
    ],
    inWork: false,
    tagsList: [],
    modelIncident: {
      accountChannelCode: null,
      authorName: null,
      text: null,
      attachments: [],
      note: null,
      url: null,
      tags: [],
      conversation: {
        problemId: null,
        dueDate: null,
        executorId: null,
        moderatorMessage: null
      }
    },
    moderatorMessage: null,
    departmentId: null,
    now: new Date().getTime()
  }),
  async mounted () {
    this.tagsList = [{ name: 'Без тега' }, ...this.tags]
  },
  watch: {
    departmentId () {
      this.modelIncident.conversation.executorId = null
      this.modelIncident.conversation.problemId = null
    },
    updatingValidTime (val) {
      if (!val) this.loadDirectories()
    }
  },
  validations () {
    return {
      modelIncident: {
        authorName: {
          required: required('Обязательное поле')
        },
        accountChannelCode: {
          required: required('Обязательное поле')
        },
        text: {
          required: required('Обязательное поле'),
          strLength: maxLength(4000, 'Максимум 4000 символов')
        },
        note: {
          strLength: maxLength(1500, 'Максимум 1500 символов')
        },
        url: {
          isUrl: isUrl('Ссылка введена некорректно')
        },
        conversation: {
          problemId: {
            required:
              this.inWork ? required('Обязательное поле') : false
          },
          dueDate: {
            required:
              this.inWork ? required('Обязательное поле') : false
          },
          executorId: {
            required:
              this.inWork ? required('Обязательное поле') : false
          },
          moderatorMessage: {
            strLength: maxLength(1500, 'Максимум 1500 символов')
          }
        }
      }
    }
  },
  computed: {
    ...mapState('directory', [
      'messageSources',
      'executors',
      'departments',
      'tags',
      'appealTypes'
    ]),
    ...mapState('session', ['user']),
    ...mapState('settings', ['updatingValidTime']),
    ...mapGetters('directory', ['problemsInDepartment']),
    ...mapGetters(['POST', 'GET']),
    executorsInDepartment () {
      return this.executors.filter(
        el => el.departmentId === this.departmentId
      )
    },
    noItemsExecutorText () {
      return this.departmentId
        ? 'В департаменте нет сотрудников'
        : 'Требуется выбрать департамент'
    },
    isShownMap() {
      return !!+getEnv('VUE_APP_SHOW_MAP');
    },
    isShownKeywords() {
      return !!+getEnv('VUE_APP_SHOW_TAGS');
    },
  },
  methods: {
    ...mapActions('incident', ['createIncident']),
    ...mapActions('directory', ['loadDirectories']),
    errorModal () {
      this.$root.$modal.openModal(InfoModal, {
        icon: 'close',
        fill: 'fargo',
        title: 'Ошибка создания обращения',
        subtitle: `Произошла внутренняя ошибка сервера во время отправки.
        Пожалуйста, повторите отправку. Если проблема повторится, то обратитесь в службу поддержки.`
      })
    },
    changeTagsList (tags) {
      this.modelIncident.tags = tags
    },
    getData () {
      if (!this.inWork) delete this.modelIncident.conversation
      return this.modelIncident
    },
    async submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid || this.isLoadingSendForm) return
      this.isLoadingSendForm = true
      await this.POST('ui/appeal/create', this.getData())
        .then(() => {
          this.$root.$modal.openModal(InfoModal, {
            icon: 'selected',
            title: this.inWork
              ? 'Обращение успешно создано и запущено в работу'
              : 'Обращение успешно создано'
          })
          this.loadDirectories()
        })
        .then(() => {
          this.$router.push({ name: this.inWork ? 'incident-work' : 'incident-new' })
        })
        .catch(() => this.errorModal())
      this.isLoadingSendForm = false
    }
  }
}
</script>

<style lang="scss" scoped>
.incident-new {
  padding-top: 62px !important;

  &__row {
    padding: 0 16px;
  }

  &::v-deep {
    .r-checkbox__input {
      left: -4px;
    }
  }

  &__checkbox {
    position: relative;
    left: 20px;
  }

  &__btn {
    position: sticky;
    width: 100%;
    bottom: 0;
    z-index: 2;
    padding: 24px 24px 12px 24px;
  }
}
</style>

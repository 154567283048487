var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reaction-time"},[_c('r-input',{directives:[{name:"mask",rawName:"v-mask",value:({
      mask: _vm.time.days < 31 ? '##' : '',
    }),expression:"{\n      mask: time.days < 31 ? '##' : '',\n    }"}],staticClass:"reaction-time__input",attrs:{"label":"Дн."},on:{"input":_vm.changeTime},model:{value:(_vm.time.days),callback:function ($$v) {_vm.$set(_vm.time, "days", $$v)},expression:"time.days"}}),_c('r-input',{directives:[{name:"mask",rawName:"v-mask",value:({
      mask: _vm.time.days > 29
        ? ''
        : ('' + _vm.time.hours)?.length === 1 && _vm.time.hours > 2
          ? 'h'
          : _vm.time.hours < 20 ? 'Hh' : 'Hf',
      tokens: {
        H: { pattern: /[0-2]/ },
        h: { pattern: /[0-9]/ },
        f: { pattern: /[0-3]/ },
      }
    }),expression:"{\n      mask: time.days > 29\n        ? ''\n        : ('' + time.hours)?.length === 1 && time.hours > 2\n          ? 'h'\n          : time.hours < 20 ? 'Hh' : 'Hf',\n      tokens: {\n        H: { pattern: /[0-2]/ },\n        h: { pattern: /[0-9]/ },\n        f: { pattern: /[0-3]/ },\n      }\n    }"}],staticClass:"reaction-time__input",attrs:{"label":"Ч."},on:{"input":_vm.changeTime},model:{value:(_vm.time.hours),callback:function ($$v) {_vm.$set(_vm.time, "hours", $$v)},expression:"time.hours"}}),_c('r-input',{directives:[{name:"mask",rawName:"v-mask",value:({
      mask: _vm.time.days > 29
        ? ''
        : _vm.time.minutes < 60 ? '##' : '',
    }),expression:"{\n      mask: time.days > 29\n        ? ''\n        : time.minutes < 60 ? '##' : '',\n    }"}],staticClass:"reaction-time__input",attrs:{"label":"Мин."},on:{"input":_vm.changeTime},model:{value:(_vm.time.minutes),callback:function ($$v) {_vm.$set(_vm.time, "minutes", $$v)},expression:"time.minutes"}}),(_vm.button)?_c('r-button-simple',{staticClass:"reaction-time__btn",attrs:{"disabled":!_vm.isActiveButton,"size":"larishae","icon":"selected"},on:{"click":_vm.selectStartItem}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="incident-forms">
    <transition name="fade">
      <response-form
        v-if="showResponseForm || showIncidentPostpone"
        ref="responseForm"
        class="mb-6"
        :incident="incident"
        @change-type-code="$emit('change-type-code', $event)"
      />
    </transition>
    <transition name="fade">
      <incident-postpone
        v-if="showIncidentPostpone"
        ref="incidentPostpone"
        :incident="incident"
      />
    </transition>
    <transition name="fade">
      <launch-form
        v-if="showLaunchForm"
        ref="launchForm"
        class="mb-6"
        :incident="incident"
      />
      <note-form
        v-if="showNoteForm"
        ref="noteForm"
      />
    </transition>
    <transition name="fade">
      <delegation-form
        v-if="showDelegationForm"
        ref="delegationForm"
        :incident="incident"
      />
    </transition>
    <slot :send-form-data="sendFormData" :open-consolidate-modal="openConsolidateModal" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import InfoModal from '@/views/incident/modal/InfoModal'
import SubmitModal from '@/views/incident/modal/SubmitModal'
import MoveToResolvedIncidentsModal from '@/views/incident/modal/MoveToResolvedIncidentsModal'
import CompleteIncidentsModal from '@/views/incident/modal/CompleteIncidentsModal'
import IncidentPostpone from '@/views/incident/components/forms/IncidentPostpone'
import LaunchForm from '@/views/incident/components/forms/LaunchForm'
import NoteForm from '@/views/incident/components/forms/NoteForm'
import ResponseForm from '@/views/incident/components/forms/ResponseForm'
import DelegationForm from '@/views/incident/components/forms/DelegationForm'
import { ACTIONS } from '@/constants'
import { goBack } from '@/plugins/lib'

export default {
  name: 'incident-forms',
  props: {
    incident: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  components: {
    IncidentPostpone,
    LaunchForm,
    NoteForm,
    ResponseForm,
    DelegationForm
  },
  computed: {
    ...mapState('session', ['user']),
    ...mapState('incident', ['executionType']),
    showResponseForm () {
      return [ACTIONS.COMPLETE_APPEAL,
        ACTIONS.EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER,
        ACTIONS.WAIT_FOR_ANSWER,
        ACTIONS.COMPLETE_WITHOUT_RESPONSE
      ].includes(this.executionType)
    },
    showLaunchForm () {
      return this.executionType === ACTIONS.EXECUTE_APPEAL ||
        this.executionType === ACTIONS.EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER
    },
    showIncidentPostpone () {
      return this.executionType === ACTIONS.WAIT_FOR_APPEAL_ACTIVATION
    },
    showNoteForm () {
      return this.executionType === ACTIONS.ADD_NOTE
    },
    isExecutorReassignment () {
      return this.$route.meta.type === ACTIONS.IN_WORK && (this.executionType === ACTIONS.EXECUTE_APPEAL ||
        this.executionType === ACTIONS.EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER)
    },
    showDelegationForm () {
      return [ACTIONS.DELEGATE_APPEAL,
        ACTIONS.REPLY_DELEGATED_APPEAL,
        ACTIONS.DECLINE_DELEGATED_APPEAL
      ].includes(this.executionType)
    },
    isConsolidated () {
      return !!(this.incident?.consolidatedAppeals?.length && !this.$route.name.match(/more/))
    },
    isCompleteIncidentsInConsolidation () {
      return this.executionType === ACTIONS.COMPLETE_WITHOUT_RESPONSE && this.isConsolidated
    }
  },
  methods: {
    ...mapActions('incident', ['moveIncident', 'addNote', 'delegate']),
    ...mapMutations('apps', ['setAnchor']),
    includeAttach (file) {
      if (this.showResponseForm || this.showIncidentPostpone) {
        const responseForm = this.$refs.responseForm
        if (!responseForm.model.attachments.find(el => el.filename === file.filename)) {
          responseForm.model.attachments.push(file)
        }
      }
      if (this.showLaunchForm) {
        const launchForm = this.$refs.launchForm
        if (!launchForm.model.executorAttachments.find(el => el.filename === file.filename)) {
          launchForm.model.executorAttachments.push(file)
        }
      }
      if (this.showDelegationForm) {
        const delegationForm = this.$refs.delegationForm
        if (!delegationForm.model.attachments.find(el => el.filename === file.filename)) {
          delegationForm.model.attachments.push(file)
        }
      }
    },
    getFormData () {
      const responseForm = this.$refs.responseForm
      const launchForm = this.$refs.launchForm
      const incidentPostpone = this.$refs.incidentPostpone
      const data = {
        id: this.incident.id,
        statusCode: this.incident.action,
        moderatorId: this.user.id,
        decision: this.executionType
      }
      if (responseForm) {
        responseForm.$v.$touch()
        if (!responseForm.$v.$invalid) {
          data.feedback = responseForm.model
        }
      }
      if (incidentPostpone) {
        incidentPostpone.$v.$touch()
        if (!incidentPostpone.$v.$invalid) {
          data.feedback = {
            ...data.feedback,
            deadline: incidentPostpone.deadline
          }
        }
      }
      if (launchForm) {
        launchForm.$v.$touch()
        if (!launchForm.$v.$invalid) {
          const model = { ...launchForm.model }
          delete model.departmentId
          data.conversation = model
        }
      }
      if (responseForm?.$v.$invalid || incidentPostpone?.$v.$invalid || launchForm?.$v.$invalid) return
      return data
    },

    getModalTitle () {
      const titles = {
        [ACTIONS.COMPLETE_WITHOUT_RESPONSE]: 'Обращение перемещено во вкладку "Решённые"',
        [ACTIONS.EXECUTE_APPEAL]: 'Обращение запущено в работу',
        [ACTIONS.EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER]: 'Обращение запущено в работу',
        [ACTIONS.COMPLETE_APPEAL]: 'Отправлен ответ заявителю',
        [ACTIONS.DELEGATE_APPEAL]: 'Обращение делегировано',
        [ACTIONS.DECLINE_DELEGATED_APPEAL]: 'Обращение отклонено',
        [ACTIONS.REPLY_DELEGATED_APPEAL]: 'Ответ отправлен',
        [ACTIONS.WAIT_FOR_ANSWER]: 'Отправлено на дозапрос',
        [ACTIONS.WAIT_FOR_APPEAL_ACTIVATION]: 'Обращение отсрочено',
        [ACTIONS.ADD_NOTE]: 'Примечание сохранено'
      }
      return titles[this.executionType]
    },

    getModal () {
      const modals = {
        [ACTIONS.COMPLETE_WITHOUT_RESPONSE]: MoveToResolvedIncidentsModal,
        [ACTIONS.COMPLETE_APPEAL]: CompleteIncidentsModal
      }
      return modals[this.executionType] || null
    },

    openSuccessModal (res) {
      this.$rir.modal.open(InfoModal, {
        icon: 'selected',
        title: res.message ? this.getModalTitle() : 'Ответ на обращение не отправлен',
        route: this.executionType === ACTIONS.REPLY_DELEGATED_APPEAL ? `/${this.$route.path.split('/')[1]}` : null,
      }, {
        isClose: false,
      })
    },

    openExceptionModal (err) {
      const errData = err.response.data
      console.log(errData)
      this.$root.$modal.openModal(InfoModal, {
        icon: 'close',
        fill: 'fargo',
        title: 'Произошла ошибка!',
        subtitle: errData?.message || 'Повторите позже'
      })
    },

    async sendAndMove () {
      const data = this.getFormData()
      if (!data) return
      if (this.isExecutorReassignment) {
        this.$root.$modal.openModal(SubmitModal, {
          formData: data,
          executorName: this.incident.executorName
        })
      } else {
        this.$emit('sending', true)
        await this.moveIncident([data])
          .then((res) => this.openSuccessModal(res))
          .then(() => goBack())
          .catch((err) => this.openExceptionModal(err))
        this.$emit('sending', false)
      }
    },

    async sendNote () {
      const noteForm = this.$refs.noteForm
      noteForm.$v.$touch()
      if (noteForm.$v.$invalid) return
      this.$emit('sending', true)
      await this.addNote({
        id: this.incident.id,
        data: noteForm.model
      })
        .then((res) => this.openSuccessModal(res))
        .then(() => goBack())
        .catch((err) => this.openExceptionModal(err))
      this.$emit('sending', false)
    },

    async delegateAppeal () {
      const delegationForm = this.$refs.delegationForm
      delegationForm.$v.$touch()
      if (delegationForm.$v.$invalid) return
      this.$emit('sending', true)
      await this.delegate({
        appealId: this.incident.id,
        action: this.executionType,
        ...delegationForm.model
      }).then((res) => this.openSuccessModal(res))
        .catch((err) => this.openExceptionModal(err))
      this.$emit('sending', false)
    },

    async completeIncidentsInConsolidation () {
      const responseForm = this.$refs.responseForm
      responseForm.$v.$touch()
      if (responseForm.$v.$invalid) return
      const incidents = [this.incident, ...this.incident?.consolidatedAppeals]
      const payload = incidents.map(incident => ({
        id: incident.id,
        statusCode: incident.action,
        moderatorId: this.user.id,
        feedback: responseForm.model,
        decision: ACTIONS.COMPLETE_WITHOUT_RESPONSE
      }))
      if (!payload) return
      this.$emit('sending', true)
      await this.moveIncident(payload)
        .then((res) => this.openSuccessModal(res))
        .then(() => goBack())
        .catch((err) => this.openExceptionModal(err))
      this.$emit('sending', false)
    },

    async sendFormData () {
      if (this.showNoteForm) this.sendNote()
      else if (this.showDelegationForm) this.delegateAppeal()
      else if (this.isCompleteIncidentsInConsolidation) this.completeIncidentsInConsolidation()
      else this.sendAndMove()
    },

    openConsolidateModal () {
      const model = this.getFormData()
      if (!model) return
      this.$rir.modal.open(this.getModal(), {
        mainIncident: this.incident,
        model
      }, {
        isClose: false,
        isFixedActionsBlock: true,
      })
    }
  }
}
</script>

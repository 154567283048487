<template>
  <FormGroup
    :error="error"
    :errorMessage="errorMessage"
  >
    <template v-slot="params">
      <r-date-picker
        :disabled="disabled"
        @input="$emit('input', $event)"
        :value="value"
        :label="label"
        :startDate="startDate"
        :period="period"
        :error="error"
      />
    </template>
    <template v-slot:after>
      <slot name="after"></slot>
    </template>
  </FormGroup>
</template>

<script>
  import FormGroup from "@/components/FormGroup.vue";

  export default {
    name: 'FormInput',
    components: {
      FormGroup,
    },
    props: {
      label: {
        type: String,
        default: null,
      },
      value: {
        type: [String, Number],
      },
      disabled: {
        type: Boolean,
        default: false
      },
      startDate: {
        type: String,
        default: null,
      },
      endDate: {
        type: String,
        default: null,
      },
      error: {
        type: Boolean,
        default: false, 
      },
      errorMessage: {
        type: String,
        default: ''
      },
      period: {
        type: Boolean,
        default: false
      }
    },
  }
</script>

<template>
  <div
    class="d-flex flex-direction-column justify-content-center align-items-center"
    style="position: relative; height: 100%; width: 100%;"
  >
    <div class="d-flex flex-direction-column pa-8">
      <r-icon
        class="mb-6"
        icon="warning"
        fill="fargo"
        :size="56"
      />
      <span
        class="mb-8 titanic--text"
        style="font-weight: bold; font-size: 32px;"
      >
        {{ title }}
      </span>
      <div
        class="mb-8"
        v-html="subtitle"
      />
      <div class="d-flex justify-content-between">
        <r-button
          style="flex: 1"
          @click="closeModal()"
          color="secondary"
          title="Не удалять"
        >
        </r-button>
        <r-button
          style="flex: 1"
          class="ml-8"
          @click="deleteIncident"
          :isLoading="sending"
          :disabled="sending"
          title="Удалить"
        >
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'DeleteModal',
  props: {
    incidents: {
      type: Array
    }
  },
  data() {
    return {
      sending: false
    };
  },
  computed: {
    ...mapState('session', ['user']),
    title() {
      return this.incidents.length > 1 ? 'Удалить обращения?' : 'Удалить обращение?';
    },
    subtitle() {
      return this.incidents.length > 1
        ? `<p class="mb-1">Обращения будут перемещены в «Удалённые»</p>
          <p>В случае необходимости вы сможете восстановить их из этого раздела</p>
        `
        : `<p class="mb-1">Обращение будет перемещено в «Удалённые»</p>
          <p>В случае необходимости вы сможете восстановить его из этого раздела</p>
        `;
    }
  },
  methods: {
    ...mapMutations('incident', ['setFilterProperty', 'setProperty', 'setSelectionMode', 'setExecutionType']),
    ...mapActions('incident', ['moveIncident', 'getIncidents']),
    ...mapMutations('apps', ['setAnchor']),
    async deleteIncident() {
      const payload = this.incidents.map(incident => ({
        id: incident?.data?.id || incident.id,
        statusCode: incident?.data?.action || incident.action,
        moderatorId: this.user.id,
        decision: 'IGNORE_APPEAL'
      }));
      this.sending = true;
      await this.moveIncident(payload)
        .then(async () => {
          if (this.$route.params.id) {
            this.setAnchor(0);
            const path = this.$route.path?.split('/');
            this.$router.push(`/${path[1]}`);
          } else {
            this.setProperty({ name: 'incidents', value: [] });
            this.setProperty({ name: 'appealActions', value: [] });
            this.setFilterProperty({ attr: 'pageIndex', value: 0 });
            await this.getIncidents({
              category: [this.$route.meta.type],
              sortBy: this.$route.meta.sortBy
            });
          }
          this.sending = false;
          this.$root.$modal.closeModal()
          this.$rir.notification.send({
            title: payload.length > 1 ? 'Обращения удалены' : 'Обращение удалено',
            seconds: 5
          });
        })
        .catch(() => {
          this.sending = false;
          this.$root.$modal.closeModal()
        })
        .finally(() => this.setSelectionMode(false));
    },
    closeModal () {
      this.setExecutionType('')
      this.$rir.modal.close()
    }
  }
};
</script>

<template>
  <div class="file-uploader__wrapper">
    <slot
      v-if="fileModuleFiles.length"
      name="before"
    ></slot>
    <r-uploader
      v-if="mode === 'edit'"
      :disabled="false"
      title="Выбрать файлы"
      sub-title="или перетяните их сюда, каждый размером не более 50 Мб"
      :max-size="50"
      v-model="files"
      :callback="upload"
      :max-files="10"
    />
    <div
      v-if="fileModuleFiles.length"
      class="files"
    >
      <r-file
        class="files__item"
        v-for="(file, index) in fileModuleFiles"
        :key="file.filename"
        type="default"
        :title="file.filename"
        iconPreview="file"
        :file="{
          type: file.contentType,
          url: file.smallImage && file.smallImage.url
        }"
        :subTitle="file.contentLength | formatFileSize"
        :buttonSimpleOption="getActionIcon()"
        @actions="doAction(file, index)"
        @click="selectFile(file)"
      />
      <div
        v-if="isShownToggler"
        class="files__toggler"
        @click="toggleFiles"
      >
        <div class="files__img">
          <r-icon
            class="files__arrow"
            :class="{'files__arrow--active': isShownAll}"
            icon="arrow-down"
            :size="20"
          />
        </div>
        <div class="files__details">
          <div
            class="files__title"
            :style="isShownAll ? 'margin-bottom: 0' : ''"
          >
            {{ isShownAll ? 'Свернуть' : 'Показать' }}
          </div>
          <div
            v-if="!isShownAll"
            class="files__subtitle"
          >
            Еще {{ otherFilesLength }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ACCEPTED_DOC_FILE_TYPES, ACCEPTED_IMAGE_FILE_TYPES } from '@/constants';
import FileView from '@/components/FileView'
import { download } from '@/plugins/lib';

export default {
  name: 'FileView',
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    mode: {
      type: String,
      default: 'view',
    },
    isButtonSimple: {
      type: Boolean,
      default: true
    },
    toggler: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      files: [],
      fileModuleFiles: [],
      decodedFiles: [],
      isShownAll: false,
    };
  },
  computed: {
    isShownToggler () {
      return this.value.length > 4 && this.toggler
    },
    otherFilesLength () {
      return this.value.length - 3
    }
  },
  watch: {
    value: {
      handler (val) {
        this.decodedFiles = val
        const files = [...this.decodedFiles]
        this.fileModuleFiles = this.toggler && this.isShownToggler ? files.splice(0, 3) : files
      },
      immediate: true
    },
  },
  methods: {
    async upload(filesData) {
      try {
        this.checkExtension(filesData.file)
        this.checkDuplicate(filesData.file)
        const body = new FormData()
        body.append('file', filesData.file, filesData.file.name)
        body.append('filename', filesData.file.name)
        const res = await this.$axios.post(this.$root.$uploadServer, body)
        const file = res.data
        if (this.fileModuleFiles.length >= 10) return
        this.fileModuleFiles.push(file)
        this.$emit('input', this.fileModuleFiles);
        filesData.success = true
        return filesData
      } catch (err) {
        console.error(err);
        this.$rir.notification.send({
          iconOptions: {
            icon: 'error',
            fill: 'warning'
          },
          title: err.message,
          seconds: 5
        })
      }
    },
    toggleFiles () {
      this.isShownAll = !this.isShownAll
      const files = [...this.decodedFiles]
      this.fileModuleFiles = this.isShownAll ? files : files.splice(0, 3)
    },
    remove (index) {
      this.fileModuleFiles.splice(index, 1)
      this.files.splice(index, 1)
      this.$emit('input', this.fileModuleFiles)
    },
    checkDuplicate (file) {
      if (!!this.fileModuleFiles?.find((el) => el.filename === file.name)) {
        throw new Error('Вы уже загружали этот файл');
      }
    },
    checkExtension (file) {
      const fileExtension = (file.name || '').split('.').pop().toLowerCase();
      if (![...ACCEPTED_DOC_FILE_TYPES, ...ACCEPTED_IMAGE_FILE_TYPES].includes(fileExtension)) {
        throw new Error(`Невалидное расширение файла ${fileExtension}`);
      }
    },
    formatFileSize(size) {
      if (!size) return "0 б";
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return `${(size / Math.pow(1024, i)).toFixed(1)} ${["б", "Кб", "Мб", "Гб", "Гб"][i]}`;
    },
    getActionIcon() {
      if (!this.isButtonSimple) return
      switch (this.mode) {
        case 'edit':
          return {
            color: "fargo",
            icon: "delete",
          };
        case 'view':
          return {
            color: "harakiri",
            icon: "reply",
          }
      }
    },
    selectFile (file) {
      const fileExtension = (file.filename || '').split('.').pop().toLowerCase();
      ACCEPTED_IMAGE_FILE_TYPES.includes(fileExtension)
        ? this.$root.$modal.openModal(FileView, { file })
        : this.download(file)
    },
    async download(file) {
      const response = await fetch(file.url);
      const blob = await response.blob();
      download(decodeURI(file.filename), blob);
    },
    doAction(file, index) {
      switch (this.mode) {
        case 'edit':
          return this.remove(index);
        case 'view':
          this.$emit('includeAttach', file);
          return
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.file-uploader__wrapper {
  width: inherit;
  margin-bottom: 24px;

  ::v-deep {
    .RUploader label {
      margin-bottom: 0;
    }
  }
}

.files {
  margin-top: 1rem;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 16px;

  &__toggler {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding-right: 16px;
    cursor: pointer;
    background: rgba(170, 174, 182, 0.12);
  }

  &__arrow {
    transition: 0.3s;
    &--active {
      transform: rotate(180deg);
    }
  }

  &__img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 56px;
    border-radius: 8px;
    margin-right: 4px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__details{
    padding: 8px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__title{
    flex: 1 1 auto;
    width: 100%;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: block;
    max-height: 44px;
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &__subtitle {
    font-size: 13px;
    line-height: 16px;
    color: var(--rir-rocky);
  }

  &__item {
    min-width: 0;
    cursor: pointer;

    &::v-deep {
      .RFile__content {
        white-space: nowrap;
        overflow: hidden;

        .RFile__title {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
</style>

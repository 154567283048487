import router from '@/router';
import store from '@/store';

export function goBack() {
  const lastRoute = store.getters['apps/lastRoute'];
  const incident = store.getters['incident/incident'];
  const path = router.currentRoute.path?.split('/');
  if (router.currentRoute.name.match(/more/)) {
    if (incident?.consolidatedToAppealId) {
      router.push(`/${path[1]}/${incident.consolidatedToAppealId}`);
    } else if (lastRoute) {
      router.push(`${lastRoute.path}`);
    } else {
      router.push(`/${path[1]}`);
    }
  } else {
    path.shift();
    path.pop();
    router.push(`/${path.join('/')}`);
  }
}

<template>
  <div
    class="history"
    ref="history"
  >
    <div
      v-for="(stage, index) in history"
      :key="stage.id"
      :ref="`history-stage-${stage.id}`"
      class="history__stage"
    >
      <div
        v-if="heightList.length"
        class="history__line"
      >
        <svg
          class="history__line-svg"
          :height="getSvgHeight(stage.id)"
          :width="16"
        >
          <template>
            <circle
              r="8"
              cx="8"
              cy="8"
              fill="#81ABEE"
            />
            <line
              v-if="index !== history.length - 1"
              x1="8"
              x2="8"
              y1="24"
              :y2="getSvgHeight(stage.id) - 2"
              stroke="#81ABEE"
              stroke-width="4"
              stroke-linecap="round"
            />
          </template>
        </svg>
      </div>
      <div
        class="history__content ml-3"
        :ref="`history-content-${stage.id}`"
      >
        <div
          :ref="`history-title-${stage.id}`"
          class="history__content-title mb-1 comte"
          @click="toggleStage(stage.id)"
        >
          <span class="comte mr-3">{{ stage.ts | dayMonthHours }}</span>
          <r-icon
            icon="arrow-bold-down"
            fill="titanic"
            class="history__content-arrow"
            :class="{'history__content-arrow--active': isActiveArrow(stage.id)}"
          />
        </div>
        <div
          v-if="isOpen(stage.id)"
          class="mb-2"
        >
          <p class="feta anie mt-1 mb-4">
            {{ stage.modifier }}
          </p>
          <p class="feta ekas">
            {{ stage.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'History',
  props: {
    history: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      heightList: [],
      openStages: [],
      activeArrows: []
    };
  },
  watch: {
    history: {
      handler(val) {
        this.openLastStage();
        this.getHeightList();
        this.$nextTick(() => {
          val.forEach(stage => this.setStageHeight(stage.id));
        });
      },
      immediate: true
    }
  },
  methods: {
    async getHeightList() {
      if (this.history?.length) {
        this.$nextTick(() => {
          this.heightList = [];
          for (const stage of this.history) {
            const [content] = this.$refs[`history-content-${stage.id}`];
            this.heightList.push({ id: stage.id, height: content?.clientHeight });
          }
        });
      }
    },
    openLastStage() {
      const lastItem = this.history[this.history?.length - 1]?.id;
      this.openStages.push(lastItem);
      this.activeArrows.push(lastItem);
    },
    getSvgHeight(id) {
      const height = this.heightList.find(height => height.id === id);
      return height.height;
    },
    setStageHeight(id) {
      const [stage] = this.$refs[`history-stage-${id}`];
      const height = this.getSvgHeight(id);
      stage.style.height = `${height + 6}px`;
    },
    close(id) {
      const [stage] = this.$refs[`history-stage-${id}`];
      const [title] = this.$refs[`history-title-${id}`];
      stage.style.height = `${title.clientHeight + 18}px`;
    },
    toggleStage(id) {
      const stageIndex = this.openStages.findIndex(item => item === id);
      const isClosed = stageIndex === -1;
      if (isClosed) {
        this.openStages.push(id);
        this.activeArrows.push(id);
        this.getHeightList();
        this.$nextTick(() => this.setStageHeight(id));
      } else {
        this.close(id);
        this.activeArrows.splice(stageIndex, 1);
        setTimeout(() => {
          this.openStages.splice(stageIndex, 1);
          this.getHeightList();
        }, 200);
      }
    },
    isOpen(id) {
      return this.openStages.includes(id);
    },
    isActiveArrow(id) {
      return this.activeArrows.includes(id);
    }
  }
};
</script>

<style lang="scss" scoped>
.history {
  &__line {
    &-svg {
      position: relative;
      top: 6px
    }
  }

  &__stage {
    display: flex;
    overflow: hidden;
    transition: 0.2s ease-in-out;
  }

  &__content {
    width: 90%;
    height: fit-content;
    padding-bottom: 8px;

    &-title {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &-arrow {
      transition: 0.2s ease;

      &--active {
        transform: rotate(180deg);
      }
    }
  }
}
</style>

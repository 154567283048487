<template>
  <transition name="fade">
    <div
      v-if="show"
      class="actions-list"
    >
      <div
        v-for="(action, index) in actions"
        :key="action + index"
        class="actions-list__action"
        :class="{'mt-6': index !== 0}"
        @click="perform(action)"
      >
        <r-icon
          :icon="getIcon(action)"
          :fill="getColor(action)"
        />
        <span class="sulguni ml-3">{{ getTitle(action) }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
import { ActionIcons, ActionTitles, ACTIONS } from '@/constants';

export default {
  name: 'ActionsList',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      required: true
    }
  },
  computed: {
    // // @TODO - убрать моки
    // innerActions () {
    //   return [...this.actions, ACTIONS.DELEGATE_APPEAL]
    // }
  },
  methods: {
    getIcon(action) {
      return action === ACTIONS.CONSOLIDATE && this.$route.name.match(/view/)
        ? 'layers'
        : ActionIcons[action];
    },
    getTitle(action) {
      if (action === ACTIONS.SEPARATE && this.$route.name.match(/more/)) {
        return 'Отсоединить';
      } if (action === ACTIONS.CONSOLIDATE && this.$route.name.match(/view/)) {
        return 'Добавить ещё обращения';
      }
      return ActionTitles[action];
    },
    getColor(action) {
      return ([ACTIONS.IGNORE_APPEAL, ACTIONS.DECLINE_DELEGATED_APPEAL].includes(action)) ? 'fargo' : 'rocky';
    },
    closePopover() {
      this.$emit('close');
    },
    perform(type) {
      this.$emit('perform', type);
    }
  }
};
</script>

<style lang="scss" scoped>
.actions-list {
  background-color: var(--rir-amelie);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);

  &__action {
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    opacity: 0.72;
    transition: opacity, 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}
</style>

<template>
  <div class="map">
    <r-select 
      class="mb-6"
      label="Адрес"
      titleValue="value"
      idValue="id"
      :value="address"
      @input="setAddress"
      :items="decodedHints"
      :isLoading="isSearching"
      :callSearch="getHintsList"
      :disabled="isDisabled"
      isSearch
      returnObject
      isClearSearchClose
    />
    <section>
      <div class="flex-1 mb-4">
        <div class="mozzarella mb-1 anie">Координаты</div>
        <div class="feta ekas">{{ coordsString }}</div>
      </div>
      <div class="d-flex align-items-center mb-6">
        <r-button-action
          v-if="saveButton"
          class="mr-6"
          title="Сохранить"
          icon="selected"
          :isArrow="false"
          :notShorter="false"
          color="rocky"
          size="sulguni"
          @click="saveLocation"
          :disabled="isDisabledSaveBtn"
        />
        <r-button-action
          :title="isCreate ? 'Очистить' : 'Удалить'"
          :icon="isCreate ? 'close' : 'delete'"
          :isArrow="false"
          :notShorter="false"
          :color="isCreate ? 'rocky' : 'fargo'"
          size="sulguni"
          @click="deleteLocation"
          :disabled="isDisabledDeleteBtn"
        />
      </div>
    </section>
    <map-component
      class="map__container mb-6"
      :location="coords"
      @setCoords="setCoords"
      :editable="!isSearching && !isDisabled"
    />
  </div>
</template>

<script>
import MapComponent from '@/components/MapComponent';
import { mapActions, mapState } from 'vuex';
import { debounce } from '@/plugins/lib';
import { STATES } from '@/constants';

export default {
  name: 'Map',
  components: { MapComponent },
  props: {
    value: {
      type: Object,
      default: null
    },
    saveButton: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      decodedHints: [],
      isSearching: false,
      location: null,
      address: null,
      id: null,
      isActiveSaveBtn: false
    }
  },
  watch: {
    value: {
      handler (val) {
        this.location = val
        this.address = {
          id: val?.fiasCodeId || null,
          value: val?.address || null
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('map', ['addressPrefix']),
    coordsString () {
      return this.coords ? this.coords.join(', ') : '00.000000, 00.000000'
    },
    coords () {
      return this.location ? [this.location?.lat, this.location?.lng] : null
    },
    isDisabledDeleteBtn () {
      return !this.location || this.isDisabled
    },
    isDisabledSaveBtn () {
      return !this.isActiveSaveBtn || this.isDisabled
    },
    isCreate () {
      return this.$route.name === 'create'
    },
    isDisabled () {
      return [STATES.IN_WORK,
        STATES.REMOVED,
        STATES.SOLVED,
        STATES.ERROR
      ].includes(this.$route.meta.type) || this.editable
    }
  },
  methods: {
    ...mapActions('map', ['geocode', 'reverseGeocode', 'getHints']),
    ...mapActions('directory', ['getDivision']),
    getHintsList: debounce(async function (query) {
      if (query) {
        this.decodedHints = await this.getHints(`${this.addressPrefix}, ${query}`)
          .catch(() => {
            this.$rir.notification.send({
              title: 'При загрузке подсказок возникли технические неполадки, повторите попытку позже',
              seconds: 5
            })
          })
        return this.decodedHints || []
      } else {
        this.decodedHints = []
        return this.decodedHints
      }
    }, 1000), 
    async setCoords (coords) {
      if (!coords) return
      this.isSearching = true
      const coordinates = [...coords]
      const res = await this.geocode(coordinates)
      this.location = { ...res }
      this.address = this.location?.address
        ? {
          id: this.location?.fiasCodeId,
          value: this.location?.address
        }
        : null
      this.isActiveSaveBtn = true
      if (!this.address) this.getNotification('Адрес не найден')
      if (!this.saveButton && this.address) this.saveLocation()
      this.isSearching = false
    },
    async setAddress (address) {
      if (!address) return
      if (!(address.geo_lat && address.geo_lon)) {
        try {
          this.isSearching = true
          const res = await this.reverseGeocode(address.value)
          const coords = res?.Point?.pos.split(' ').reverse()
          if (coords) {
            address.geo_lat = coords[0],
            address.geo_lon = coords[1]
          } else {
            this.getNotification('По данному адресу ничего не найдено')
          }
        } catch {
          this.getNotification('По данному адресу ничего не найдено')
        } finally {
          this.isSearching = false
        }
      }
      this.location = {
        ...this.location,
        fiasCodeId: address.id || null,
        address: address.value,
        lat: address.geo_lat,
        lng: address.geo_lon
      }
      this.address = {
        id: address.id || null,
        value: address.value
      }
      this.isActiveSaveBtn = true
      if (!this.saveButton) this.saveLocation()
    },
    saveLocation () {
      this.$emit('input', this.location)
      this.getNotification('Адрес сохранен')
      this.isActiveSaveBtn = false
    },
    deleteLocation () {
      this.location = null
      this.address = null
      if (this.saveButton) {
        this.$emit('input', this.location)
        this.getNotification('Адрес удален')
      }
    },
    getNotification (text) {
      this.$rir.notification.send({
        title: text,
        seconds: 5
      })
    }
  }
};
</script>

<style lang="scss" scoped>

.flex-1 {
  flex: 1
}
.map {
 &__container {
    height: 300px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
  }
}
</style>

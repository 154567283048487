import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import getEnv from '@/plugins/env';
import { incidentAnalyzeRoutes } from '@/router/routes/incident-analyze.routes';
import { incidentCompletedRoutes } from '@/router/routes/incident-completed.routes';
import { incidentCreateRoutes } from '@/router/routes/create.routes';
import { incidentErrorRoutes } from '@/router/routes/incident-error.routes';
import { incidentNewRoutes } from '@/router/routes/incident-new.routes';
import { incidentDeletedRoutes } from '@/router/routes/incident-deleted.routes';
import { incidentWorkRoutes } from '@/router/routes/incident-work.routes';
import { incidentPausedRoutes } from '@/router/routes/incident-paused.routes';
import { incidentAdditionalQuestionRoutes } from '@/router/routes/incident-additional-question.routes';
import { statisticsRoutes } from '@/router/routes/statistics.routes';
import { settingsRoutes } from '@/router/routes/settings.routes';
import NotFound from '@/components/NotFound';
import DefaultLayout from '@/layout/DefaultLayout';

Vue.use(VueRouter);

const routes = [
  ...incidentAnalyzeRoutes,
  ...incidentCompletedRoutes,
  ...incidentCreateRoutes,
  ...incidentErrorRoutes,
  ...incidentDeletedRoutes,
  ...incidentNewRoutes,
  ...incidentWorkRoutes,
  ...statisticsRoutes,
  ...incidentAdditionalQuestionRoutes,
  ...incidentPausedRoutes,
  ...settingsRoutes,
  {
    path: '/', redirect: { name: 'incident-new' }
  },
  {
    path: '*',
    components: { default: NotFound },
    meta: {
      layout: DefaultLayout
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: getEnv('BASE_URL'),
  routes
});

router.beforeEach((to, from, next) => {
  store.commit('apps/setLastRoute', from);
  store.commit('apps/setRoute', to);
  next();
});

export default router;

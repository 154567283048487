<template>
  <div class="accordion">
    <div v-if="post">
      <div :class="[{'accordion__post-content--open': isOpen && isShownAccordion}, {'accordion__post-content': isShownAccordion}]">
        <slot />
      </div>
      <div
        v-if="isShownAccordion"
        @click="toggleAccordion"
        class="mt-2 accordion__header"
      >
        <div
          class="accordion__title briscola"
          :class="`${color}--text`"
        >
          {{ isOpen ? 'Cвернуть' : 'Читать полностью' }}
        </div>
        <r-icon
          class="accordion__arrow ml-2"
          :class="{'accordion__arrow--active': isOpen}"
          icon="arrow-down"
          :fill="color"
          :size="8"
        />
      </div>
    </div>
    <div v-else>
      <div
        @click="toggleAccordion"
        class="mb-6 accordion__header"
      >
        <div class="accordion__title comte">
          <slot name="header">
            Развернуть
          </slot>
        </div>
        <r-icon
          class="accordion__arrow ml-2"
          :class="{'accordion__arrow--active': isOpen}"
          icon="arrow-bold-down"
          fill="titanic"
          :size="16"
        />
      </div>
      <transition
        name="accordion"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <div
          v-if="isOpen"
          class="accordion__content"
        >
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Accordion',
  props: {
    post: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'orange'
    }
  },
  data() {
    return {
      isOpen: false,
      isShownAccordion: true,
      id: ''
    };
  },
  computed: {
    color() {
      const colors = {
        orange: 'lebowski',
        blue: 'rocky',
        white: 'rocky',
        green: 'matrix'
      };
      return colors[this.type];
    }
  },
  mounted() {
    this.setIsShownAccordion();
    window.addEventListener('resize', () => this.setIsShownAccordion());
  },
  beforeDestroy() {
    window.removeEventListener('resize');
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    setIsShownAccordion() {
      this.isShownAccordion = this.$slots.default[0].elm.clientHeight > 72;
    },
    beforeEnter(el) {
      el.style.height = '0';
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    leave(el) {
      el.style.height = '0';
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion {
  &__post-content {
    height: 72px;
    overflow: hidden;
    transition: 0.2s ease-in-out;

    &--open {
      height: fit-content;
    }
  }

  &__content {
    overflow: hidden;
    transition: 0.2s ease-in-out;
  }

  &__arrow {
    transition: 0.3s;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
</style>

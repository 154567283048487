<template>
  <div class="incident-list-toolbar">
    <div class="incident-list-toolbar__head">
      <h2 class="camembert clamp-1">{{ $route.meta.title }}</h2>
      <user-info />
    </div>
    <div
      v-if="showForm"
      class="mt-5"
    >
      <div class="mb-6">
        <r-tabs
          class="incident-list-toolbar__tabs"
          :class="{ disabled: isLoading }"
          :value="systemCode"
          id-value="code"
          title-value="name"
          :items="systemCodes"
          @input="changeTab"
        >
          <template #default="{ item }">
            <div
              class="incident-list-toolbar__tabs-counter edam ekas"
              :class="item.expired"
            >
              {{ item.count || 0 }}
            </div>
          </template>
        </r-tabs>
      </div>
      <div
        class="d-flex justify-content-between align-items-center"
        :class="{disabled: isLoading}"
      >
        <r-input
          class="incident-list-toolbar__input"
          v-model="searching"
          @input="debounceInput"
          label="Поиск"
        />
        <FormSelect
          v-if="isShownAccounts"
          class="incident-list-toolbar__select"
          label="Аккаунт"
          selectAllTitle="Все аккаунты"
          :disabled="!filteredAccounts.length"
          :items="filteredAccounts"
          v-model="account"
          idValue="id"
          titleValue="name"
          subTitleValue="systemName"
          isSelectAll
          returnObject
        />
        <FormSelect
          v-if="isShownAccounts"
          class="incident-list-toolbar__select"
          label="Тип упоминания"
          selectAllTitle="Все типы"
          :disabled="!filteredAppealTypes.length"
          :items="filteredAppealTypes"
          v-model="accountChannelCode"
          idValue="code"
          titleValue="name"
          subTitleValue="systemName"
          isSelectAll
          returnObject
        />
        <div
          v-if="isShownFilters"
          class="ml-6"
        >
          <r-bulb
            class="filters"
            color="matrix"
            :title="filtersCount ? String(filtersCount) : null"
            position="nikaia"
            size="bretiax"
          >
            <template #content>
              <r-button-simple
                class="incident-list-toolbar__filters d-flex align-items-center"
                type="secondary"
                @click="openFilters"
                icon="filter"
                size="larishae"
              />
            </template>
          </r-bulb>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/plugins/lib';
import FiltersModal from '@/views/incident/modal/FiltersModal';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import UserInfo from '@/layout/components/UserInfo';
import FormSelect from '@/components/FormSelect';
import { STATES } from '@/constants'
import getEnv from '@/plugins/env';


export default {
  name: 'IncidentListToolbar',
  components: { UserInfo, FormSelect },
  data() {
    return {
      searching: null,
      accountChannelCode: [],
      account: [],
      systemCodes: [],
      filteredAppealTypes: [],
      filteredAccounts: [],
      changedSystemCode: false
    };
  },

  computed: {
    ...mapState('incident', ['filters', 'isLoading', 'appealTypes']),
    ...mapGetters('incident', ['filtersCount']),
    ...mapState('directory', ['messageSources', 'appealSystemCounter', 'accounts', 'systemCode']),
    showForm() {
      return this.$route.name !== 'create'
        && this.$route.name !== 'settings'
        && this.$route.name !== 'statistics';
    },
    isShownFilters () {
      return this.$route.meta.type !== STATES.ERROR
    },
    isShownAccounts() {
      return !!+getEnv('VUE_APP_SHOW_ACCOUNTS_FILTER');
    },
  },

  watch: {
    $route(val) {
      this.clearFilterProperties();
      this.setAccountChannelCode();
      this.setAccountIds();
      this.resetIncidents();
      this.searching = null;
      this.setDirectoryProp({
        name: 'systemCode',
        value: { name: 'Все источники', code: 'total' }
      })
      if (!this.showForm) return;
      this.fetchIncidentList();
      this.getSystemCodeCounters(this.appealSystemCounter);
      this.$rir.modal?.close(null, true)
    },
    appealSystemCounter: {
      handler(val) {
        this.getSystemCodeCounters(val);
      },
      deep: true
    },
    'filters.searching': {
      handler(val) {
        this.searching = val || null;
      },
      immediate: true
    },
    filters: {
      handler(val) {
        this.setDirectoryProp({
          name: 'systemCode',
          value: val.systemCodes ? this.systemCodes.find(code => code.code === val.systemCodes[0]) : { name: 'Все источники', code: 'total' }
        })
      },
      immediate: true,
      deep: true
    },

    accountChannelCode: {
      handler (newVal, oldVal) {
        if (oldVal === newVal) return;
        if (!this.changedSystemCode)  {
          this.setAccountChannelCode();
          this.accountsInSource(this.accountChannelCode?.length
            ? this.accountChannelCode.map(el => el.system)
            : [this.systemCode.code]
          )
          this.resetIncidents();
          if (!this.changedSystemCode) this.fetchIncidentList();
        }
      },
      deep: true
    },

    account: {
      handler (newVal, oldVal) {
        if (oldVal === newVal) return;
        if (!this.changedSystemCode)  {
          this.setAccountIds();
          this.typesInSource(this.account?.length
            ? this.account.map(el => el.systemCode)
            : [this.systemCode.code]
          )
          this.resetIncidents();
          this.fetchIncidentList();
        }
      },
      deep: true,
    },

    systemCode(newVal, oldVal) {
      if (oldVal.code === newVal.code) return;
      this.changedSystemCode = true
      this.setFilterProperty({
        attr: 'systemCodes',
        value: newVal.code === 'total' ? null : [this.systemCode.code]
      });
      this.accountChannelCode = []
      this.account = []
      this.setAccountChannelCode();
      this.setAccountIds();
      this.typesInSource([this.systemCode.code])
      this.accountsInSource([this.systemCode.code])
      this.resetIncidents();
      this.fetchIncidentList();
    }
  },

  async mounted() {
    this.getAppealSystem();
    this.getSystemCodeCounters(this.appealSystemCounter);
    this.resetIncidents();
    await this.fetchIncidentList(),
    await Promise.all([
      this.getAccounts(),
      this.getIncidentTypes()
    ])
    this.typesInSource([this.systemCode.code])
    this.accountsInSource([this.systemCode.code])
  },

  methods: {
    ...mapMutations('incident', ['clearFilterProperties', 'setFilterProperty', 'setProperty']),
    ...mapMutations('directory', { setDirectoryProp: 'setProperty' }),
    ...mapActions('incident', ['getIncidents', 'getIncidentTypes', 'getIncidentCount']),
    ...mapActions('directory', ['getAppealSystem', 'getAccounts']),
    setAccountChannelCode () {
      this.setFilterProperty({
        attr: 'accountChannelCode',
        value: this.accountChannelCode?.length ? this.accountChannelCode.map(el => el.code) : undefined
      });
    },
    setAccountIds () {
      this.setFilterProperty({
        attr: 'accountIds',
        value: this.account?.length ? this.account.map(el => el.id) : undefined
      });
    },
    typesInSource(source) {
      this.filteredAppealTypes = source.includes('total')
        ? this.appealTypes
        : this.appealTypes.filter(el => source.includes(el.system));
    },
    accountsInSource(source) {
      this.filteredAccounts = source.includes('total')
        ? this.accounts
        : this.accounts.filter(el => source.includes(el.systemCode));
    },
    openFilters() {
      this.$root.$modal.openModal(FiltersModal);
    },
    changeTab(tab) {
      this.setDirectoryProp({
        name: 'systemCode',
        value: tab
      })
    },
    async fetchIncidentList() {
      this.filters.accountChannelCode?.length || this.filters.accountIds?.length || this.filters.searching || this.filtersCount
        ? this.getIncidentCount({
          category: [this.$route.meta.type],
        })
        : this.setProperty({ name: 'incidentCount', value: 0 });
      await this.getIncidents({
        category: [this.$route.meta.type],
        sortBy: this.filters.sortBy || this.$route.meta.sortBy
      });
      this.changedSystemCode = false
    },
    getSystemCodeCounters(systemCounter) {
      const codes = [...this.messageSources, { name: 'Все источники', code: 'total' }];
      const counters = systemCounter[this.$route.meta.type];
      this.systemCodes = codes.map(item => {
        const count = counters?.[item.code]?.count;
        const expired = counters?.[item.code]?.exp;
        const countValue = count > 999 ? '999+' : count;
        return {
          ...item,
          optionsBulb: {
            title: countValue ? String(countValue) : null,
            color: expired ? 'fargo' : 'rocky',
            position: 'polyxo'
          }
        };
      });
    },
    resetIncidents() {
      this.setProperty({ name: 'incidents', value: [] });
      this.setProperty({ name: 'appealActions', value: [] });
      this.setFilterProperty({ attr: 'pageIndex', value: 0 });
    },
    debounceInput: debounce(function () {
      this.resetIncidents();
      this.setFilterProperty({ attr: 'searching', value: this.searching });
      this.fetchIncidentList();
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>
::v-deep .RBulb.filters .RBulb__badge:not(:empty){
  color: var(--rir-white) !important;
  background-color: var(--rir-matrix);
  flex: 1 0 16px;
  height: 16px;
  display: flex;
  padding: 2px 4px;
  min-width: 16px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.incident-list-toolbar {
  width: 100%;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__input {
    flex: 2;
  }

  &__select {
    flex: 1;
    margin-left: 24px;
  }

  &__tabs {

    &-counter {
      position: absolute;
      top: 0;
      left: calc(100% + 4px);
    }

    &::v-deep {
      .r-tabs__item {
        margin-right: 32px;
      }
      .r-tabs__content--separator.rows>.r-tabs__item+.r-tabs__item {
        margin-left: 4px;
      }
    }
  }

  &__filters {
    position: relative;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 8px;

    &-count {
      position: absolute;
      background-color: var(--rir-matrix);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      top: 3px;
      right: 8px;

      span {
        font-size: 10px;
        line-height: 12px;
        color: var(--rir-amelie);
      }
    }
  }
}

.disabled {
  opacity: 0.72;
  cursor: auto;
  pointer-events: none;
}
</style>

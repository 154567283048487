import apps from './apps';
import directory from './directory';
import session from './session';
import incident from './incident';
import settings from './settings';
import map from './map';
import notification from './notification';

export default {
  apps,
  directory,
  session,
  incident,
  settings,
  map,
  notification
};

export default {
  incident(state) {
    return state.incident;
  },
  filtersCount(state) {
    const {
      pageIndex,
      size,
      dueDateFrom,
      extDueDateFrom,
      endDateFrom,
      category,
      systemCodes,
      accountIds,
      accountChannelCode,
      sortBy,
      searching,
      ...filters
    } = state.filters;
    let counter = 0;
    for (const k in filters) {
      if (filters[k]) counter++;
    }
    return counter;
  },
};

<template>
  <div class="move-to-resolved-modal">
    <div class="move-to-resolved-modal__wrapper">
      <r-icon
        fill="rocky"
        class="mb-6"
        icon="warning"
        :size="56"
      />
      <div class="mb-4 titanic--text camembert">
        {{ title }}
      </div>
      <div class="mb-6">
        {{ subtitle }}
      </div>
      <div
        v-if="isMedialogy"
        class="move-to-resolved-modal__info mb-6"
      >
        <div class="bryndza fargo--text mb-1">
          Внимание!
        </div>
        <div class="mozzarella prestige--text">
          Выбрано обращение из источника «Медиалогия». Ответ на обращение не будет отправлен, и оно будет помещено в раздел «Решённые»
        </div>
      </div>
      <FormTextarea
        class="mb-8"
        :rows="5"
        label="Комментарий, не виден автору"
        v-model="model.feedback"
        :error="$v.model.feedback.$error"
        :errorMessage="mesErr($v.model.feedback)"
      />
      <div class="move-to-resolved-modal__buttons">
        <r-button
          class="move-to-resolved-modal__button"
          @click="$root.$modal.closeModal()"
          color="secondary"
          title="Не завершать"
        >
        </r-button>
        <r-button
          class="move-to-resolved-modal__button ml-8"
          @click="moveToResolved"
          :isLoading="sending"
          :disabled="sending"
          title="Да, завершить без ответа"
        >
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { ACTIONS } from '@/constants';
import { required } from '@/plugins/vuelidate/customValidate';
import { validationMixin } from 'vuelidate';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import FormTextarea from '@/components/FormTextarea'

export default {
  name: 'MoveToResolvedModal',
  mixins: [validationMixin, validateMixin],
  components: { FormTextarea },
  props: {
    incidents: {
      type: Array
    }
  },
  data() {
    return {
      sending: false,
      model: {
        typeCode: 'PUBLICATION',
        feedback: null,
        attachments: [],
        publisherId: null
      }
    };
  },
  validations: {
    model: {
      feedback: {
        required: required('Обязательное поле')
      }
    }
  },
  computed: {
    ...mapState('session', ['user']),
    title() {
      return this.incidents.length > 1 ? 'Завершить обращения без ответа?' : 'Завершить обращение без ответа?';
    },
    subtitle() {
      return this.incidents.length > 1
        ? 'Выбранные обращения будут перемещены в раздел «Решённые»'
        : 'Выбранное обращение будет перемещено в раздел «Решённые»';
    },
    isMedialogy() {
      return this.incidents.filter(incident => incident.data.systemCode === 'MEDIALOGIA-IM').length;
    }
  },
  methods: {
    ...mapMutations('incident', ['setFilterProperty', 'setProperty', 'setSelectionMode']),
    ...mapActions('incident', ['moveIncident', 'getIncidents']),
    ...mapMutations('apps', ['setAnchor']),
    async moveToResolved() {
      this.$v.$touch();
      if (this.$v.$invalid || this.sending) return;
      const payload = this.incidents.map(incident => ({
        id: incident.data.id,
        statusCode: incident.data.action,
        moderatorId: this.user.id,
        feedback: this.model,
        decision: ACTIONS.COMPLETE_WITHOUT_RESPONSE
      }));
      this.sending = true;
      await this.moveIncident(payload)
        .then(async () => {
          if (this.$route.params.id) {
            this.setAnchor(0);
            const path = this.$route.path?.split('/');
            this.$router.push(`/${path[1]}`);
          } else {
            this.setProperty({ name: 'incidents', value: [] });
            this.setProperty({ name: 'appealActions', value: [] });
            this.setFilterProperty({ attr: 'pageIndex', value: 0 });
            await this.getIncidents({
              category: [this.$route.meta.type],
              sortBy: this.$route.meta.sortBy
            });
          }
          this.sending = false;
          this.$root.$modal.closeModal()
          this.$rir.notification.send({
            title: payload.length > 1 ? 'Обращения перемещены в решенные' : 'Обращение перемещено в решенные',
            seconds: 5
          });
        })
        .catch(() => {
          this.sending = false;
          this.$root.$modal.closeModal()
        })
        .finally(() => this.setSelectionMode(false));
    }
  }
};
</script>

<style lang="scss" scoped>
.move-to-resolved-modal {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;

  &__wrapper {
    max-width: 800px;
    margin: auto;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    flex: 1;
  }

  &__info {
    background-color: #fef8f9;
    padding: 24px;
    border-radius: 16px;
  }
}
</style>

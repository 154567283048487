<template>
  <div class="statistics">
    <div class="statistics__header d-flex align-items-center mb-10">
      <FormSelect
        class="statistics__control"
        label="Тип отчёта"
        :items="reports"
        v-model="report"
        idValue="id"
        titleValue="value"
        returnObject
      />
      <FormSelect
        class="statistics__control ml-6"
        label="Формат файла отчёта"
        :items="formats"
        idValue="id"
        titleValue="value"
        v-model="format"
      />
      <FormDatePicker
        class="statistics__control ml-6"
        period
        v-model="$v.model.period.$model"
        :error="$v.model.period.$error"
        :errorMessage="mesErr($v.model.period)"
        label="Отчётный период"
      />
    </div>
    <transition name="fade">
      <div v-if="report.id === 'common'" class="statistics__additional">
        <FormSelect
          label="Департамент"
          idValue="id"
          titleValue="name"
          :items="departments"
          v-model="model.departmentId"
        />
        <FormSelect
          label="Исполнитель"
          idValue="id"
          titleValue="fullName"
          :items="executorsInDepartment"
          v-model="model.executorId"
          :disabled="!model.departmentId"
        />
        <FormSelect
          label="Проблема"
          idValue="id"
          titleValue="name"
          :items="problemsInDepartment(model.departmentId)"
          v-model="model.problemId"
          :disabled="!model.departmentId"
        />
        <FormSelect
          label="Статус обращения"
          idValue="code"
          titleValue="value"
          :items="statuses"
          v-model="model.categoryCode"
        />
        <FormSelect
          label="Своевременность исполнения"
          idValue="code"
          titleValue="value"
          :items="disciplines"
          v-model="model.disciplineCode"
        />
      </div>
    </transition>
    <div class="statistics__chart" />
    <r-button
      style="width: 100%;"
      @click="downloadReport"
      :isLoading="isLoading"
      :disabled="isLoading"
      title="Скачать"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { download } from '@/plugins/lib'
import FormSelect from '@/components/FormSelect'
import FormDatePicker from '@/components/FormDatePicker'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { period, required } from '@/plugins/vuelidate/customValidate'

const defaultFormats = [
    {
      id: 'pdf',
      value: 'PDF'
    },
    {
      id: 'xlsx',
      value: 'XLS'
    }
  ]

export default {
  name: 'statistics-view',
  components: { FormSelect, FormDatePicker },
  mixins: [validationMixin, validateMixin],
  data () {
    return {
      isLoading: false,
      model: {
        period: null,
        problemId: null,
        departmentId: null,
        executorId: null,
        disciplineCode: null,
        categoryCode: null
      },
      formats: defaultFormats,
      format: 'xlsx',
      reports: [
        {
          id: 'aggregate/executor',
          value: 'По исполнителям'
        },
        {
          id: 'aggregate/type',
          value: 'По проблемам'
        },
        {
          id: 'aggregate/department',
          value: 'По департаментам'
        },
        {
          id: 'common',
          value: 'Настраиваемый'
        },
        {
          id: 'appeal-by-appeal-system',
          value: 'По обращениям'
        },
        {
          id: 'appeal-by-category',
          value: 'По статусам обращений'
        },
      ],
      report: {
        id: 'aggregate/executor',
        value: 'По исполнителям'
      }
    }
  },
  validations () {
    return {
      model: {
        period: {
          period: period('Введите период'),
          required: required('Обязательное поле')
        },
      }
    }
  },
  watch: {
    'model.departmentId': function () {
      this.model.executorId = null
      this.model.problemId = null
    },
    report (val) {
      this.formats = val.id === 'appeal-by-appeal-system' || val.id === 'appeal-by-category'
        ? [
          {
            id: 'xlsx',
            value: 'XLS'
          }
        ]
        : defaultFormats
    }
  },
  computed: {
    ...mapGetters(['GET_BLOB']),
    ...mapGetters('directory', ['problemsInDepartment']),
    ...mapState('directory', ['problems', 'departments', 'executors', 'statuses', 'disciplines']),
    executorsInDepartment () {
      return this.executors.filter(el => el.departmentId === this.model.departmentId)
    }
  },
  methods: {
    async downloadReport () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isLoading = true
      const model = {
        ...this.model,
        from: this.model.period?.length ? +new Date(this.model.period[0]) : null,
        to: this.model.period?.length ? +new Date(this.model.period[1]) : null
      }
      delete model.period
      const { data } = await this.GET_BLOB(`report/${this.format}/${this.report.id}`, model)
      const name = 'Отчет ' + this.report.value.toLowerCase()
      download(`${name} ${this.generateNameFile()}.${this.format}`, data)
      this.isLoading = false
    },
    generateNameFile () {
      const nameList = []
      if (this.report.id !== 'common') {
        Object.keys(this.model).forEach(key => {
          if (this.model[key]) {
            switch (key) {
              case 'problemId':
                nameList.push('Проблеме')
                break
              case 'departmentId':
                nameList.push('Департаменту')
                break
              case 'executorId':
                nameList.push('Исполнителю')
                break
              case 'categoryCode':
                nameList.push('Статусу')
                break
              case 'disciplineCode':
                nameList.push('Дисциплине')
                break
            }
          }
        })
      }
      const from = this.model.period[0]
      const to = this.model.period[1]
      let nameFile = ''
      if (nameList.length) {
        nameFile += `по ${nameList.join('/')} `
      }
      return `${nameFile}за период с ${from} по ${to}`
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics {
  padding-top: 62px !important;
  padding: 0 32px;
  height: 94vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__chart {
    margin: auto;
    height: 206px;
    width: 621px;
    background-image: url("/img/img/chart.svg");
  }

  &__additional {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }

  &__control {
    flex: 1;
  }
}
</style>

<template>
  <div
    class="pipeline"
    ref="pipeline"
  >
    <div class="pipeline__wrapper">
      <div
        v-for="(stage, index) in pipelineData"
        :key="stage.id"
        :ref="`pipeline-stage-${stage.id}`"
        class="pipeline__stage"
      >
        <div
          v-if="heightList.length"
          class="pipeline__line"
        >
          <svg
            class="pipeline__line-svg"
            :ref="`pipeline-line-${stage.id}`"
            :height="getSvgHeight(stage.id)"
            :width="16"
          >
            <template>
              <template v-if="!isCompleted && index === pipelineData.length - 1">
                <path
                  d="M7.91233 0C7.35399 0 6.90137 0.447715 6.90137 1C6.90137 1.55228 7.35399 2 7.91233 2C11.2624 2 13.9781 4.68629 13.9781 8C13.9781 11.3137 11.2624 14 7.91233 14C6.96498 14 6.07103 13.7859 5.27472 13.4048C4.44998 13.0102 3.72832 12.4356 3.16256 11.7322C2.81494 11.3 2.17895 11.2284 1.74202 11.5723C1.3051 11.9161 1.23271 12.5452 1.58033 12.9774C2.333 13.9132 3.29365 14.6786 4.39422 15.2052C5.45912 15.7148 6.65348 16 7.91233 16C12.379 16 16 12.4183 16 8C16 3.58172 12.379 0 7.91233 0Z"
                  :fill="iconColor"
                />
                <path
                  d="M7.98864 4.76125C7.98864 4.20896 8.44767 3.74909 8.99064 3.87775C10.874 4.32403 12.274 6.00039 12.274 8.00019C12.274 10.3413 10.3554 12.2391 7.98864 12.2391C6.95035 12.2391 5.99846 11.8739 5.2569 11.2662C4.82672 10.9136 4.92278 10.2747 5.36968 9.94311L7.98864 8.00019V4.76125Z"
                  :fill="iconColor"
                />
                <path
                  d="M2.02784 9.55469C2.02784 10.107 1.57522 10.5547 1.01688 10.5547C0.458545 10.5547 0.00592359 10.107 0.00592359 9.55469C0.00592359 9.0024 0.458545 8.55469 1.01688 8.55469C1.57522 8.55469 2.02784 9.0024 2.02784 9.55469Z"
                  :fill="iconColor"
                />
                <path
                  d="M1.01096 7.43701C1.5693 7.43701 2.02192 6.9893 2.02192 6.43701C2.02192 5.88473 1.5693 5.43701 1.01096 5.43701C0.452622 5.43701 0 5.88473 0 6.43701C0 6.9893 0.452622 7.43701 1.01096 7.43701Z"
                  :fill="iconColor"
                />
                <path
                  d="M3.39027 3.63281C3.39027 4.1851 2.93764 4.63281 2.37931 4.63281C1.82097 4.63281 1.36835 4.1851 1.36835 3.63281C1.36835 3.08053 1.82097 2.63281 2.37931 2.63281C2.93764 2.63281 3.39027 3.08053 3.39027 3.63281Z"
                  :fill="iconColor"
                />
                <path
                  d="M4.83562 2.69531C5.39396 2.69531 5.84658 2.2476 5.84658 1.69531C5.84658 1.14303 5.39396 0.695312 4.83562 0.695312C4.27728 0.695312 3.82466 1.14303 3.82466 1.69531C3.82466 2.2476 4.27728 2.69531 4.83562 2.69531Z"
                  :fill="iconColor"
                />
              </template>
              <circle
                v-else
                r="8"
                cx="8"
                cy="8"
                fill="#81ABEE"
              />
              <template v-if="!(isCompleted && index === pipelineData.length - 1)">
                <transition name="fade">
                  <line
                    v-if="isOpen(stage.id)"
                    x1="8"
                    x2="8"
                    y1="24"
                    :y2="getSvgHeight(stage.id) - 2"
                    :stroke="expired > 0 && inWork && index === pipelineData.length - 1
                      ? '#E14761'
                      : '#C0D6F6'"
                    stroke-width="4"
                    stroke-linecap="round"
                  />
                </transition>
                <transition name="fade">
                  <line
                    v-if="isOpen(stage.id) && index === pipelineData.length - 1 && inWork"
                    x1="8"
                    x2="8"
                    y1="24"
                    :y2="getProgressLineFill(stage.id)"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-linecap="round"
                  />
                </transition>
                <line
                  x1="8"
                  x2="8"
                  y1="24"
                  :y2="getLineFill(stage.id, index)"
                  stroke="#81ABEE"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </template>
            </template>
          </svg>
        </div>
        <div
          class="pipeline__content ml-3"
          :ref="`pipeline-content-${stage.id}`"
        >
          <div
            :ref="`pipeline-title-${stage.id}`"
            class="pipeline__content-title mb-1 comte"
            @click="toggleStage(stage.id)"
          >
            <span class="comte mr-3">{{ stage.description }}</span>
            <r-icon
              icon="arrow-bold-down"
              fill="titanic"
              class="pipeline__content-arrow"
              :class="{'pipeline__content-arrow--active': isActiveArrow(stage.id)}"
            />
          </div>
          <div
            class="pipeline__content-details"
            v-if="isOpen(stage.id)"
          >
            <p class="feta ekas">
              {{ stage.createdAt | dayMonthHours }}
            </p>
            <div
              v-if="stage.executor"
              class="pipeline__content-executor mt-4"
            >
              <div class="d-flex align-items-center mb-1">
                <r-icon
                  fill="rocky"
                  icon="avatar"
                />
                <span class="mozzarella ekas ml-2">Исполнитель</span>
              </div>
              <p class="feta ekas mb-1">
                {{ getExecutorName(stage.executor) }}
              </p>
              <p class="mozzarella anie mb-1">
                {{ getDepartament(stage.executor) }}
              </p>
            </div>
            <div
              v-if="stage.timeToCompleteWork"
              class="pipeline__content-progress mt-4"
            >
              <div class="d-flex align-items-center mb-1">
                <r-icon
                  fill="rocky"
                  icon="selected"
                />
                <span class="mozzarella ekas ml-2">Выполнено за</span>
              </div>
              <span class="feta anie">{{ stage.timeToCompleteWork }}</span>
            </div>
            <div
              v-if="stage.additional"
              class="mt-4"
            >
              <span class="feta ekas">{{ stage.additional }}</span>
            </div>
            <div
              v-if="index === pipelineData.length - 1"
              class="mt-4"
            >
              <div v-if="expired > 0 && inWork">
                <div class="d-flex align-items-center mb-1">
                  <r-icon
                    icon="warning"
                    fill="fargo"
                  />
                  <span class="mozzarella ekas ml-2">Просрочено на</span>
                </div>
                <p class="feta fargo--text">{{ expired | hours }} </p>
                <p class="mozzarella ekas mt-2">(срок исполнения {{ requiredDuration | hours }})</p>
              </div>
              <div v-else-if="expired <= 0 && inWork">
                <div class="d-flex align-items-center mb-1">
                  <r-icon
                    fill="rocky"
                    icon="timer"
                  />
                  <span class="mozzarella anie ml-2">Осталось</span>
                </div>
                <span class="feta">{{ remainder | hours }}</span>
                <span class="feta anie ml-1">из {{ requiredDuration | hours }}</span>
              </div>
              <span
                v-else-if="!isCompleted && !error"
                class="feta ekas"
              >
                В процессе
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pipeline__finish"
        v-if="!isCompleted"
      >
        <svg
          :height="80"
          :width="16"
        >
          <template>
            <circle
              r="2"
              cx="8"
              cy="6"
              fill="#C0D6F6"
            />
            <circle
              r="2"
              cx="8"
              cy="14"
              fill="#C0D6F6"
            />
            <circle
              r="2"
              cx="8"
              cy="22"
              fill="#C0D6F6"
            />
            <line
              x1="8"
              x2="8"
              y1="28"
              y2="50"
              stroke="#C0D6F6"
              stroke-width="4"
            />
            <circle
              r="8"
              cx="8"
              cy="62"
              fill="#C0D6F6"
            />
          </template>
        </svg>
        <div class="pipeline__content pipeline__content--finish ml-3">
          <span class="comte mb-1 biqe">
            Ответ заявителю
          </span>
          <!-- <p v-if="inWork" class="feta">
            <span class="lebowski--text">Исполнить до</span>
            <span class="titanic--text ekas ml-2">{{ deadline | dayMonthHours }}</span>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { STATES } from '@/constants';

export default {
  name: 'Pipeline',
  props: {
    incident: {
      type: Object
    },
    pipelineData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      heightList: [],
      openStages: [],
      activeArrows: []
    };
  },
  mounted () {
    window.addEventListener('resize', () => this.heightPiplineRecalculation());
  },
  beforeDestroy() {
    window.removeEventListener('resize');
  },
  watch: {
    pipelineData: {
      handler() {
        this.openLastStage();
        this.getHeightList();
        this.$nextTick(() => {
          this.heightPiplineRecalculation()
        });
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('directory', ['departments']),
    requiredDuration() {
      return Math.abs(this.incident.dueDate - this.incident.executorTs);
    },
    deadline() {
      return this.incident.dueDate;
    },
    isCompleteAppeal() {
      return this.incident?.action === 'COMPLETE_APPEAL';
    },
    expired() {
      return Date.now() - this.deadline;
    },
    remainder() {
      return this.deadline - Date.now();
    },
    percentOfProgress() {
      return (Date.now() - this.incident.executorTs) / this.requiredDuration;
    },
    persentOfExpired() {
      const persent = this.requiredDuration / (this.expired + this.requiredDuration)
      return persent > 1 || persent < 0 ? 0 : persent
    },
    iconColor() {
      return this.expired > 0 && this.inWork ? '#E14761' : '#81ABEE';
    },
    inWork() {
      return this.$route.meta.type === STATES.IN_WORK;
    },
    error() {
      return this.$route.meta.type === STATES.ERROR;
    },
    isCompleted() {
      return this.$route.meta.type === STATES.SOLVED;
    }
  },
  methods: {
    getExecutorName(executor) {
      return `${executor?.lastName} ${executor?.firstName.split('')[0]}. ${executor?.secondName.split('')[0]}.`;
    },
    getDepartament(executor) {
      const department = this.departments.find(department => department.id === executor.departmentId);
      return department?.fullName || 'Департамент отсутствует';
    },
    getHeightList() {
      if (this.pipelineData?.length) {
        this.$nextTick(() => {
          this.heightList = [];
          for (const stage of this.pipelineData) {
            const [content] = this.$refs[`pipeline-content-${stage.id}`];
            this.heightList.push({ id: stage.id, height: content?.clientHeight });
          }
        });
      }
    },
    openLastStage() {
      const lastItem = this.pipelineData[this.pipelineData.length - 1]?.id;
      this.openStages.push(lastItem);
      this.activeArrows.push(lastItem);
    },
    getSvgHeight(id) {
      const height = this.heightList.find(height => height.id === id);
      return height.height;
    },
    getLineFill(id, index) {
      return this.isOpen(id) && index === this.pipelineData.length - 1 && this.inWork
        ? this.getProgressLineFill(id)
        : this.getSvgHeight(id) - 2;
    },
    getProgressLineFill (id) {
      const lineSize = this.getSvgHeight(id) * this.persentOfExpired
      const res = this.expired > 0
        ? lineSize > 24
          ? lineSize
          : 24 + lineSize
        : this.getSvgHeight(id) * this.percentOfProgress + 8
      return res
    },
    setStageHeight(id) {
      const [stage] = this.$refs[`pipeline-stage-${id}`];
      const height = this.getSvgHeight(id);
      if (!stage) return
      stage.style.height = `${height + 6}px`;
    },
    heightPiplineRecalculation() {
      this.pipelineData.forEach((stage) => {
        this.getHeightList();
        this.$nextTick(() => this.setStageHeight(stage.id));
      })
    },
    setTitleHeight(id) {
      const [stage] = this.$refs[`pipeline-stage-${id}`];
      const [title] = this.$refs[`pipeline-title-${id}`];
      stage.style.height = `${title.clientHeight + 18}px`;
    },
    toggleStage(id) {
      const stageIndex = this.openStages.findIndex(item => item === id);
      const isClosed = stageIndex === -1;
      if (isClosed) {
        this.openStages.push(id);
        this.activeArrows.push(id);
        this.getHeightList();
        this.$nextTick(() => this.setStageHeight(id));
      } else {
        this.setTitleHeight(id);
        this.activeArrows.splice(stageIndex, 1);
        setTimeout(() => {
          this.openStages.splice(stageIndex, 1);
          this.getHeightList();
        }, 200);
      }
    },
    isOpen(id) {
      return this.openStages.includes(id);
    },
    isActiveArrow(id) {
      return this.activeArrows.includes(id);
    }
  }
};
</script>

<style lang="scss" scoped>
.pipeline {
  &__stage {
    display: flex;
    overflow: hidden;
    transition: 0.2s ease-in-out;
  }

  &__finish {
    display: flex;
  }

  &__content {
    width: 90%;
    height: fit-content;
    padding-bottom: 8px;

    &-title {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &--finish {
      position: relative;
      top: 48px;
    }

    &-arrow {
      transition: 0.2s ease;

      &--active {
        transform: rotate(180deg);
      }
    }
  }

  &__line {
    &-svg {
      position: relative;
      top: 6px
    }
  }
}
</style>

<template>
  <div class="required-duration">
    <FormGroup
      :error="error"
      :errorMessage="errorMessage"
    >
      <r-select 
        class="mb-6"
        label="Срок реакции"
        titleValue="value"
        idValue="id"
        v-model="type"
        :items="types"
        :disabled="readonly"
      />
      <r-popover
        v-if="type === 'select'"
        class="required-duration__popover"
        contentWidthActivator
        :disabled="readonly"
        position="left-bottom"
        @close="isSelect = false"
      >
        <template #activator="{ on }">
          <div
            v-on="on"
            class="required-duration__input"
          >
            <r-input
              label="Время до исполнения"
              :value="formatDate"
              @click.prevent.stop="changeViewList"
              :class="[
                { disabled: readonly },
                { error: error }
              ]"
              :after-icon="isSelect ? 'arrow-up' : 'arrow-down'"
              :isClear="false"
              readonly
            />
          </div>
        </template>
        <template #content>
          <reaction-time
            class="required-duration__time"
            :time="time"
            @select="submitReactionTime"
            @change="changeReactionTime"
            button
          />
          <div class="required-duration__items">
            <div v-if="itemsList && itemsList.length">
              <r-list-item
                @click.native="!item.disabled && selectItem(item)"
                v-for="item in itemsList"
                :class="{
                  active: item.forUuId === modelId,
                  disabled: item.disabled
                }"
                :key="item.forUuId"
                :title="item[textValue]"
              />
            </div>
            <div
              v-else
              class="required-duration__empty"
            >
              Список пустой
            </div>
          </div>
        </template>
      </r-popover>
      <FormDateTimePicker
        v-if="type === 'calendar'"
        :disabled="readonly"
        :startDate="startDate"
        :endDate="endDate"
        v-model="model"
        :error="error"
      />
    </FormGroup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ReactionTime from '@/components/ReactionTime'
import moment from 'moment'
import FormGroup from "@/components/FormGroup.vue";
import FormDateTimePicker from "@/components/FormDateTimePicker.vue";

export default {
  name: 'RequiredDurationComponent',
  components: {
    ReactionTime,
    FormGroup,
    FormDateTimePicker
  },
  props: {
    value: {
      validator: prop => ['string', 'object', 'number'].includes(typeof prop) || prop === null,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    },
    textValue: {
      type: String,
      default: 'value'
    },
    idValue: {
      type: String,
      default: 'id'
    },
    endTime: {
      type: [String, Number],
      default: null
    },
    error: {
      type: Boolean,
      default: false, 
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      time: {
        days: null,
        hours: null,
        minutes: null
      },
      item: null,
      formatDate: null,
      itemsList: [],
      currentTime: null,
      isSelect: false,
      type: 'calendar',
      types: [
        {
          id: 'calendar',
          value: 'Выбрать дату и время'
        },
        {
          id: 'select',
          value: 'Задать срок'
        }
      ]
    }
  },
  computed: {
    ...mapState('directory', ['validTime']),
    model: {
      get () {
        if (this.value) {
          return this.value
        }
        return null
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    startDate () {
      return this.currentTime.toString()
    },
    endDate () {
      return this.endTime || new Date(this.currentTime.setDate(this.currentTime.getDate() + 30)).toString()
    },
    modelId () {
      return this.item
        ? this.item.forUuId
        : null
    },
  },
  watch: {
    value (val) {
      if (!val) return
      this.formatDate = moment(+val).format('YYYY-MM-DD HH:mm')
    },
    isDatePicker: {
      handler () {
        this.currentTime = new Date()
      },
      immediate: true
    },
    isSelect () {
      this.itemsList = [...this.itemsList.map((time) => {
        return {
          ...time,
          id: new Date().getTime() + time.duration
        }
      })]
    },
    validTime: {
      handler () {
        this.changeItemsList()
      },
      immediate: true
    }
  },
  methods: {
    changeItemsList () {
      this.itemsList = this.validTime.map((time) => {
        return {
          forUuId: this.$nanoid(),
          id: new Date().getTime() + time.value,
          duration: time.value,
          value: this.$options.filters.formatDuration(time.value)
        }
      })
    },
    submitReactionTime (date) {
      this.startItem = date
      this.selectItem(date)
    },
    changeReactionTime (time) {
      this.time = time
    },
    changeViewList () {
      this.isSelect = !this.readonly && !this.isSelect
    },
    selectItem (item) {
      this.item = { ...item }
      const val = item[this.idValue]
      this.$emit('change', item)
      this.$emit('input', val)
      this.isSelect = false
    }
  }
}
</script>

<style lang="scss" scoped>
.required-duration {

  &__input {
    width: 100%;
    cursor: pointer !important;
    user-select: auto !important;
    pointer-events: auto !important;
    pointer-events: auto !important;
    & > .readonly {
      opacity: 1 !important;
    }

    &:hover {
      box-shadow: none;
      background-color: var(--rir-djangoHover);
      border-radius: 8px;
    }
    & > label {
      opacity: 1 !important;
    }
  }

  &__items {
    max-height: 240px;
    overflow: scroll;
    color: rgba(#CDD3DF, 0.48) !important;

    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }
    &::-webkit-scrollbar-corner {
      background: rgba(0,0,0,0);
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      box-shadow: inset 0 0 0 10px;
      border-radius: 9999px;
      background: none;
    }
  }

  .error {
    .required-duration__input {
      background-color: var(--rir-schindler) !important;
    }
  }

  &__select {
    &-icon {
      & i {
        transition: transform 200ms ease;
        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__calendar {
    &-wrapper {
      display: inline-flex;
      min-width: 288px;
      border-radius: 16px;
      padding: 24px;
      height: 244px;
      overflow: hidden;
      background-color: var(--rir-amelie);
    }
  }

  &__time {
    padding: 16px;
  }

  &__empty {
    text-align: center;
  }
}
</style>

export default {
  async getNotifications({ commit, state, rootGetters }, payload) {
    const { pageIndex } = state.filters;
    commit('setProperty', { name: 'isLoading', value: true });
    const notifications = await rootGetters.GET('notification', {
      ...state.filters,
      ...payload
    });
    !pageIndex
      ? commit('setProperty', { name: 'notifications', value: notifications })
      : commit('concatNotifications', notifications);
    commit('setProperty', { name: 'isLoading', value: false });
  },
  async addNotification({ commit, rootGetters }, payload) {
    return await rootGetters.POST('notification', payload);
  },
  async readNotification({ commit, rootGetters }, payload) {
    return await rootGetters.POST('notification/read', payload);
  },
  async readAllNotification({ commit, rootGetters }) {
    return await rootGetters.POST('notification/read-all');
  },
  async getNotificationCount({ commit, rootGetters }) {
    return rootGetters.GET('notification/count').then(data => {
      commit('setProperty', { name: 'notificationCount', value: data });
    });
  },
};

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const InitState = () => ({
  isLoading: false,
  filters: {
    searching: null,
    pageIndex: 0,
    size: 20
  },
  executionType: '',
  incidents: [],
  messages: [],
  overdueList: [],
  appealActions: [],
  selectedIncidents: [],
  isSelectionMode: false,
  appealTypes: [],
  incidentCount: 0,
  incident: {}
});

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
};

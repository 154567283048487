<template>
  <div class="note">
    <FormTextarea
      class="mb-6"
      :rows="5"
      label="Примечание"
      v-model="model.value"
      :error="$v.model.value.$error"
      :errorMessage="mesErr($v.model.value)"
    />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required } from '@/plugins/vuelidate/customValidate'
import FormTextarea from '@/components/FormTextarea'

export default {
  name: 'NoteForm',
  mixins: [validationMixin, validateMixin],
  components: { FormTextarea },
  data () {
    return {
      model: {
        value: ''
      }
    }
  },
  validations: {
    model: {
      value: {
        required: required('Обязательное поле')
      }
    }
  }
}
</script>

<template>
  <div
    class="d-flex flex-direction-column justify-content-center align-items-center"
    style="position: relative; height: 100%; width: 100%;"
  >
    <div class="d-flex flex-direction-column pa-8">
      <r-icon
        fill="rocky"
        class="mb-6"
        icon="help"
        :size="56"
      />
      <span
        class="mb-8 titanic--text"
        style="font-weight: bold; font-size: 32px;"
      >
        Объединить обращения?
      </span>
      <div class="mb-8">
        <p>Выбранные обращения будут добавлены к обращению №{{ incident.number }}</p>
      </div>
      <div class="d-flex justify-content-between">
        <r-button
          style="flex: 1"
          @click="$root.$modal.closeModal()"
          color="secondary"
          title="Не объединять"
        >
        </r-button>
        <r-button
          style="flex: 1"
          class="ml-8"
          @click="consolidate"
          :isLoading="loading"
          :disabled="loading"
          title="Да, объединить"
        >
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import InfoModal from '@/views/incident/modal/InfoModal';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'ConsolidateSubmitModal',
  props: {
    incident: {
      type: Object
    },
    selectedIncidents: {
      type: Array
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapMutations('incident', ['setFilterProperty', 'setProperty', 'clearFilterProperties']),
    ...mapMutations('apps', ['setAnchor']),
    ...mapActions('incident', ['consolidateIncidents', 'getIncidents']),
    ...mapActions('directory', ['getCountBySystem']),
    async consolidate() {
      this.loading = true;
      await this.consolidateIncidents({
        id: this.incident.id,
        data: this.selectedIncidents.map(incident => incident.data.id)
      })
        .then(async () => {
          if (this.$route.params.id) {
            this.setAnchor(0);
            const path = this.$route.path?.split('/');
            this.$router.push(`/${path[1]}`);
          } else {
            this.setProperty({ name: 'incidents', value: [] });
            this.setProperty({ name: 'appealActions', value: [] });
            this.setFilterProperty({ attr: 'pageIndex', value: 0 });
            await this.getIncidents({
              category: [this.$route.meta.type],
              sortBy: this.$route.meta.sortBy
            });
            await this.getCountBySystem();
          }
          this.clearFilterProperties();
          this.$rir.modal.close(null, true)
          this.$rir.notification.send({
            title: 'Обращения объединены',
            seconds: 5
          });
        })
        .catch(() => this.errorModal());
      this.loading = false;
    },
    errorModal() {
      this.$root.$modal.openModal(InfoModal, {
        icon: 'close',
        fill: 'fargo',
        title: 'Ошибка объединения обращения',
        subtitle: `Произошла внутренняя ошибка сервера во время отправки.
        Пожалуйста, повторите отправку. Если проблема повторится, то обратитесь в службу поддержки.`
      });
    }
  }
};
</script>

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const InitState = () => ({
  notifications: [],
  notificationCount: 0,
  isLoading: false,
  filters: {
    pageIndex: 0,
    size: 20
  }
});

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
};

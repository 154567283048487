export default {
  setProperty: (state, payload) => {
    const { name, value } = payload;
    state[name] = value;
  },
  removeEntityArray: (state, payload) => {
    const { name, value } = payload;
    const index = state[name].findIndex(el => el.id === value);
    state[name].splice(index, 1);
  }
};

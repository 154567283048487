import IncidentListToolbar from '@/views/incident/toolbars/IncidentListToolbar';
import StatisticsView from '@/views/statistics/StatisticsView';
import DefaultLayout from '@/layout/DefaultLayout';

export const statisticsRoutes = [
  {
    path: '/statistics',
    name: 'statistics',
    components: {
      default: StatisticsView,
      toolbar: IncidentListToolbar
    },
    meta: {
      layout: DefaultLayout,
      title: 'Статистика'
    }
  }
];

<template>
  <div class="bulb-tags">
    <MultiselectTags
      :value="model"
      @input="changeSelectedTags"
    >
      <template v-slot:activator="{ on }">
        <div
          @click="on.click"
          class="d-flex align-items-center bulb-tags__activator"
        >
          <div class="bulb-tags__knob">
            <r-icon
              icon="add"
              fill="amelie"
            />
          </div>
          <div
            v-if="!selectedTags.length"
            class="briscola ekas ml-2"
          >
            Добавить метку
          </div>
        </div>
      </template>
    </MultiselectTags>
    <div
      v-if="selectedTags.length"
      class="bulb-tags__list ml-2"
      ref="view"
    >
      <div
        v-for="tag in selectedTags"
        :key="tag.name"
        class="bulb-tags__tag amelie--text"
        :style="`background-color: ${tag.color}`"
      >
        {{ tag.name }}
      </div>
      <r-button-action
        :title="isFullView ? 'Свернуть' : `Ещё ${countHidden}`"
        @click="isFullView = !isFullView, checkViewBlock()"
        ref="change"
        color="harakiri"
        size="briscola"
        :not-shorter="true"
        style="visibility: hidden;"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MultiselectTags from '@/components/MultiselectTags'

export default {
  name: 'BulbTags',
  components: { MultiselectTags },
  props: {
    value: {
      type: Array,
      default: () => ([]),
      require: true
    }
  },
  data () {
    return {
      selectedTags: [],
      model: [],
      isFullView: false,
      countHidden: 0
    }
  },
  watch: {
    value: {
      handler (val) {
        this.model = val
        this.setSelectedTags(val)
        this.checkViewBlock()
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('directory', ['tags']),
  },
  mounted () {
    this.checkViewBlock();
  },
  methods: {
    ...mapActions('incident', ['addTags']),
    checkViewBlock() {
      this.$nextTick(() => {
        if (!this.$refs.view) return
        const { children } = this.$refs.view;
        let sumWidth = 0;
        let isView = true;
        this.$refs.change.$el.style.visibility = 'hidden';
        this.countHidden = 0;
        for (const item of children) {
          item.style.display = 'inline-flex';
          sumWidth += item?.clientWidth + 8;
          if (sumWidth >= this.$refs.view?.clientWidth) {
            isView = false;
            this.$refs.change.$el.style.visibility = 'visible';
          }
          if (!isView && this.$refs.change.$el !== item && !this.isFullView) {
            item.style.display = 'none';
            this.countHidden++;
          }
        }
      });
    },
    changeSelectedTags (tags) {
      this.model = tags
      this.addTags({
        id: this.$route.params.id,
        data: this.model || []
      })
      this.setSelectedTags(tags)
      this.checkViewBlock();
    },
    setSelectedTags (tags) {
      this.selectedTags = this.tags.filter(val => {
        if (!!tags.find(tag => tag === val.name)) {
          return val
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bulb-tags {
  display: flex;

  &__knob {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: var(--rir-rocky);
    border-radius: 12px;
  }

  &__activator {
    cursor: pointer;
    transition: opacity .2s;

    &:hover {
      opacity: 0.72;
    }
  }

  &__list {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__tag {
    display: inline-block;
    width: fit-content;
    border-radius: 16px;
    padding: 4px 12px;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 16px !important;

    &-category {
      font-size: 10px !important;
      font-weight: 400 !important;
      line-height: 12px !important;
    }
  }
}
</style>

<template>
  <div :id="mapId" class="map">
    <yandex-map
      :controls="[]"
      :options="{
        suppressMapOpenBlock: true,
        yandexMapDisablePoiInteractivity: true
      }"
      @map-was-initialized="initMapHandler"
      :coords="coords"
      :zoom="zoom"
      :style="{ width: '100%', height: '100%' }"
    />
    <action-button
      :style="`position: absolute; top: ${fullscreenPosition.top}px; right: ${fullscreenPosition.right}px; z-index: 5;`"
      :icon="isFullscreen ? 'exit-fullscreen' : 'fullscreen'"
      @click="toggleFullscreen"
    />
    <div :style="`position: absolute; top: ${zoomPosition.top}px; right: ${zoomPosition.right}px; z-index: 5;`">
      <action-button
        class="mb-1"
        icon="add"
        @click="zoomIn"
      />
      <action-button
        icon="remove"
        @click="zoomOut"
      />
    </div>
  </div>
</template>

<script>
import getEnv from '@/plugins/env'
import ActionButton from '@/components/ActionButton'

const DEFAULT_ZOOM = 15

export default {
  name: 'MapComponent',
  components: { ActionButton },
  props: {
    zoom: {
      type: Number,
      default: DEFAULT_ZOOM
    },
    location: {
      type: Array,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: false
    },
    zoomPosition: {
      type: Object,
      default: () => ({
        right: 10,
        top: 72
      })
    },
    fullscreenPosition: {
      type: Object,
      default: () => ({
        right: 10,
        top: 16
      })
    }
  },
  data () {
    return {
      map: null,
      sourcePlacemark: null,
      isFullscreen: false,
      mapId: null,
    }
  },
  computed: {
    coords () {
      return this.location?.length ? this.location : [getEnv('VUE_APP_DEFAULT_LATITUDE'), getEnv('VUE_APP_DEFAULT_LONGITUDE')]
    },
  },
  watch: {
    coords: {
      handler (val, oldVal) {
        if (this.location?.length) {
          this.updateGeoData(val, oldVal, this.setMarker)
        } else {
          this.map.geoObjects.remove(this.sourcePlacemark)
          this.centerMap([val, val])
        }
      },
      deep: true
    }
  },
  created () {
    this.mapId = this.$nanoid()
  },
  methods: {
    // инициализация карты
    initMapHandler (init) {
      this.map = init
      this.centerMap([this.coords, this.coords])
      this.map.events.add('click', (e) => {
        const coords = e.get('coords')
        this.onClick(coords)
      })
      if (this.location?.length) {
        this.setMarker(this.coords)
      }
    },
    // Полноэкранный режим
    toggleFullscreen () {
      if (document.fullscreenElement) {
        document.exitFullscreen()
        this.isFullscreen = false
      } else {
        const element = document.getElementById(this.mapId)
        element.requestFullscreen()
        this.isFullscreen = true
      }
    },
    // Зум
    zoomIn () {
      this.map.setZoom(this.map.getZoom() + 1, { duration: 200 })
    },
    zoomOut () {
      this.map.setZoom(this.map.getZoom() - 1, { duration: 200 })
    },
    // Установка маркера
    setMarker (coords) {
      this.map.geoObjects.remove(this.sourcePlacemark)
      if (!coords?.length) return
      // eslint-disable-next-line no-undef
      this.sourcePlacemark = new ymaps.Placemark(coords, null, {
        draggable: this.editable,
        id: 'source',
        iconLayout: 'default#image',
        iconImageHref: '/map-icons/default-map-pin.svg',
        iconImageSize: [24, 36],
        iconImageOffset: [-12, -36]
      })
      this.sourcePlacemark.events.add('dragend', (e) => {
        const coords = e.get('target').geometry.getCoordinates()
        this.onClick(coords)
      })
      this.map.geoObjects.add(this.sourcePlacemark)
      this.centerMap([coords, coords])
    },
    // Позиционирование области видимости карты
    centerMap (bounds) {
      const area = bounds || this.map.geoObjects.getBounds()
      if (!area) return
      if (this.map.geoObjects.getLength() > 1) {
        this.map.setBounds(area, {
          checkZoomRange: true,
          zoomMargin: [10, 10]
        }).then(() => {
          if (this.map && this.map.getZoom() > DEFAULT_ZOOM) this.map.setZoom(DEFAULT_ZOOM)
        })
      } else {
        this.map.setZoom(this.map.getZoom())
      }
    },
    // Обновление гео-данных
    updateGeoData (val, oldVal, foo) {
      if (!this.map || val === oldVal) return
      foo(val)
    },
    onClick (coordinates) {
      if (this.editable) this.$emit('setCoords', coordinates)
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>

<template>
  <section class="user">
    <NotificationList class="mr-8"/>
    <a @click="logoutSystem">
      <r-icon
        icon="exit"
        fill="titanic"
        size="20"
      />
    </a>
    <div class="user__avatar">
      <r-icon
        fill="amelie"
        icon="avatar"
      />
    </div>
    <span>{{ infoName }}</span>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationList from '@/components/NotificationList'

export default {
  name: 'UserInfo',
  components: { NotificationList },
  computed: {
    ...mapGetters('session', ['infoName'])
  },
  methods: {
    logoutSystem() {
      this.$keycloak.logoutFn();
    }
  }
};
</script>

<style lang="scss" scoped>
.user{
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--rir-alien);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
  }
  & > span {
    font-weight: 500;
    color: var(--rir-titanic);
  }
  & > a {
    display: flex;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;
  }
}
</style>

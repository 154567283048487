<template>
  <div class="reaction-time">
    <r-input
      class="reaction-time__input"
      v-mask="{
        mask: time.days < 31 ? '##' : '',
      }"
      v-model="time.days"
      @input="changeTime"
      label="Дн."
    />
    <r-input
      class="reaction-time__input"
      v-mask="{
        mask: time.days > 29
          ? ''
          : ('' + time.hours)?.length === 1 && time.hours > 2
            ? 'h'
            : time.hours < 20 ? 'Hh' : 'Hf',
        tokens: {
          H: { pattern: /[0-2]/ },
          h: { pattern: /[0-9]/ },
          f: { pattern: /[0-3]/ },
        }
      }"
      v-model="time.hours"
      @input="changeTime"
      label="Ч."
    />
    <r-input
      class="reaction-time__input"
      v-mask="{
        mask: time.days > 29
          ? ''
          : time.minutes < 60 ? '##' : '',
      }"
      v-model="time.minutes"
      @input="changeTime"
      label="Мин."
    />
    <r-button-simple
      v-if="button"
      :disabled="!isActiveButton"
      class="reaction-time__btn"
      size="larishae"
      @click="selectStartItem"
      icon="selected"
    />
  </div>
</template>

<script>
export default {
  name: 'ReactionTime',
  props: {
    time: {
      type: Object,
      default: () => ({
        days: null,
        hours: null,
        minutes: null
      })
    },
    button: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isActiveButton: false
    }
  },
  computed: {
    isActiveButton() {
      return this.time.days || this.time.hours || this.time.minutes;
    },
    computedTime () {
      return Object.assign({}, this.time);
    }
  },
  watch: {
    computedTime: {
      handler (val, oldVal) {
        if (this.computedTime.days || this.computedTime.hours || this.computedTime.minutes) {
          const time = JSON.stringify(val)
          const oldTime = JSON.stringify(oldVal)
          this.isActiveButton = time !== oldTime
        }
      },
      deep: true
    }
  },
  methods: {
    selectStartItem() {
      if (!this.time) return;
      const days = this.time.days ? `${+this.time.days} дн. ` : '';
      const hours = this.time.hours ? `${+this.time.hours} ч. ` : '';
      const minutes = this.time.minutes ? `${+this.time.minutes} мин.` : '';
      const duration = this.time.days * 86400000 + this.time.hours * 3600000 + this.time.minutes * 60000;
      const date = {
        forUuId: this.$nanoid(),
        id: new Date().getTime() + duration,
        duration,
        value: days + hours + minutes
      };
      this.$emit('select', date);
      this.isActiveButton = false
    },
    changeTime() {
      this.$emit('change', this.time);
    }
  }
};
</script>

<style lang="scss" scoped>
.reaction-time {
  display: flex;

  &__input {
    flex: 1;
    margin-right: 16px;
    width: 0;
  }

  &__btn {
    width: 40px;
  }
}
</style>

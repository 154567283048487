import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const InitState = () => ({
  actionbar: false,
  isSidebarOpen: false,
  scroll: 0,
  anchor: 0,
  route: null,
  lastRoute: null
});

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
};

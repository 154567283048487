import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const InitState = () => ({
  coords: [],
  address: null,
  addressPrefix: ''
});

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
};

import moment from 'moment';
import { numDeclination } from './numDeclination';

moment.locale('ru');
export function formatDuration(period) {
  const parts = [];
  const duration = moment.duration(period);

  // return nothing when the duration is falsy or not correctly parsed (P0D)
  if (!duration || duration.toISOString() === 'P0D') return;

  if (duration.years() >= 1) {
    const years = Math.floor(duration.years());
    parts.push(`${years} ${numDeclination(years, ['год', 'года', 'лет'])}`);
  }

  if (duration.months() >= 1) {
    const months = Math.floor(duration.months());
    parts.push(`${months} ${numDeclination(months, ['месяц', 'месяца', 'месяцев'])}`);
  }

  if (duration.days() >= 1) {
    const days = Math.floor(duration.days());
    parts.push(`${days} ${numDeclination(days, ['день', 'дня', 'дней'])}`);
  }

  if (duration.hours() >= 1) {
    const hours = Math.floor(duration.hours());
    parts.push(`${hours} ${numDeclination(hours, ['час', 'часа', 'часов'])}`);
  }

  if (duration.minutes() >= 1) {
    const minutes = Math.floor(duration.minutes());
    parts.push(`${minutes} ${numDeclination(minutes, ['минута', 'минуты', 'минут'])}`);
  }

  if (duration.seconds() >= 1) {
    const seconds = Math.floor(duration.seconds());
    parts.push(`${seconds} ${numDeclination(seconds, ['секунда', 'секунды', 'секунд'])}`);
  }

  return parts.join(', ');
}

import Vue from 'vue';
import Vuex from 'vuex';
import getEnv from '@/plugins/env';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    GET_BLOB(state, getters) {
      return (url, params = {}, headers = {}) => Vue.axios.get(`${getEnv('VUE_APP_API_SERVER')}${url}`, { params, headers, responseType: 'arraybuffer' })
        .then(response => response || {});
    },
    GET(state, getters) {
      return (url, params = {}, headers = {}) => Vue.axios.get(`${getEnv('VUE_APP_API_SERVER')}${url}`, { params, headers })
        .then(({ data }) => data || {});
    },
    POST(state, getters) {
      return (url, params, query) => Vue.axios.post(`${getEnv('VUE_APP_API_SERVER')}${url}`, params, { params: query })
        .then(({ data }) => data || {});
    },
    PUT(state, getters) {
      return (url, params, query) => Vue.axios.put(`${getEnv('VUE_APP_API_SERVER')}${url}`, params, { params: query })
        .then(({ data }) => data || {});
    },
    PATCH(state, getters) {
      return (url, params) => Vue.axios.patch(`${getEnv('VUE_APP_API_SERVER')}${url}`, params)
        .then(({ data }) => data || {});
    },
    DELETE(state, getters) {
      return (url, params = {}, headers = {}) => Vue.axios.delete(`${getEnv('VUE_APP_API_SERVER')}${url}`, { params, headers })
        .then(response => response || {});
    }
  },
  modules
});

import { STATES, StatesTitles } from '@/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const InitState = () => ({
  messageSources: [],
  executors: [],
  divisions: [],
  problems: [],
  departments: [],
  appealSystemCounter: [],
  validTime: [],
  tags: [],
  accounts: [],
  systemCode: { name: 'Все источники', code: 'total' },
  statuses: [
    {
      code: STATES.NEW,
      value: StatesTitles.NEW
    },
    {
      code: STATES.IN_WORK,
      value: StatesTitles.IN_WORK
    },
    {
      code: STATES.WAITING_DECISION,
      value: StatesTitles.WAITING_DECISION
    },
    {
      code: STATES.ADDITIONAL_QUESTION,
      value: StatesTitles.ADDITIONAL_QUESTION
    },
    {
      code: STATES.PAUSED,
      value: StatesTitles.PAUSED
    },
    {
      code: STATES.ERROR,
      value: StatesTitles.ERROR
    },
    {
      code: STATES.SOLVED,
      value: StatesTitles.SOLVED
    },
    {
      code: STATES.REMOVED,
      value: StatesTitles.REMOVED
    }
  ],
  disciplines: [
    {
      code: 'OVERDUE',
      value: 'Просрочные обращения'
    },
    {
      code: 'ON_TIME',
      value: 'Выполненые обращения в срок'
    }
  ],
  actionsExecutor: [
    {
      value: 'Не моя проблема',
      code: 'NOT_MY_PROBLEM'
    },
    {
      value: 'Не могу решить проблему',
      code: 'CANNOT_SOLVE_PROBLEM'
    },
    {
      value: 'Проблема решена',
      code: 'EXECUTE_PROBLEM'
    }
  ]
});

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
};

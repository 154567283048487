<template>
  <div
    @click="$emit('click', $event)"
    class="action-button"
  >
    <r-icon
      :icon="icon"
      :size="size"
      :fill="fill"
    />
  </div>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    icon: {
      type: String,
      default: 'add'
    },
    fill: {
      type: String,
      default: 'rocky'
    },
    size: {
      type: [String, Number],
      default: 20
    }
  }
};
</script>

<style lang="scss" scoped>
.action-button {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--rir-amelie);
  box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.03);
  }
}
</style>

import IncidentListToolbar from '@/views/incident/toolbars/IncidentListToolbar';
import IncidentNew from '@/views/incident/IncidentNew';
import DefaultLayout from '@/layout/DefaultLayout';

export const incidentCreateRoutes = [
  {
    path: '/create',
    name: 'create',
    components: {
      default: IncidentNew,
      toolbar: IncidentListToolbar
    },
    meta: {
      layout: DefaultLayout,
      title: 'Создание нового обращения'
    }
  }
];

<template>
  <section
    class="incident-item mb-3"
    :class="[
      {'incident-item--selected': isSelected },
      {'incident-item--selective': isSelectionMode },
      {'incident-item--disabled': isDisabled }
    ]"
    @click="selectIncident"
  >
    <information-block-incident
      :incident="incident"
      class="incident-item__information"
      @click.native="openViewIncident('')"
    />
    <div
      v-if="unavailable && inProgress"
      class="incident-item__unavailable"
    >
      <r-icon
        icon="warning"
        fill="fargo"
        class="mb-1"
      />
      <p class="mozzarella">
        Работа с обращением недоступна
      </p>
    </div>
    <div
      v-else
      class="incident-item__additional-info"
    >
      <ActionsTimerIncident
        v-if="isWorking"
        @click.native="openViewIncident('')"
      />
      <section v-if="isShownButtons">
        <div
          v-if="inProgress"
          class="incident-item__actions"
          :class="{'disabled': isSelectionMode}"
        >
          <div
            v-if="isActiveSpinner"
            class="incident-item__spinner"
          >
            <r-spinner />
          </div>
          <div class="d-flex align-items-center">
            <div
              v-if="isShownCroppedActions"
              class="d-flex align-items-center"
            >
              <r-tooltip
                v-for="action in croppedActions"
                :key="action"
                class="incident-item__action mr-7"
                :style="`top: 8px`"
                activator-hover
                color="amelie"
                @click.native.stop="openViewIncident(action)"
                :title="getTitle(action)"
              >
                <template #activator>
                  <r-icon
                    :icon="getIcon(action)"
                    :fill="getIconFill(action)"
                    size="20"
                  />
                </template>
              </r-tooltip>
            </div>
            <r-popover
              v-if="isShownContextMenu"
              class="incident-item__popover"
              click-close
              position="right-bottom"
            >
              <template #activator="{ on }">
                <r-button-simple
                  icon="menu-context"
                  type="transparent"
                  class="incident-item__button"
                  v-on="on"
                />
              </template>
              <template #content>
                <actions-list
                  v-if="isShownActions"
                  show
                  @perform="openViewIncident"
                  :actions="isShownCroppedActions ? publicationActions : allActions"
                />
              </template>
            </r-popover>
          </div>
        </div>
      </section>
      <slot v-else />
      <component
        v-if="componentActions && isShownButtons"
        :is="componentActions"
        @click.native="openViewIncident('')"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import InformationBlockIncident from '@/views/incident/components/InformationBlockIncident';
import ActionsTimerIncident from '@/views/incident/components/actions/ActionsTimerIncident';
import ActionsList from '@/views/incident/components/ActionsList';
import DeleteModal from '@/views/incident/modal/DeleteModal';
import ConsolidateIncidentsModal from '@/views/incident/modal/ConsolidateIncidentsModal';
import SeparateIncidentsModal from '@/views/incident/modal/SeparateIncidentsModal';
import { ActionIcons, ActionTitles, ACTIONS, STATES } from '@/constants';
import { DELEGATION_ACTIONS } from '@/constants'

export default {
  name: 'IncidentItem',
  components: {
    InformationBlockIncident,
    ActionsList,
    ActionsTimerIncident
  },
  provide() {
    return {
      incident: this.incident
    };
  },
  props: {
    incident: {
      type: Object
    },
    type: {
      type: String
    },
    isShownButtons: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('apps', ['scroll']),
    ...mapState('incident', ['appealActions', 'isActionsLoading', 'isSelectionMode', 'selectedIncidents', 'executionType']),
    ...mapState('session', ['areaCode']),
    croppedActions () {
      return this.publicationActions.splice(0, this.publicationActions?.length > 3 ? 2 : 3)
    },
    publicationActions() {
      const publications = this.incident?.actions?.publication;
      const actions = publications ? [...publications] : [];
      if (this.isMedialogy && this.inProgress) actions.push(ACTIONS.ADD_NOTE);
      if ([STATES.NEW,
        STATES.IN_WORK,
        STATES.WAITING_DECISION,
        STATES.ADDITIONAL_QUESTION,
        STATES.PAUSED,
        STATES.ERROR
      ].includes(this.$route.meta.type) && !(this.incident.delegationAction || this.isConsolidation)) {
        actions.push(ACTIONS.SELECT);
      }
      return this.sortActions(actions);
    },
    isDelegation() {
      return !!this.incident.delegationAction === DELEGATION_ACTIONS.DECLINE_DELEGATED_APPEAL && this.incident.areaCode === this.areaCode;
    },
    allActions() {
      return [...this.croppedActions, ...this.publicationActions];
    },
    unavailable() {
      return this.croppedActions.includes(ACTIONS.MOVE_TO_RESOLVED);
    },
    isActiveSpinner() {
      return this.incident?.actions?.isLoading;
    },
    isSelected() {
      return this.selectedIncidents.find(incident => incident.data.id === this.incident.id);
    },
    componentActions() {
      const components = {
        [STATES.REMOVED]: 'ActionsBlockRecover',
        [STATES.ERROR]: 'ActionsBlockError'
      };
      const component = components[this.$route.meta.type];
      return component
        ? () => component && import(`@/views/incident/components/actions/${component}.vue`)
        : false;
    },
    isMedialogy() {
      return ['MEDIALOGIA-IM'].includes(this.incident.systemCode);
    },
    inProgress() {
      return this.$route.meta.type !== STATES.REMOVED
        && this.$route.meta.type !== STATES.SOLVED
        && this.$route.meta.type !== STATES.ERROR;
    },
    isShownCroppedActions() {
      return this.croppedActions?.length && !this.isWorking
    },
    isWorking () {
      return this.$route.meta.type !== STATES.NEW && this.executionType !== ACTIONS.COMPLETE_APPEAL
    },
    isShownActions() {
      return !this.$root.$modal.isOpen && !this.isSelectionMode;
    },
    isConsolidation() {
      return !!this.incident.consolidatedCount;
    },
    isSeparate () {
      return this.executionType === ACTIONS.SEPARATE && this.isSelectionMode
    },
    isDisabled() {
      if (this.disabled) {
        return true
      } else {
        return this.isSeparate
        ? false
        : (!this.inProgress || this.isShownDelegationAction || this.isConsolidation) && this.isSelectionMode;
      }
    },
    isShownContextMenu () {
      return this.isShownCroppedActions ? (this.croppedActions?.length < 3 && this.allActions?.length > 3) : this.allActions?.length
    }
  },
  methods: {
    ...mapMutations('incident', ['setExecutionType', 'setSelectedIncident', 'setProperty', 'setSelectionMode']),
    ...mapMutations('apps', ['setAnchor']),
    ...mapActions('incident', ['getIncident', 'markAsRead']),
    getIcon(action) {
      return ActionIcons[action];
    },
    getTitle(action) {
      return ActionTitles[action];
    },
    getIconFill(action) {
      return [ACTIONS.IGNORE_APPEAL, ACTIONS.DECLINE_DELEGATED_APPEAL].includes(action) ? 'fargo' : 'rocky';
    },
    sortActions(actions) {
      if (actions.includes(ACTIONS.EXECUTE_APPEAL)) {
        const two = ACTIONS.EXECUTE_APPEAL;
        actions.sort((x, y) => (x === two ? -1 : y === two ? 1 : 0));
      }
      if (actions.includes(ACTIONS.COMPLETE_APPEAL) || actions.includes(ACTIONS.REPLY_DELEGATED_APPEAL)) {
        const first = actions.includes(ACTIONS.COMPLETE_APPEAL) ? ACTIONS.COMPLETE_APPEAL : ACTIONS.REPLY_DELEGATED_APPEAL;
        actions.sort((x, y) => (x === first ? -1 : y === first ? 1 : 0));
      }
      return actions;
    },
    selectIncident() {
      if (!this.isSelectionMode) return;
      this.setSelectedIncident({
        data: this.incident,
        actions: this.allActions
      });
      this.$emit('select', this.incident);
    },
    openViewIncident(type) {
      if (this.isSelectionMode) return;
      this.setExecutionType(type);
      this.setAnchor(this.scroll);
      if (!this.incident.isRead) this.markAsRead(this.incident.id);
      switch (type) {
        case ACTIONS.IGNORE_APPEAL:
          this.$root.$modal.openModal(DeleteModal, { incidents: [this.incident] });
          break;
        case ACTIONS.SELECT:
          this.setSelectionMode(true);
          this.setSelectedIncident({
            data: this.incident,
            actions: this.allActions
          });
          break;
        case ACTIONS.CONSOLIDATE:
          this.$rir.modal.open(ConsolidateIncidentsModal, {
            incident: this.incident
          }, {
            isClose: false,
            isFixedActionsBlock: true
          });
          break;
        case ACTIONS.SEPARATE:
          this.$rir.modal.open(SeparateIncidentsModal, {
            mainIncident: this.incident
          },
          {
            isClose: false,
            isFixedActionsBlock: true,
          });
          break;
        default:
          this.$router.push(`/${this.$route.name}/${this.incident.id}`);
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.incident-item {
  display: grid;
  grid-template-columns: 5fr 2fr;
  width: 100%;
  align-items: center;
  min-height: 136px;
  max-height: 150px;
  background-color: var(--rir-amelie);
  border-radius: 16px;
  cursor: pointer;
  transition: box-shadow 0.3s, background-color 0.3s;
  padding: 20px;

  &:hover {
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    background-color: var(--rir-amelie);
  }

  &--selected {
    background-color: var(--rir-apocalypse);
  }

  &--selective {
    &:hover {
      box-shadow: none;
      outline: 1px solid var(--rir-casablanca);
      background-color: var(--rir-apocalypse);
    }
  }

  &--disabled {
    opacity: 0.32;
    pointer-events: none;
    cursor: auto;
  }

  &__additional-info {
    display: flex;
    position: relative;
    height: 100%;
    margin-left: auto;
    margin-right: 24px;
  }

  &__spinner {
    padding-right: 24px
  }

  &__actions {
    position: absolute;
    right: 0;
    top: 53%;
    transform: translate(0, -50%);
  }

  &__loading-ring {
    width: 500px;
    display: flex;
    justify-content: center;
  }

  &__unavailable {
    // padding-right: 40px;
    width: 138px;
    margin-left: auto;
  }

  &__action {
    opacity: 0.72;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &__button {
    background-color: rgba(0, 0, 0, 0) !important;
    padding: 0;
    padding-right: 20px;
    width: 24px !important;
    height: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
  }

  &__information{
    display: grid;
    grid-template-columns: minmax(171px, 26%) 74%;
    grid-column-gap: 32px;
    overflow: hidden;
    height: 100%;
    margin-right: 20px;
    // padding: 20px;
  }
}

.disabled {
  opacity: 0.32;
  cursor: auto;
  pointer-events: none;
}
</style>

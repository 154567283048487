<template>
  <div
    class="d-flex flex-direction-column justify-content-center align-items-center"
    style="position: relative; height: 100%; width: 100%;"
  >
    <div class="d-flex flex-direction-column pa-8">
      <r-icon
        v-if="icon"
        :icon="icon"
        :fill="fill"
        class="mb-6"
        size="56"
      />
      <h2 class="mb-8 camembert">
        {{ title }}
      </h2>
      <span
        v-if="subtitle"
        class="mb-8"
      >{{ subtitle }}</span>
      <r-button
        @click="closeModal"
        color="secondary"
        :title="button"
        style="width: 100%"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'InfoModal',
  props: {
    title: {
      type: String,
      requied: true
    },
    subtitle: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    fill: {
      type: String,
      default: 'matrix'
    },
    button: {
      type: String,
      default: 'Продолжить работу'
    },
    route: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapMutations('incident', ['setFilterProperty', 'setProperty']),
    ...mapMutations('apps', ['setAnchor']),
    closeModal() {
      if (this.$route.params.id) {
        this.setAnchor(0);
        if (this.route) {
          this.$router.push(this.route);
        } else {
          const path = this.$route.path?.split('/');
          this.$router.push(`/${path[1]}`);
        };
      }
      this.$rir.modal.close(null, true)
    }
  }
};
</script>

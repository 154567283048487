<template>
  <div
    class="d-flex flex-direction-column justify-content-center align-items-center"
    style="position: relative; height: 100%; width: 100%;"
  >
    <div class="d-flex flex-direction-column pa-8">
      <r-icon
        class="mb-6"
        icon="warning"
        fill="fargo"
        :size="56"
      />
      <span
        class="mb-8 titanic--text"
        style="font-weight: bold; font-size: 32px;"
      >
        Удалить ответ?
      </span>
      <div class="mb-8">
        <p>Ответ пользователя будет удалён без возможности восстановления</p>
      </div>
      <div class="d-flex justify-content-between">
        <r-button
          style="flex: 1"
          @click="$root.$modal.closeModal()"
          type="secondary"
          title="Не удалять"
          width="wide"
        />
        <r-button
          style="flex: 1"
          class="ml-8"
          @click="deleteMessage"
          :isLoading="sending"
          :disabled="sending"
          title="Удалить"
          width="wide"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'DeleteMessageModal',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      sending: false
    };
  },
  computed: {
    ...mapState('incident', ['messages'])
  },
  methods: {
    ...mapActions('incident', ['deleteRawIncident']),
    ...mapMutations('incident', ['setProperty']),
    async deleteMessage() {
      this.sending = true;
      await this.deleteRawIncident({ id: this.id })
        .then(() => {
          this.$rir.notification.send({
            title: 'Сообщение удалено',
            seconds: 5
          });
          const messages = this.messages.filter(message => message.id !== this.id);
          this.setProperty({ name: 'messages', value: messages });
        })
        .finally(() => {
          this.sending = false;
          this.$root.$modal.closeModal()
        });
    }
  }
};
</script>

<template>
  <FormGroup
    :error="error"
    :errorMessage="errorMessage"
  >
    <r-row gap-hidden>
      <r-col :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
        <r-date-picker
          :inline="inline"
          :period="false"
          :label="label"
          v-model="innerDate"
          :disabled="disabled"
          :clickClose="clickClose"
          :error="error"
          :errorMessage="errorMessage"
          :startDate="startDate"
          :endDate="endDate"
          @input="onChangeDate"
          label="Дата"
        />
      </r-col>
      <r-col :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
        <r-time-picker
          :disabled="disabled || !innerDate"
          :isSeconds="false"
          v-model="innerTime"
          :label="timeLabel"
          :startTime="startTime"
          :error="error"
          :errorMessage="null"
          @input="onChangeTime"
          label="Время"
        />
      </r-col>
    </r-row>
  </FormGroup>
</template>

<script>
  import FormGroup from "@/components/FormGroup.vue";
  import moment from 'moment'
  
  export default {
    name: "WRDateTimePicker",
    components: {
      FormGroup,
    },
    props: {
      value: {
        type: [String, Object, Number],
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      timeLabel: {
        type: String,
        default: null,
      },
      startDate: {
        type: String,
        default: null,
      },
      endDate: {
        type: String,
        default: null,
      },
      clickClose: {
        type: Boolean,
        default: true, 
      },
      inline: {
        type: Boolean,
        default: false, 
      },
      disabled: {
        type: Boolean,
        default: false, 
      },
      error: {
        type: Boolean,
        default: false, 
      },
      errorMessage: {
        type: String,
        default: '', 
      },
    },
    data () {
      return {
        innerDate: null,
        innerTime: null
      }
    },
    computed: {
      timeStamp () {
        if (!this.innerDate) return null;
        const [hour, minute] = this.innerTime.split(':');
        return moment(this.innerDate).hour(+hour).minute(+minute).valueOf().toString();
      },
      startTime () {
        if (this.innerDate === moment(new Date()).format('YYYY-MM-DD')) {
          return moment(this.startDate).format("hh:mm:ss")
        }
        return null
      }
    },
    watch: {
      value: {
        handler (val) {
          if (!val) {
            this.innerDate = null;
            this.innerTime = null;
            return;
          }

          const date = moment(Number(val)).format('YYYY-MM-DD');
          const hour = moment(Number(val)).hour().toString().padStart(2, '0');
          const minute = moment(Number(val)).minute().toString().padStart(2, '0');
          const time = `${hour}:${minute}`;

          if (this.innerDate !== date) {
            this.innerDate = date;
          }

          if (this.innerTime !== time) {
            this.innerTime = time;
          }
        },
        deep: true,
        immediate: true,
      }
    },
    methods: {
      onChangeDate () {
        this.innerTime = null
        this.$emit('input', this.timeStamp);
      },
      onChangeTime () {
        this.$emit('input', this.timeStamp);
      }
    }
  }
</script>

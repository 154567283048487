<template>
  <div class="incident-author">
    <div class="incident-author__name">
      <a
        target="_blank"
        :href="incident.authorUrl"
      >
        <h6>{{ incident.authorName }}</h6>
      </a>
      <div
        v-if="authorsAppeals && authorsAppeals.length"
        class="incident-author__wrapper ml-3"
        v-inner-click-outside="closeAppealsPopover"
      >
        <div
          class="incident-author__link"
          @click="toggleAppealsPopover"
        >
          <r-icon
            fill="rocky"
            icon="eye"
          />
          <span class="briscola rocky--text ml-1">Все обращения автора</span>
          <r-icon
            fill="rocky"
            class="incident-author__arrow ml-2"
            :class="{'incident-author__arrow--active': show}"
            icon="arrow-down"
            :size="12"
          />
        </div>
        <transition name="fade">
          <div
            v-if="show"
            class="incident-author__popover"
          >
            <p
              v-for="appeal in authorsAppeals"
              :key="appeal.id"
              class="incident-author__appeal link"
              @click="goToIncident(appeal)"
            >
              №{{ appeal.number }} от {{ appeal.createdAt | dateTextMonth }}
            </p>
          </div>
        </transition>
      </div>
    </div>
    <span class="mozzarella anie">{{ incident.createdAt | dayMonthHours }}</span>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { StatesRoutes } from '@/constants';

export default {
  name: 'IncidentAuthor',
  props: {
    incident: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      show: false,
      appeals: []
    };
  },
  computed: {
    authorsAppeals() {
      return this.appeals.filter(incident => incident.id !== this.$route.params.id);
    }
  },
  watch: {
    incident: {
      handler (val) {
        this.getAuthorsAppeals(val)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('incident', ['getIncidentsByAuthor']),
    ...mapMutations('incident', ['setExecutionType']),
    toggleAppealsPopover() {
      this.show = !this.show;
    },
    closeAppealsPopover() {
      this.show = false;
    },
    async getAuthorsAppeals (incident) {
      if (!Object.keys(incident).length) return
      this.appeals = await this.getIncidentsByAuthor({
        authorId: [incident?.authorId]
      });
    },
    goToIncident(incident) {
      this.setExecutionType('')
      if (incident.category) {
        this.$emit('goToIncident', `/${StatesRoutes[incident.category]}/${incident.id}`);
      }
      this.closeAppealsPopover();
    }
  }
};
</script>

<style lang="scss" scoped>
.incident-author {
  &__name {
    display: flex;
    align-items: center;
  }

  &__link {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__arrow {
    transition: transform 0.2s ease;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__wrapper {
    position: relative;
  }

  &__popover {
    position: absolute;
    z-index: 100;
    top: 32px;
    left: 0px;
    width: max-content;
    padding: 20px 20px 4px;
    background-color: var(--rir-amelie);
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    border-radius: 0px 16px 16px 16px;
  }

  &__appeal {
    display: block;
    margin-bottom: 16px;
    line-height: 1;
    padding: 2px 5px;
  }
}
</style>

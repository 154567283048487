import { helpers } from 'vuelidate/lib/validators';
import * as linkify from 'linkifyjs';

export const required = mesErr => helpers.withParams(
  { type: 'required', mesErr },
  value => !!value && helpers.req(value)
);
export const regEx = (regEx, mesErr) => helpers.withParams(
  { type: 'regEx', mesErr },
  value => !value || regEx.test(value)
);
export const period = mesErr => helpers.withParams(
  { type: 'period', mesErr },
  value => !value || value?.length === 2
);
export const typeFloatOrNumber = mesErr => helpers.withParams(
  { type: 'typeFloat', mesErr },
  // eslint-disable-next-line eqeqeq
  value => !value || (Number(value) == value && (value % 1 !== 0 || value % 1 === 0))
);
export const minLength = (countLength, mesErr) => helpers.withParams(
  { type: 'minLength', mesErr },
  value => !value || value?.length >= countLength
);
export const maxLength = (countLength, mesErr) => helpers.withParams(
  { type: 'maxLength', mesErr },
  value => !value || value?.length <= countLength
);
export const strLength = (countLength, mesErr) => helpers.withParams(
  { type: 'strLength', mesErr },
  value => !value || value.toString()?.length === countLength
);
export const apiCheck = (action, mesErr) => helpers.withParams(
  { type: 'apiCheck', mesErr },
  async () => await action()
);
export const under = (count, mesErr) => helpers.withParams(
  { type: 'extDueDate', mesErr },
  value => !value || value < count
);
export const isUrl = (mesErr) => helpers.withParams(
  { type: isUrl, mesErr },
  value => !value || linkify.test(value)
)

export default {
  async getUserInfo({ rootGetters, commit }) {
    const data = await rootGetters.GET('get-user');
    commit('setProperty', { name: 'user', value: data });
  },
  async getUserAreaCode({ rootGetters, commit }) {
    const data = await rootGetters.GET('user/area-code');
    commit('setProperty', { name: 'areaCode', value: data.value });
  }
};

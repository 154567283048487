<template>
  <div class="launch-form">
    <section class="launch-form__select">
      <FormSelect
        label="Департамент"
        :items="departments"
        idValue="id"
        titleValue="name"
        v-model.lazy="$v.model.departmentId.$model"
        :error="$v.model.departmentId.$error"
        :errorMessage="mesErr($v.model.departmentId)"
      />
      <FormSelect
        label="Ответственный"
        :items="executorsInDepartment"
        idValue="id"
        titleValue="fullName"
        subTitleValue="botIsBlockedByUser"
        :disabled="!$v.model.departmentId.$model"
        v-model.lazy="$v.model.executorId.$model"
        :error="$v.model.executorId.$error"
        :errorMessage="mesErr($v.model.executorId)"
      />
      <RequiredDurationComponent
        :readonly="isDisabledDatePicker"
        v-model="$v.model.dueDate.$model"
        :error="$v.model.dueDate.$error"
        :errorMessage="mesErr($v.model.dueDate)"
      />
      <FormSelect
        label="Тип проблемы"
        :items="problemsInDepartment(model.departmentId)"
        idValue="id"
        titleValue="name"
        :disabled="!$v.model.departmentId.$model"
        v-model.lazy="$v.model.problemId.$model"
        :error="$v.model.problemId.$error"
        :errorMessage="mesErr($v.model.problemId)"
      />
    </section>
    <section class="mt-6">
      <div class="mb-4">
        <FormTextarea
          :rows="8"
          :max-rows="8"
          label="Комментарий"
          v-model="model.moderatorMessage"
          :error="$v.model.moderatorMessage.$error"
          :errorMessage="mesErr($v.model.moderatorMessage)"
        />
      </div>
      <FileUploader
        v-if="isNotAttachFiles"
        class="mb-6"
        v-model="model.executorAttachments"
        mode="edit"
      />
    </section>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { required, maxLength } from '@/plugins/vuelidate/customValidate'
import FileView from '@/components/FileView'
import { mapGetters, mapState, mapActions } from 'vuex'
import RequiredDurationComponent from '@/components/RequiredDurationComponent'
import FormSelect from '@/components/FormSelect'
import FormTextarea from '@/components/FormTextarea'
import FileUploader from '@/components/FileUploader'

export default {
  name: 'LaunchForm',
  mixins: [validationMixin, validateMixin],
  components: {
    RequiredDurationComponent,
    FormSelect,
    FormTextarea,
    FileUploader
  },
  props: {
    incident: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoadingSendForm: false,
    time: null,
    model: {
      moderatorMessage: null,
      problemId: null,
      executorId: null,
      departmentId: null,
      dueDate: null,
      executorAttachments: []
    },
    incidentCodes: [],
    now: new Date().getTime()
  }),
  validations () {
    return {
      model: {
        problemId: {
          required: required('Обязательное поле')
        },
        departmentId: {
          required: required('Обязательное поле')
        },
        executorId: {
          required: required('Обязательное поле')
        },
        dueDate: {
          required: required('Обязательное поле')
          // under: this.incident.extDueDate ? under(this.incident.extDueDate, 'Дата больше внешнего срока исполнения') : false
        },
        moderatorMessage: {
          maxLength: maxLength(4000, 'Максимум 4000 символов')
        }
      }
    }
  },
  watch: {
    'model.departmentId': function () {
      this.model.executorId = null
      this.model.problemId = null
      this.$v.model.executorId.$reset()
      this.$v.model.problemId.$reset()
    }
  },
  computed: {
    ...mapState('directory', ['executors', 'departments', 'validTime']),
    ...mapState('incident', ['appealActions']),
    ...mapGetters('directory', ['problemsInDepartment']),
    ...mapState('session', ['areaCode']),
    executorsInDepartment () {
      return this.executors.filter(el => el.departmentId === this.model.departmentId && el.enabled).map(el => ({
        ...el,
        botIsBlockedByUser: el.botIsBlockedByUser ? 'Исполнитель заблокировал бота' : null,
        disabled: !!el.botIsBlockedByUser
      }))
    },
    noItemsExecutorText () {
      return this.model.departmentId ? 'В департаменте нет сотрудников' : 'Требуется выбрать департамент'
    },
    isAttachAnswer () {
      return this.model.executorAttachments.length < 2 && this.isNotAttachFiles
    },
    isNotAttachFiles () {
      return !['INSTAGRAM'].includes(this.incident.systemCode)
    },
    isDisabledDatePicker () {
      return this.appealActions?.publication?.length > 1
        && this.incident.areaCode !== this.areaCode
    },
    timeList () {
      return this.validTime.map((time) => {
        return {
          ...time,
          text: this.$options.filters.hours(time.value)
        }
      })
    }
  },
  mounted () {
    this.loadDirectories()
    this.model.dueDate = this.incident.dueDate
  },
  methods: {
    ...mapActions('directory', ['loadDirectories']),
    selectFile (file) {
      this.$root.$modal.openModal(FileView, { file })
    }
  }
}
</script>

<style lang="scss" scoped>
.launch-form {
  &__select {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }

  &__time {
    padding: 16px;
  }
}
</style>

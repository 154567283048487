import moment from 'moment';
import { formatDuration } from '@/plugins/lib';

moment.locale('ru');
export default {
  install(Vue, options) {
    Vue.filter('dateTextMonth', date => (date ? moment(date).format('DD MMMM YYYY') : ''));
    Vue.filter('dateTime', date => (date ? moment(date).format('DD.MM.YYYY, HH:mm') : ''));
    Vue.filter('dayMonthHours', date => (date ? moment(date).format('DD MMMM HH:mm') : ''));
    Vue.filter('time', date => (date ? moment(date).format('HH:mm') : ''));
    Vue.filter('hours', date => (date ? moment.duration(date / 3600000, 'hours').humanize() : ''));
    Vue.filter('formatDuration', date => (date ? formatDuration(date) : ''));
  }
};

<template>
  <FormGroup
    :error="error"
    :errorMessage="errorMessage"
  >
    <template v-slot="params">
      <r-select
        ref="select"
        :label="label"
        :idValue="idValue"
        :titleValue="titleValue"
        :subTitleValue="subTitleValue"
        :value="value"
        @input="$emit('input', $event)"
        :items="items"
        :isLoading="isLoading"
        :callSearch="callSearch"
        :isSearch="isSearch"
        :returnObject="returnObject"
        :error="error"
        :disabled="disabled"
      >
        <template #body>
          <div
            v-if="isSelectAll"
            class="px-5 py-3"
          >
            <r-checkbox
              :value="selectAll"
              :title="selectAllTitle"
              :is-partial="selectPartial"
              @input="changeAllItems"
            />
          </div>
        </template>
      </r-select>
    </template>
    <template v-slot:after>
      <slot name="after"></slot>
    </template>
  </FormGroup>
</template>

<script>
  import FormGroup from "@/components/FormGroup.vue";

  export default {
    name: 'FormSelect',
    components: {
      FormGroup,
    },
    props: {
      label: {
        type: String,
        default: null,
      },
      idValue: {
        type: String,
        default: 'id',
      },
      titleValue: {
        type: String,
        default: 'title',
      },
      subTitleValue: {
        type: String,
        default: 'subTitle'
      },
      value: {
        type: [Object, String, Number, Array],
        default: null,
      },
      items: {
        type: Array,
        default: () => [],
      },
      callSearch: {
        type: Function,
        default: () => {}
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      isSearch: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      returnObject: {
        type: Boolean,
        default: false
      },
      error: {
        type: Boolean,
        default: false, 
      },
      errorMessage: {
        type: String,
        default: ''
      },
      isSelectAll: {
        type: Boolean,
        default: false
      },
      selectAllTitle: {
        type: String,
        default: 'Выбрать все',
      }
    },
    computed: {
      selectAll() {
        return this.value?.length === this.items?.length;
      },
      selectPartial() {
        return !!this.value?.length && this.value?.length !== this.items?.length;
      },
    },
    mounted() {
      window.addEventListener('scroll', this.closePopover);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.closePopover);
    },
    methods: {
      changeAllItems(val) {
        this.$emit('input', val
          ? this.idValue && !this.returnObject ? [...this.items.map(item => item[this.idValue])] : [...this.items]
          : []
        )
      },
      closePopover () {
        const select = this.$refs.select
        if (select.isOpen) {
          select.isOpen = false
        }
      }
    }
  }
</script>

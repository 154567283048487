<template>
  <div
    class="layout-main"
    :class="{'layout-main--close': !isSidebarOpen}"
  >
    <r-layout-dashboard
      :is-compact="true"
      :is-offset-content="false"
      :menu-options="{
        items: getTabs
      }"
      ref="dashboard"
    >
      <template #header>
        <span
          class="taleggio"
        >
          Рабочее место <br> модератора
        </span>
      </template>
      <template #navigation>
        <toolbar
          id="toolbar"
          class="layout-main__toolbar"
          :class="[
            {'layout-main__toolbar--show': scrollUp || scroll < contentTop},
            {'layout-main__toolbar--shadowed': scroll > contentTop},
          ]"
        >
          <router-view name="toolbar" />
        </toolbar>
      </template>
      <router-view
        slot="content"
        :style="`padding-top: ${contentTop}px`"
      />
      <template #footer>
        <r-button-action
          class="sidebar__report RMenu__body"
          title="Сообщить об ошибке"
          icon="complaint"
          @click="report"
        />
        <r-tooltip
          class="sidebar__report compact"
          position="center-right"
          title="Сообщить об ошибке"
        >
          <template #activator>
            <r-button-action
              class="RMenu__body"
              title=""
              icon="complaint"
              @click="report"
            />
          </template>
        </r-tooltip>
        <div
          class="sidebar__logo"
        >
          <div
            class="sidebar__logo-svg"
          />
        </div>
        <scroll-to-top-button />
      </template>
    </r-layout-dashboard>
    <!--<r-app-layout
      is-toggle
      @toggleMenu="toggleSidebarHandler"
      scrollable
    >
      <template #sidebar>
        <sidebar :is-sidebar-open="isSidebarOpen" />
      </template>
      <template #toolbar>
        <toolbar
          id="toolbar"
          class="layout-main__toolbar"
          :class="[
            {'layout-main__toolbar--show': scrollUp || scroll < contentTop},
            {'layout-main__toolbar--shadowed': scroll > contentTop},
          ]"
        >
          <router-view name="toolbar" />
        </toolbar>
      </template>
      <router-view
        slot="default"
        :style="`padding-top: ${contentTop}px`"
      />
    </r-app-layout>-->
  </div>
</template>

<script>
import Sidebar from '@/layout/components/Sidebar';
import Toolbar from '@/layout/components/Toolbar';
import ScrollToTopButton from '@/components/ScrollToTopButton';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'DefaultLayout',
  components: { Toolbar, Sidebar, ScrollToTopButton },
  data() {
    return {
      scrollUp: false,
      toolbar: null,
      contentTop: null
    };
  },
  computed: {
    ...mapState('apps', ['isSidebarOpen', 'scroll']),
    ...mapState('directory', ['appealSystemCounter']),
    ...mapState('incident', ['isLoading', 'isSelectionMode']),
    menuItems() {
      return [
        {
          title: 'Создать',
          icon: 'add',
          router: {
            path: '/create'
          },
          disabled: this.isSelectionMode
        },
        {
          title: 'Новые',
          icon: 'new',
          router: {
            path: '/incident-new'
          },
          counter: 'NEW',
          disabled: this.isLoading
        },
        {
          title: 'В работе',
          icon: 'in-progress',
          router: {
            path: '/incident-work'
          },
          counter: 'IN_WORK',
          disabled: this.isLoading
        },
        {
          title: 'Ожидают решения',
          icon: 'waiting',
          router: {
            path: '/incident-analyze'
          },
          counter: 'WAITING_DECISION',
          disabled: this.isLoading
        },
        {
          title: 'Дозапрос',
          icon: 'help',
          router: {
            path: '/incident-additional-question'
          },
          counter: 'ADDITIONAL_QUESTION',
          disabled: this.isLoading
        },
        {
          title: 'Отложенные',
          icon: 'timer',
          router: {
            path: '/incident-paused'
          },
          counter: 'PAUSED',
          disabled: this.isLoading
        },
        {
          title: 'Ошибка отправки',
          icon: 'warning',
          router: {
            path: '/incident-error'
          },
          counter: 'ERROR',
          disabled: this.isLoading || this.isSelectionMode
        },
        {
          title: 'Решённые',
          icon: 'selection',
          router: {
            path: '/incident-completed'
          },
          counter: 'SOLVED',
          disabled: this.isLoading || this.isSelectionMode
        },
        {
          title: 'Удалённые',
          icon: 'delete',
          router: {
            path: '/incident-deleted'
          },
          disabled: this.isLoading || this.isSelectionMode
        },
        {
          title: 'Статистика',
          icon: 'chart',
          router: {
            path: '/statistics'
          },
          disabled: this.isSelectionMode
        },
        {
          title: 'Настройки',
          icon: 'settings',
          router: {
            path: '/settings'
          },
          disabled: this.isSelectionMode
        }
      ];
    },
    getTabs() {
      return this.menuItems.map(el => {
        const count = this.showCount(this.appealSystemCounter[el.counter]?.total?.count);
        if (el.counter) {
          return {
            ...el,
            bulbOptions: {
              title: count ? String(count) : null,
              color: this.appealSystemCounter[el.counter]?.total?.exp ? 'fargo' : 'rocky'
            }
          };
        }
        return el;
      });
    }
  },
  watch: {
    $route: {
      handler() {
        this.$nextTick(() => {
          this.toolbar = document.getElementById('toolbar');
          this.contentTop = this.toolbar.clientHeight + 8;
        });
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.addToolbarShadow);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.addToolbarShadow);
  },
  methods: {
    ...mapMutations('apps', ['toggleSidebar', 'setScroll']),
    toggleSidebarHandler() {
      this.toggleSidebar();
    },
    addToolbarShadow() {
      this.scrollUp = window.scrollY - this.scroll < 0;
      if (window.scrollY === 0 || (this.scrollUp && window.scrollY > this.contentTop)) {
        this.toolbar.style.top = 0;
      }
      if (window.scrollY < this.contentTop) {
        this.toolbar.style.top = `-${window.scrollY}px`;
      }
      this.setScroll(window.scrollY);
    },
    showCount(count) {
      const value = count > 999 ? '999+' : count;
      return value;
    },
    report() {
      window.showCollectorDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar__report.compact{
  display: none;
}
::v-deep .RLayoutDashboard__menu.compact{
  .sidebar__logo{
    display: none;
  }
  .sidebar__report{
    display: none;
    &.compact{
      display: block;
    }
  }
}

::v-deep .RLayoutDashboard__list .RMenu{
  flex: none!important;
}
::v-deep .RMenu__body.activeLink{
  .RMenu__title{
    opacity: 1;
  }
  .RMenu__icon{
    opacity: 1;
  }
}
::v-deep  .RLayoutDashboard__footer{
  height: auto!important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto!important;
  padding-left: 0!important;
  padding-right: 0!important;
}
::v-deep .RLayoutDashboard__navigation{
  height: auto!important;
}
::v-deep .RMenu__icon{
  opacity: .72
}
::v-deep .RMenu__body:hover {
  .RMenu__title,
  .RMenu__icon{
    opacity: 1
  }
}
::v-deep .RLayoutDashboard__menu.compact .RMenu__body{
  justify-content: center;
  width: 100%;
}
.layout-main {
  &__toolbar {
    position: fixed;
    right: 0;
    top: 0;
    width: calc(100% - 344px);
    height: auto;
    padding: 32px 32px 24px;
    opacity: 0;
    pointer-events: none;
    z-index: 100;
    transform: translate(0, -20%);
    border-top-left-radius: 36px;

    &--show {
      opacity: 1;
      pointer-events: auto;
      transform: translate(0, 0);
    }

    &--shadowed {
      box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
      border-top-left-radius: 0;
    }
  }

  &::v-deep {
    // .r-app-layout__toolbar {
    //   height: 36px;
    // }

    .r-app-layout__body {
      left: 344px;
      background-color: var(--rir-gladiator);
      width: calc(100% - 344px);
    }

    .r-app-layout__body::after {
      display: none;
    }

    .r-app-layout__body::before {
      left: 344px;
    }

    .r-app-layout__sidebar {
      width: 344px;
      padding-right: 0;
      transition: 0.3s ease;
    }

    .r-app-layout__close {
      position: fixed;
      left: 307px;

      &--open {
        left: 25px;
      }
    }

    .r-app-layout__toolbar {
      padding: 0;
      height: auto;
    }
  }

 /* &--close {
    .layout-main__toolbar {
      width: calc(100% - 60px);
    }
    &::v-deep {
      .r-app-layout__sidebar {
        z-index: 9;
        width: 60px;
        padding-right: 0;
      }

      .r-app-layout__body {
        left: 60px;
        width: calc(100% - 60px);
      }

      .r-app-layout__body::before {
        left: 60px;
      }
    }
  }*/
}
body.widest .RLayoutDashboard__menu.compact+.RLayoutDashboard__body .layout-main__toolbar {
  width: calc(100% - 64px);
}

.layout-main__toolbar {
  background-color: var(--rir-trainspotting);
}
.sidebar{
  &__logo {
    position: relative;
    height: 112px;
    transition: box-shadow 0.3s ease;
    flex-shrink: 0;
    width: 100%;
    padding: 12px 16px;
    &-svg {
      background-repeat: no-repeat;
      background-position: left;
      background-image: url("/img/img/logo.svg");
      height: 100%;
    }

    &--shadowed {
      box-shadow: 0px 10px 36px rgba(4, 21, 62, 0.72);
    }
  }
}
</style>

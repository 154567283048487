import mixinsCommit from '@/store/mixins/mixinsCommit';
import { STATES } from '@/constants';

export default {
  ...mixinsCommit,
  changeLoadingList(state, payload) {
    state.states.loading = payload;
  },
  setExecutionType(state, payload) {
    state.executionType = payload;
  },
  addIncident(state, payload) {
    const incident = state.incidents.find(incident => incident.id === payload.id);
    if (incident) return;
    state.incidents.unshift(payload);
  },
  clearFilterProperties(state) {
    state.filters = {
      searching: null,
      pageIndex: 0,
      size: 20
    };
  },
  pushActions(state, payload) {
    state.appealActions = state.appealActions.concat(payload);
  },
  concatIncident(state, payload) {
    state.incidents = state.incidents.concat(payload);
  },
  removeIncident(state, payload) {
    state.incidents = state.incidents.filter(incident => payload.id !== incident.id);
  },
  setFilterProperty(state, payload) {
    const { attr, value } = payload;
    const newStateFilters = { ...state.filters };
    newStateFilters[attr] = value;
    state.filters = newStateFilters;
  },
  changeMark(state, payload) {
    const { modelId, status } = payload;
    const incident = state.incidents.find(el => el.id === modelId);
    if (!incident) return;
    incident.isRead = status;
  },
  setSelectionMode(state, payload) {
    state.isSelectionMode = payload;
    if (payload) return;
    state.selectedIncidents = [];
  },
  setSelectedIncident(state, payload) {
    const index = state.selectedIncidents?.findIndex(incident => incident.data.id === payload.data.id);
    index === -1
      ? state.selectedIncidents.push(payload)
      : state.selectedIncidents.splice(index, 1);
  },
  setSelectedIncidents(state, payload) {
    state.selectedIncidents = payload;
  },
  setActions(state) {
    if (state.incidents?.length) {
      const routeName = this.getters['apps/currentRoute'].meta.type;
      if (routeName !== STATES.REMOVED && routeName !== STATES.SOLVED) {
        state.incidents = state.incidents.map(incident => {
          if (incident.actions?.publication?.length || incident.actions?.note?.length) return incident;
          const actions = state.appealActions.find(appeal => appeal.appealId === incident.id);
          return {
            ...incident,
            actions: {
              isLoading: !actions?.publication,
              note: actions?.note || [],
              publication: actions?.publication || []
            }
          };
        });
      }
    }
  }
};

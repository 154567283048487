<template>
  <div class="due-dates d-flex align-items-center">
    <r-tooltip
      v-if="incident.createdAt"
      activator-hover
      color="amelie"
      title="Поступило"
    >
      <template #activator>
        <div class="d-flex align-items-center mb-2">
          <r-icon
            fill="rocky"
            icon="new"
            size="16"
          />
          <span class="ml-1 ekas mozzarella">{{ incident.createdAt | dateTime }}</span>
        </div>
      </template>
    </r-tooltip>

    <r-tooltip
      activator-hover
      color="amelie"
      title="Внешний срок исполнения"
    >
      <template #activator>
        <div class="d-flex align-items-center ml-4 mb-2">
          <r-icon
            icon="calendar"
            fill="lebowski"
            :size="16"
          />
          <div
            class="ml-1 mozzarella"
            :class="incident.extDueDate ? 'ekas' : 'anie'"
          >
            {{ extDueDate }}
          </div>
        </div>
      </template>
    </r-tooltip>

    <r-tooltip
      v-if="isShownDeadline"
      activator-hover
      color="amelie"
      :title="tooltipInfo"
    >
      <template #activator>
        <div class="d-flex align-items-center ml-4 mb-2">
          <r-icon
            icon="calendar"
            :fill="inTime ? 'rocky' : 'fargo'"
            :size="16"
          />
          <span class="ml-1 ekas mozzarella">{{ deadline | dateTime }}</span>
        </div>
      </template>
    </r-tooltip>

    <r-tooltip
      v-if="isCompleted && incident.endTs"
      activator-hover
      color="amelie"
      :title="inTime ? 'Завершено' : 'Завершено с просрочкой'"
    >
      <template #activator>
        <div class="ml-4 d-flex align-items-center mb-2">
          <r-icon
            icon="selection"
            :fill="inTime ? 'matrix' : 'fargo'"
            :size="16"
          />
          <span class="ml-1 mozzarella titanic--text ekas">
            {{ incident.endTs | dateTime }}
          </span>
        </div>
      </template>
    </r-tooltip>

    <r-tooltip
      v-if="isRemoved && incident.endTs"
      activator-hover
      color="amelie"
      title="Удалено"
    >
      <template #activator>
        <div class="ml-4 d-flex align-items-center mb-2">
          <r-icon
            icon="delete"
            fill="metropolis"
            :size="16"
          />
          <span class="ml-1 mozzarella titanic--text ekas">
            {{ incident.endTs | dateTime }}
          </span>
        </div>
      </template>
    </r-tooltip>
  </div>
</template>
<script>
import { STATES } from '@/constants';

export default {
  name: 'DueDates',
  props: {
    incident: {
      type: Object,
      default: null
    }
  },
  computed: {
    isCompleted() {
      return this.$route.meta.type === STATES.SOLVED;
    },
    isRemoved() {
      return this.$route.meta.type === STATES.REMOVED;
    },
    isShownDeadline () {
      return this.deadline && !this.isRemoved && !this.isCompleted
    },
    deadline() {
      return this.incident.dueDate;
    },
    inTime () {
      return this.incident.discipline === 'ON_TIME'
    },
    extDueDate() {
      return this.incident?.extDueDate ? this.$options.filters.dateTime(this.incident.extDueDate) : 'Нет даты';
    },
    tooltipInfo () {
      return this.$route.meta.type === STATES.PAUSED
        ? this.inTime
          ? 'Внутренний срок отложения'
          : 'Внутренний срок отложения (просрочено)'
        : this.inTime
          ? 'Внутренний срок исполнения'
          : 'Внутренний срок исполнения (просрочено)'
    }
  }
};
</script>

<template>
  <div class="empty">
    <div class="empty__wrapper">
      <div class="empty__icon mb-2" v-html="icon" />
      <div class="empty__content">
        <p class="feta ekas mb-1">{{ title }}</p>
        <p class="mozzarella anie">{{ details }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Empty',
  props: {
    title: {
      type: String,
      default: ''
    },
    details: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      icon: `
        <svg width="120" height="112" viewBox="0 0 120 112" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M79.828 47.8951L79.7377 47.8424L69.5508 41.9738L79.828 36.0525L79.9183 36.1052L90.1052 41.9738L79.828 47.8951ZM70.2775 41.9738L79.827 47.4758L89.3774 41.9738L79.828 36.4718L70.2775 41.9738Z" fill="#E4EDFB"/>
        <path d="M89.742 53.6056L89.6517 53.5529L79.4648 47.6843L89.742 41.7629L100.019 47.6843L89.742 53.6056ZM80.1926 47.6853L89.742 53.1873L99.2915 47.6863L89.742 42.1843L80.1926 47.6853Z" fill="#E4EDFB"/>
        <path d="M99.6542 59.3172L89.377 53.3959L99.6542 47.4746L109.931 53.3959L99.6542 59.3172ZM90.1047 53.3959L99.6542 58.8979L109.204 53.3969L99.6542 47.895L90.1047 53.3959Z" fill="#E4EDFB"/>
        <path d="M109.568 65.0289L99.291 59.1076L109.568 53.1863L109.659 53.239L119.845 59.1076L109.568 65.0289ZM100.018 59.1076L109.567 64.6096L119.117 59.1076L109.567 53.6056L100.018 59.1076Z" fill="#E4EDFB"/>
        <path d="M59.9999 47.8941L59.9095 47.8414L49.7227 41.9728L59.9999 36.0525L60.0902 36.1052L70.2771 41.9738L59.9999 47.8941ZM50.4504 41.9728L59.9999 47.4747L69.5493 41.9738L59.9999 36.4718L50.4504 41.9728Z" fill="#E4EDFB"/>
        <path d="M69.9139 53.6058L59.6367 47.6845L69.9139 41.7642L70.0043 41.8168L80.1911 47.6855L69.9139 53.6058ZM60.3645 47.6845L69.9139 53.1865L79.4634 47.6845L69.9139 42.1825L60.3645 47.6845Z" fill="#E4EDFB"/>
        <path d="M79.828 59.3172L79.7377 59.2646L69.5508 53.3959L79.828 47.4746L79.9183 47.5273L90.1052 53.3959L79.828 59.3172ZM70.2775 53.3959L79.827 58.8979L89.3774 53.3959L79.828 47.8939L70.2775 53.3959Z" fill="#E4EDFB"/>
        <path d="M89.742 65.0289L79.4648 59.1076L89.742 53.1863L100.019 59.1076L89.742 65.0289ZM80.1926 59.1076L89.742 64.6096L99.2915 59.1076L89.742 53.6056L80.1926 59.1076Z" fill="#E4EDFB"/>
        <path d="M99.6542 70.7406L99.5638 70.6879L89.377 64.8193L99.6542 58.8979L109.931 64.8193L99.6542 70.7406ZM90.1047 64.8193L99.6542 70.3213L109.204 64.8203L99.6542 59.3183L90.1047 64.8193Z" fill="#E4EDFB"/>
        <path d="M109.568 76.4523L109.478 76.3996L99.291 70.5309L109.568 64.6096L109.659 64.6623L119.845 70.5309L109.568 76.4523ZM100.019 70.5309L109.568 76.0329L119.118 70.532L109.568 65.03L100.019 70.5309Z" fill="#E4EDFB"/>
        <path d="M40.1737 47.8939L40.0834 47.8412L29.8965 41.9726L40.1737 36.0513L40.264 36.1039L50.4509 41.9726L40.1737 47.8939ZM30.6232 41.9726L40.1727 47.4745L49.7221 41.9726L40.1727 36.4706L30.6232 41.9726Z" fill="#E4EDFB"/>
        <path d="M50.0878 53.6056L49.9974 53.5529L39.8105 47.6843L50.0878 41.7629L50.1781 41.8156L60.365 47.6843L50.0878 53.6056ZM40.5373 47.6843L50.0868 53.1862L59.6372 47.6843L50.0878 42.1823L40.5373 47.6843Z" fill="#E4EDFB"/>
        <path d="M60.0018 59.3172L59.9115 59.2646L49.7246 53.3959L60.0018 47.4746L70.279 53.3959L60.0018 59.3172ZM50.4514 53.3959L60.0008 58.8979L69.5502 53.3969L60.0008 47.8939L50.4514 53.3959Z" fill="#E4EDFB"/>
        <path d="M69.9139 65.0289L59.6367 59.1076L69.9139 53.1873L70.0043 53.2399L80.1911 59.1086L69.9139 65.0289ZM60.3645 59.1076L69.9139 64.6095L79.4634 59.1076L69.9139 53.6056L60.3645 59.1076Z" fill="#E4EDFB"/>
        <path d="M79.828 70.7406L69.5508 64.8193L79.828 58.8979L90.1052 64.8193L79.828 70.7406ZM70.2786 64.8193L79.828 70.3213L89.3774 64.8193L79.828 59.3173L70.2786 64.8193Z" fill="#E4EDFB"/>
        <path d="M89.7421 76.4523L89.6517 76.3996L79.4648 70.5309L89.7421 64.6096L89.8324 64.6623L100.019 70.5309L89.7421 76.4523ZM80.1916 70.5309L89.7411 76.0329L99.2905 70.5309L89.7411 65.0289L80.1916 70.5309Z" fill="#E4EDFB"/>
        <path d="M99.6552 82.1629L99.5649 82.1102L89.377 76.2426L99.6542 70.3213L99.7445 70.374L109.931 76.2426L99.6552 82.1629ZM90.1047 76.2426L99.6542 81.7445L109.204 76.2426L99.6542 70.7406L90.1047 76.2426Z" fill="#E4EDFB"/>
        <path d="M109.568 87.8746L109.478 87.8219L99.291 81.9533L109.568 76.032L119.845 81.9533L109.568 87.8746ZM100.019 81.9533L109.568 87.4553L119.118 81.9543L109.568 76.4523L100.019 81.9533Z" fill="#E4EDFB"/>
        <path d="M30.2596 53.6058L19.9824 47.6845L30.2596 41.7642L30.35 41.8168L40.5368 47.6855L30.2596 53.6058ZM20.7102 47.6845L30.2596 53.1865L39.8091 47.6845L30.2596 42.1825L20.7102 47.6845Z" fill="#E4EDFB"/>
        <path d="M40.1737 59.3172L40.0834 59.2646L29.8965 53.3959L40.1737 47.4746L40.264 47.5273L50.4509 53.3959L40.1737 59.3172ZM30.6243 53.3959L40.1737 58.8979L49.7231 53.3959L40.1737 47.8939L30.6243 53.3959Z" fill="#E4EDFB"/>
        <path d="M50.0878 65.0289L49.9974 64.9762L39.8105 59.1076L50.0878 53.1863L50.1781 53.239L60.365 59.1076L50.0878 65.0289ZM40.5383 59.1076L50.0878 64.6096L59.6372 59.1076L50.0878 53.6056L40.5383 59.1076Z" fill="#E4EDFB"/>
        <path d="M60.0018 70.7396L59.9115 70.6869L49.7246 64.8193L60.0018 58.8979L70.279 64.8193L60.0018 70.7396ZM50.4514 64.8193L60.0008 70.3212L69.5502 64.8203L60.0008 59.3183L50.4514 64.8193Z" fill="#E4EDFB"/>
        <path d="M69.9149 76.4512L69.8246 76.3986L59.6367 70.5299L69.9139 64.6096L80.1911 70.5309L69.9149 76.4512ZM60.3645 70.5299L69.9139 76.0319L79.4634 70.5309L69.9139 65.0289L60.3645 70.5299Z" fill="#E4EDFB"/>
        <path d="M79.828 82.1629L69.5508 76.2416L79.828 70.3213L79.9183 70.374L90.1052 76.2426L79.828 82.1629ZM70.2786 76.2416L79.828 81.7435L89.3774 76.2416L79.828 70.7396L70.2786 76.2416Z" fill="#E4EDFB"/>
        <path d="M89.7421 87.8746L89.6517 87.8219L79.4648 81.9533L89.7421 76.032L89.8324 76.0846L100.019 81.9533L89.7421 87.8746ZM80.1926 81.9533L89.7421 87.4553L99.2915 81.9533L89.7421 76.4513L80.1926 81.9533Z" fill="#E4EDFB"/>
        <path d="M99.6561 93.586L99.5658 93.5334L89.3789 87.6647L99.6561 81.7434L99.7465 81.7961L109.933 87.6647L99.6561 93.586ZM90.1057 87.6647L99.6551 93.1667L109.205 87.6647L99.6551 82.1627L90.1057 87.6647Z" fill="#E4EDFB"/>
        <path d="M20.3475 59.3172L20.2572 59.2646L10.0703 53.3959L20.3475 47.4746L30.6247 53.3959L20.3475 59.3172ZM10.7971 53.3959L20.3465 58.8979L29.897 53.3959L20.3465 47.8939L10.7971 53.3959Z" fill="#E4EDFB"/>
        <path d="M30.2616 65.0289L19.9844 59.1076L30.2616 53.1873L30.3519 53.2399L40.5388 59.1086L30.2616 65.0289ZM20.7111 59.1076L30.2606 64.6095L39.81 59.1076L30.2606 53.6056L20.7111 59.1076Z" fill="#E4EDFB"/>
        <path d="M40.1737 70.7396L40.0834 70.6869L29.8965 64.8193L40.1737 58.8979L40.264 58.9506L50.4509 64.8193L40.1737 70.7396ZM30.6243 64.8193L40.1737 70.3212L49.7231 64.8193L40.1737 59.3173L30.6243 64.8193Z" fill="#E4EDFB"/>
        <path d="M50.0878 76.4513L49.9974 76.3986L39.8105 70.53L50.0878 64.6086L50.1781 64.6613L60.365 70.53L50.0878 76.4513ZM40.5383 70.53L50.0878 76.0319L59.6372 70.53L50.0878 65.028L40.5383 70.53Z" fill="#E4EDFB"/>
        <path d="M60.0018 82.1629L59.9115 82.1103L49.7246 76.2416L60.0018 70.3203L60.0922 70.373L70.279 76.2416L60.0018 82.1629ZM50.4524 76.2416L60.0018 81.7436L69.5512 76.2416L60.0018 70.7396L50.4524 76.2416Z" fill="#E4EDFB"/>
        <path d="M69.9159 87.8746L59.6387 81.9533L69.9159 76.032L80.1931 81.9533L69.9159 87.8746ZM60.3664 81.9533L69.9159 87.4553L79.4653 81.9543L69.9159 76.4523L60.3664 81.9533Z" fill="#E4EDFB"/>
        <path d="M79.83 93.586L69.5527 87.6647L79.83 81.7444L79.9203 81.7971L90.1072 87.6657L79.83 93.586ZM70.2795 87.6647L79.8289 93.1666L89.3784 87.6647L79.8289 82.1627L70.2795 87.6647Z" fill="#E4EDFB"/>
        <path d="M89.7421 99.2977L89.6517 99.245L79.4648 93.3764L89.7421 87.4551L89.8324 87.5078L100.019 93.3764L89.7421 99.2977ZM80.1926 93.3764L89.7421 98.8784L99.2915 93.3764L89.7421 87.8744L80.1926 93.3764Z" fill="#E4EDFB"/>
        <path d="M10.4335 65.0279L10.3431 64.9752L0.15625 59.1076L10.4335 53.1863L10.5238 53.239L20.7107 59.1076L10.4335 65.0279ZM0.884029 59.1076L10.4335 64.6096L19.9829 59.1076L10.4335 53.6056L0.884029 59.1076Z" fill="#E4EDFB"/>
        <path d="M20.3475 70.7396L20.2572 70.6869L10.0703 64.8183L20.3475 58.897L30.6247 64.8183L20.3475 70.7396ZM10.7981 64.8183L20.3475 70.3203L29.897 64.8193L20.3475 59.3173L10.7981 64.8183Z" fill="#E4EDFB"/>
        <path d="M30.2616 76.4512L30.1713 76.3986L19.9844 70.5299L30.2616 64.6096L30.3519 64.6623L40.5388 70.5309L30.2616 76.4512ZM20.7122 70.5299L30.2616 76.0319L39.811 70.5309L30.2616 65.0289L20.7122 70.5299Z" fill="#E4EDFB"/>
        <path d="M40.1757 82.1629L29.8984 76.2416L40.1757 70.3203L40.266 70.373L50.4529 76.2416L40.1757 82.1629ZM30.6252 76.2416L40.1746 81.7436L49.7241 76.2416L40.1746 70.7396L30.6252 76.2416Z" fill="#E4EDFB"/>
        <path d="M50.0878 87.8746L49.9974 87.8219L39.8105 81.9533L50.0878 76.032L50.1781 76.0846L60.365 81.9533L50.0878 87.8746ZM40.5373 81.9533L50.0867 87.4553L59.6362 81.9533L50.0867 76.4513L40.5373 81.9533Z" fill="#E4EDFB"/>
        <path d="M60.0018 93.586L49.7246 87.6647L60.0018 81.7434L70.279 87.6647L60.0018 93.586ZM50.4524 87.6647L60.0018 93.1667L69.5512 87.6647L60.0018 82.1627L50.4524 87.6647Z" fill="#E4EDFB"/>
        <path d="M69.9159 99.2967L69.8256 99.2441L59.6387 93.3754L69.9159 87.4541L80.1931 93.3754L69.9159 99.2967ZM60.3664 93.3764L69.9159 98.8784L79.4653 93.3775L69.9159 87.8755L60.3664 93.3764Z" fill="#E4EDFB"/>
        <path d="M79.83 105.008L79.7396 104.956L69.5527 99.0871L79.83 93.1658L79.9203 93.2184L90.1072 99.0871L79.83 105.008ZM70.2795 99.0871L79.8289 104.589L89.3784 99.0881L79.8289 93.5861L70.2795 99.0871Z" fill="#E4EDFB"/>
        <path d="M10.4345 76.4513L10.3441 76.3986L0.15625 70.53L10.4335 64.6086L10.5238 64.6613L20.7107 70.53L10.4345 76.4513ZM0.884029 70.53L10.4335 76.0319L19.9829 70.53L10.4335 65.028L0.884029 70.53Z" fill="#E4EDFB"/>
        <path d="M20.3475 82.1629L20.2572 82.1103L10.0703 76.2416L20.3475 70.3203L20.4379 70.373L30.6247 76.2416L20.3475 82.1629ZM10.7981 76.2416L20.3475 81.7436L29.897 76.2416L20.3475 70.7396L10.7981 76.2416Z" fill="#E4EDFB"/>
        <path d="M30.2616 87.8746L30.1713 87.8219L19.9844 81.9533L30.2616 76.032L40.5388 81.9533L30.2616 87.8746ZM20.7122 81.9533L30.2616 87.4553L39.811 81.9543L30.2616 76.4523L20.7122 81.9533Z" fill="#E4EDFB"/>
        <path d="M40.1757 93.585L40.0853 93.5324L29.8984 87.6637L40.1757 81.7424L40.266 81.7951L50.4529 87.6637L40.1757 93.585ZM30.6262 87.6647L40.1757 93.1667L49.7251 87.6658L40.1757 82.1638L30.6262 87.6647Z" fill="#E4EDFB"/>
        <path d="M50.0878 99.2967L39.8105 93.3754L50.0878 87.4551L60.365 93.3764L50.0878 99.2967ZM40.5383 93.3754L50.0878 98.8773L59.6372 93.3754L50.0878 87.8734L40.5383 93.3754Z" fill="#E4EDFB"/>
        <path d="M60.0018 105.008L49.7246 99.0871L60.0018 93.1658L60.0922 93.2184L70.279 99.0871L60.0018 105.008ZM50.4524 99.0871L60.0018 104.589L69.5512 99.0871L60.0018 93.5851L50.4524 99.0871Z" fill="#E4EDFB"/>
        <path d="M10.4335 87.8746L10.3431 87.8219L0.15625 81.9533L10.4335 76.032L10.5238 76.0846L20.7107 81.9533L10.4335 87.8746ZM0.883014 81.9533L10.4324 87.4553L19.9819 81.9533L10.4324 76.4513L0.883014 81.9533Z" fill="#E4EDFB"/>
        <path d="M20.3475 93.585L20.2572 93.5324L10.0703 87.6637L20.3475 81.7424L20.4379 81.7951L30.6247 87.6637L20.3475 93.585ZM10.7971 87.6647L20.3465 93.1667L29.896 87.6647L20.3465 82.1628L10.7971 87.6647Z" fill="#E4EDFB"/>
        <path d="M30.2616 99.2967L30.1713 99.2441L19.9844 93.3754L30.2616 87.4541L30.3519 87.5068L40.5388 93.3754L30.2616 99.2967ZM20.7111 93.3754L30.2606 98.8774L39.81 93.3754L30.2606 87.8734L20.7111 93.3754Z" fill="#E4EDFB"/>
        <path d="M40.1757 105.008L40.0853 104.956L29.8984 99.0871L40.1757 93.1658L50.4529 99.0871L40.1757 105.008ZM30.6262 99.0871L40.1757 104.589L49.7251 99.0881L40.1757 93.5861L30.6262 99.0871Z" fill="#E4EDFB"/>
        <path d="M60.0265 68.4464L30.1562 51.2379L59.8317 34.0293L60.0265 68.4464Z" fill="#C0D6F6"/>
        <path d="M60.0268 68.4464L59.832 34.0293L89.7014 51.2379L60.0268 68.4464Z" fill="#C0D6F6"/>
        <path d="M51.5273 63.5504L60.0274 68.4464L68.3027 63.6476L59.8327 58.6868L51.5273 63.5504Z" fill="#E4EDFB"/>
        <path d="M89.7014 51.2377L96.2809 42.0772L66.4115 24.8687L59.832 34.0292L89.7014 51.2377Z" fill="#E4EDFB"/>
        <path d="M30.1576 51.2377L23.5781 42.0772L53.2528 24.8687L59.8331 34.0292L30.1576 51.2377Z" fill="#E4EDFB"/>
        <path d="M58.2891 53.6116L62.2096 51.5324C62.333 51.4668 62.4612 51.402 62.5934 51.3381C62.7192 51.2749 62.8409 51.2118 62.9594 51.1494L59.0388 53.2286C58.9211 53.291 58.7986 53.3541 58.6728 53.4173C58.5406 53.4812 58.4132 53.546 58.2891 53.6116Z" fill="#E14761"/>
        <path d="M55.6609 44.8097L59.5815 42.7305C58.9543 43.0657 58.6321 43.8276 58.6159 45.0161L54.6953 47.0954C54.7115 45.9068 55.0337 45.1449 55.6609 44.8097Z" fill="#E14761"/>
        <path d="M69.5535 46.9861C69.2038 45.8356 68.6829 44.7215 67.9908 43.6463C67.2987 42.5719 66.4581 41.58 65.4699 40.674C64.4808 39.768 63.3562 38.994 62.0929 38.3519C60.9229 37.7568 59.8445 37.4329 58.8579 37.3746C57.9143 37.3212 57.0923 37.4791 56.3905 37.8515L52.4707 39.9299C53.1725 39.5575 53.9953 39.3996 54.9381 39.453C55.9248 39.5113 57.0031 39.8352 58.1731 40.4303C59.4364 41.0724 60.561 41.8472 61.5501 42.7524C62.5383 43.6576 63.3789 44.6503 64.071 45.7247C64.7631 46.8007 65.2832 47.9148 65.6338 49.0645C65.9835 50.2183 66.1506 51.3599 66.136 52.4935C66.1263 53.2392 66.0589 53.8877 65.9348 54.4358C65.8098 54.9856 65.6086 55.4682 65.3335 55.8843C65.0569 56.3005 64.6925 56.6786 64.2414 57.0203C63.9031 57.2753 63.5104 57.5312 63.0657 57.7878C62.9578 57.8502 62.8467 57.9117 62.7331 57.9741L62.3793 58.1619C61.9395 58.3951 61.5882 58.6048 61.3229 58.7918C61.0137 59.0104 60.7817 59.2266 60.6251 59.4395C60.4693 59.6525 60.3671 59.8646 60.3176 60.0751C60.2665 60.2873 60.2413 60.5172 60.2381 60.7658L60.2275 61.5949L64.1481 59.5157L64.1587 58.6866C64.1619 58.438 64.1879 58.2081 64.2382 57.9959C64.2877 57.7854 64.3899 57.5725 64.5457 57.3603C64.7023 57.1474 64.9343 56.9312 65.2435 56.7126C65.4423 56.5717 65.6897 56.4187 65.9867 56.2527C66.024 56.2317 66.063 56.2106 66.1019 56.1888L66.5238 55.9645C67.1632 55.622 67.7101 55.2811 68.1612 54.9403C68.6123 54.5986 68.9766 54.2205 69.2533 53.8043C69.5292 53.3881 69.7296 52.9056 69.8546 52.3558C69.9787 51.8077 70.0461 51.1591 70.0558 50.4134C70.0704 49.2807 69.9032 48.1391 69.5535 46.9861Z" fill="#E14761"/>
        <path d="M58.1727 40.4303C59.436 41.0724 60.5606 41.8472 61.5497 42.7524C62.5379 43.6576 63.3785 44.6503 64.0706 45.7247C64.7628 46.8007 65.2828 47.9148 65.6334 49.0645C65.9831 50.2183 66.1502 51.3599 66.1356 52.4935C66.1259 53.2392 66.0585 53.8877 65.9344 54.4358C65.8094 54.9856 65.6082 55.4682 65.3332 55.8843C65.0565 56.3005 64.6922 56.6786 64.241 57.0203C63.7891 57.3612 63.243 57.702 62.6037 58.0445C62.0584 58.3247 61.6316 58.5732 61.3225 58.7918C61.0134 59.0104 60.7813 59.2266 60.6247 59.4396C60.4689 59.6525 60.3667 59.8646 60.3172 60.0751C60.2661 60.2873 60.2409 60.5172 60.2377 60.7658L60.2271 61.5949L55.5974 59.2404L55.6193 57.5822C55.6331 56.5313 55.8781 55.703 56.3585 55.0973C56.8372 54.4925 57.6096 53.933 58.6758 53.4173C59.5285 52.9882 60.1987 52.5777 60.6872 52.185C61.1748 51.7931 61.4239 51.1689 61.4353 50.3106C61.4491 49.2605 61.1732 48.2646 60.6093 47.3238C60.0446 46.3829 59.2129 45.6332 58.1143 45.0745C57.1082 44.5628 56.2911 44.4746 55.6631 44.809C55.0359 45.1442 54.7138 45.906 54.6976 47.0946L49.998 44.7053C50.0127 43.571 50.2317 42.6042 50.6536 41.8035C51.0748 41.0027 51.6484 40.3971 52.3721 39.9842C53.0959 39.5729 53.9511 39.3972 54.9394 39.4522C55.9244 39.5113 57.0027 39.8352 58.1727 40.4303Z" fill="#F196A5"/>
        <path d="M60.0791 70.8299C62.2435 70.8299 63.9981 69.079 63.9981 66.9192C63.9981 64.7594 62.2435 63.0085 60.0791 63.0085C57.9147 63.0085 56.1602 64.7594 56.1602 66.9192C56.1602 69.079 57.9147 70.8299 60.0791 70.8299Z" fill="#F196A5"/>
        <path d="M63.9981 66.9192C63.9981 67.9418 62.2439 69.8842 60.0791 69.8842C57.9144 69.8842 56.1602 68.2487 56.1602 66.9192C56.1602 64.7598 57.9144 63.0085 60.0791 63.0085C62.2439 63.0085 63.9981 64.7598 63.9981 66.9192Z" fill="#F196A5"/>
        <path d="M60.0775 63.0093C58.9733 63.0093 57.9761 63.4659 57.2637 64.1995C60.5928 63.3825 63.4578 66.1273 62.7389 69.7886C63.5121 69.0745 63.9957 68.0535 63.9957 66.9191C63.9965 64.7598 62.2415 63.0093 60.0775 63.0093Z" fill="#E14761"/>
        <path d="M89.7017 51.2378L89.6043 76.1219L59.9297 93.3305L60.0271 68.4463L89.7017 51.2378Z" fill="#E4EDFB"/>
        <path d="M60.0262 68.4463L59.9288 93.3305L30.0586 76.1219L30.156 51.2378L60.0262 68.4463Z" fill="#C0D6F6"/>
        <path d="M60.0271 68.4463L50.4796 67.8374L20.6094 50.6289L30.1569 51.2378L60.0271 68.4463Z" fill="#E4EDFB"/>
        <path d="M60.0273 68.4463L69.5741 67.8374L99.2495 50.6289L89.702 51.2378L60.0273 68.4463Z" fill="#E4EDFB"/>
        <path d="M60.8978 18.8685L60.8856 23.179C60.89 21.6665 59.8857 20.153 57.8759 18.9996C53.8796 16.7073 47.4223 16.7073 43.4514 18.9996C41.4793 20.1386 40.4927 21.6291 40.4883 23.1217L40.5005 18.8113C40.5049 17.3187 41.4915 15.8282 43.4636 14.6892C47.4334 12.3969 53.8918 12.3969 57.8881 14.6892C59.8979 15.8426 60.9022 17.3561 60.8978 18.8685Z" fill="#81ABEE"/>
        <path d="M60.8978 18.8687L60.8856 23.1791C60.8812 24.6717 59.8946 26.1621 57.9214 27.3011C53.9516 29.5935 47.4943 29.5935 43.4991 27.3011C41.4882 26.1478 40.4839 24.6332 40.4883 23.1207L40.5005 18.8103C40.4961 20.3227 41.5004 21.8363 43.5113 22.9907C47.5065 25.2831 53.9649 25.2831 57.9335 22.9907C59.9068 21.8517 60.8934 20.3613 60.8978 18.8687Z" fill="#8C8C8C"/>
        <path d="M60.8978 18.8687L60.8856 23.1791C60.8812 24.6717 59.8946 26.1621 57.9214 27.3011C53.9516 29.5935 47.4943 29.5935 43.4991 27.3011C41.4882 26.1478 40.4839 24.6332 40.4883 23.1207L40.5005 18.8103C40.4961 20.3227 41.5004 21.8363 43.5113 22.9907C47.5065 25.2831 53.9649 25.2831 57.9335 22.9907C59.9068 21.8517 60.8934 20.3613 60.8978 18.8687Z" fill="#8C8C8C"/>
        <path d="M60.8978 18.8687L60.8856 23.1791C60.8812 24.6717 59.8946 26.1621 57.9214 27.3011C53.9516 29.5935 47.4943 29.5935 43.4991 27.3011C41.4882 26.1478 40.4839 24.6332 40.4883 23.1207L40.5005 18.8103C40.4961 20.3227 41.5004 21.8363 43.5113 22.9907C47.5065 25.2831 53.9649 25.2831 57.9335 22.9907C59.9068 21.8517 60.8934 20.3613 60.8978 18.8687Z" fill="#8C8C8C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M60.6085 24.5251C63.2379 23.0061 64.6791 20.9869 64.6669 18.84C64.6547 16.6919 63.1903 14.6739 60.5442 13.1559C57.897 11.6369 54.384 10.8008 50.6537 10.8008C46.9234 10.8008 43.4204 11.6369 40.7909 13.1559C38.1625 14.6739 36.7203 16.693 36.7325 18.84C36.7436 20.9869 38.208 23.0061 40.8552 24.5251C43.5024 26.0431 47.0154 26.8791 50.7457 26.8791C54.476 26.8791 57.979 26.0431 60.6085 24.5251ZM57.9335 22.9907C53.9649 25.2831 47.5065 25.2831 43.5113 22.9907C39.5149 20.6984 39.4939 16.9817 43.4625 14.6894C47.4333 12.397 53.8906 12.397 57.8869 14.6894C61.8821 16.9806 61.9032 20.6984 57.9335 22.9907Z" fill="#E4EDFB"/>
        <path d="M64.6551 23.1493C64.6673 25.2962 63.2261 27.3154 60.5967 28.8344C57.9672 30.3524 54.4642 31.1885 50.7339 31.1885C47.0036 31.1885 43.4906 30.3524 40.8434 28.8344C38.1962 27.3154 36.7318 25.2962 36.7207 23.1493L36.7329 18.8389C36.7451 20.9858 38.2095 23.005 40.8556 24.524C43.5017 26.042 47.0147 26.8781 50.7461 26.8781C54.4775 26.8781 57.9805 26.042 60.6089 24.524C63.2383 23.005 64.6794 20.9858 64.6673 18.8389" fill="#C0D6F6"/>
        <path d="M40.0805 24.5042C39.6327 24.2476 39.2269 24.2233 38.9332 24.393L23.6973 33.1911C23.9899 33.0215 24.3968 33.0457 24.8446 33.3024C25.7348 33.8135 26.4542 35.0517 26.452 36.0684C26.4509 36.5729 26.2713 36.9287 25.982 37.0962L41.2179 28.298C41.5072 28.1306 41.6868 27.7759 41.6879 27.2702C41.6901 26.2535 40.9707 25.0142 40.0805 24.5042Z" fill="#E4EDFB"/>
        <path d="M25.9831 37.0927C26.6119 36.7296 26.6105 35.561 25.9798 34.4827C25.3491 33.4044 24.328 32.8247 23.6992 33.1879C23.0703 33.551 23.0718 34.7196 23.7025 35.7979C24.3332 36.8762 25.3542 37.4559 25.9831 37.0927Z" fill="#C0D6F6"/>
        </svg>
      `
    }
  }
}
</script>

<style lang="scss" scoped>
.empty {
  width: 100%;
  display: flex;
  justify-content: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: fit-content;
  }
}
</style>

import actions from './actions';
import mutations from './mutations';

export const InitState = () => ({
  timeList: [],
  updatingValidTime: false
});

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations
};

export default {
  rolePlatform(state) {
    const roles = new Map([
      ['SOCIAL_MEDIA.MODERATOR', 'MODERATOR']
    ]);
    return roles.get(state.user.roles.find(el => roles.get(el)));
  },
  roleName(state) {
    const { user } = state;
    const roles = new Map([
      ['SOCIAL_MEDIA.MODERATOR', 'Модератор']
    ]);
    return roles.get(user.roles.find(el => roles.get(el)));
  },
  fullName(state) {
    const { user } = state;
    return `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`.trim() || 'Не указано ФИО';
  },
  infoName(state, getters) {
    return getters.fullName || getters.roleName;
  }
};

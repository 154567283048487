<template>
  <section class="d-flex flex-direction-column delegation-form__actions">
    <div v-if="isDelegateAppeal" class="d-flex mb-6">
      <div class="delegation-form__select">
        <required-duration-component
          :readonly="isDisabledDatePicker"
          v-model="$v.model.dueDate.$model"
          :error="$v.model.dueDate.$error"
          :errorMessage="mesErr($v.model.dueDate)"
        />
      </div>
      <div class="delegation-form__select ml-6">
        <FormSelect
          label="Подразделение"
          :items="decodedDivisions"
          idValue="areaCode"
          titleValue="title"
          v-model="$v.model.areaCode.$model"
          @input="changeAreaCode"
          :callSearch="getDivisionList"
          isSearch
          :error="$v.model.areaCode.$error"
          :isLoading="divisionLoading"
          :errorMessage="mesErr($v.model.areaCode)"
        />
      </div>
    </div>

    <FormTextarea
      class="mb-6"
      :rows="5"
      :max-rows="5"
      label="Комментарий"
      v-model="$v.model.message.$model"
      :error="$v.model.message.$error"
      :errorMessage="mesErr($v.model.message)"
    />

    <FileUploader
      v-if="showDragUpload"
      class="mb-6"
      v-model="model.attachments"
      mode="edit"
    />
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import FileView from '@/components/FileView'
import { required, maxLength } from '@/plugins/vuelidate/customValidate'
import { mapActions, mapState } from 'vuex'
import { ACTIONS, DELEGATION_ACTIONS } from '@/constants'
import RequiredDurationComponent from '@/components/RequiredDurationComponent'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import FormTextarea from '@/components/FormTextarea'
import { debounce } from '@/plugins/lib';
import FileUploader from '@/components/FileUploader'

export default {
  name: 'DelegationForm',
  mixins: [validationMixin, validateMixin],
  components: {
    RequiredDurationComponent,
    FormInput,
    FormSelect,
    FormTextarea,
    FileUploader
  },
  props: {
    incident: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    divisionLoading: false,
    model: {
      areaCode: null,
      dueDate: null,
      message: '',
      attachments: []
    }
  }),
  validations () {
    return {
      model: {
        areaCode: {
          required: this.isDelegateAppeal ? required('Обязательное поле') : false
        },
        dueDate: {
          required: this.isDelegateAppeal ? required('Обязательное поле') : false
          // under: this.incident.extDueDate ? under(this.incident.extDueDate, 'Дата больше внешнего срока исполнения') : false
        },
        message: {
          required: this.isDelegateAppeal ? false : required('Обязательное поле'),
          maxLength: maxLength(4000, 'Максимум 4000 символов')
        }
      }
    }
  },
  mounted () {
    this.getDivisionList()
    this.model.dueDate = this.incident.dueDate
  },
  computed: {
    ...mapState('directory', ['divisions']),
    ...mapState('incident', ['executionType', 'appealActions']),
    ...mapState('session', ['user', 'areaCode']),
    showDragUpload () {
      return !['IG_COMMENT'].includes(this.incident.accountChannelCode)
    },
    isDelegateAppeal () {
      return this.executionType === ACTIONS.DELEGATE_APPEAL
    },
    isDisabledDatePicker () {
      return this.appealActions?.publication?.length > 1
        && this.incident.areaCode !== this.areaCode
    },
    decodedDivisions () {
      return this.divisions.filter(el => el.areaCode !== this.incident.currentAreaCode).map((division) => ({
        title: division.shortName || division.name,
        ...division
      }))
    }
  },
  methods: {
    ...mapActions('directory', ['getDivision']),
    selectFile (file) {
      this.$root.$modal.openModal(FileView, { file })
    },
    getDivisionList: debounce(async function (searching) {
      this.divisionLoading = true
      await this.getDivision({ directSubordination: true, searching })
      this.divisionLoading = false
      return this.decodedDivisions
    }, 1000),
  }
}
</script>

<style lang="scss" scoped>
.delegation-form {
  &__select {
    flex: 1
  }
}
</style>

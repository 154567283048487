<template>
  <not-found v-if="showNotFound" />
  <div
    v-else
    class="incident-view"
  >
    <div class="incident-view__wrapper">
      <h2
        v-if="!loading"
        class="camembert incident-view__content mb-4"
      >
        {{ title }}
      </h2>
      <div class="incident-view__story" />
    </div>
    <div class="incident-view__wrapper">
      <section class="incident-view__content">
        <div
          v-if="loading"
          class="mt-8 incident-view__loading-ring"
        >
          <r-spinner />
        </div>
        <div v-else>
          <div>
            <due-dates :incident="incident" class="mb-4" />
            <div class="d-flex align-items-center mb-4">
              <div class="d-flex align-items-center">
                <span class="tulum">{{ isShownDelegationAction
                  ? incident.accountChannelName
                  : (incident.delegationaccountChannelName || incident.accountChannelName) }}
                </span>
              </div>
              <div class="incident-view__source ml-6">
                <get-source-message
                  class="mr-2"
                  :incident="incident"
                  :source-url="historyMeta.source_url"
                  :source-type="historyMeta.source_type"
                />
              </div>
            </div>
          </div>
          <div v-if="isConsolidated" class="d-flex align-items-center mb-4">
            <div class="feta">
              Объединённое
            </div>
            <div class="d-flex align-items-center ml-6">
              <r-icon
                fill="rocky"
                icon="layers"
                size="16"
              />
              <div class="ml-2 feta">
                {{ consolidatedCounter }}
              </div>
            </div>
          </div>

          <BulbTags
            v-if="isShownKeywords"
            class="mb-6"
            :value="incident.tags"
          />

          <IncidentViewCard
            class="mb-6"
            :incident="incident"
            @goToIncident="goToIncident"
            @includeAttach="includeAttach"
          />

          <div v-if="isConsolidated" class="mb-6">
            <r-accordion 
              size="criamisa"
              color="rocky"
              title="Развернуть прикреплённые обращения"
            >
              <template #content>
                <incident-view-card
                  v-for="(incident, index) in incident.consolidatedAppeals"
                  :key="incident.id"
                  :class="{'mt-2': index == 0}"
                  :incident="incident"
                  :isConsolidated="isConsolidated"
                  @goToIncident="goToIncident"
                  @includeAttach="includeAttach"
                />
              </template>
            </r-accordion>
          </div>

          <div
            v-if="messages && messages.length"
            id="messaging"
            class="incident-view__messaging"
          >
            <message
              v-for="(message, index) in messages"
              :key="index"
              :message="message"
              :incident="incident"
              @includeAttach="includeAttach"
            />
          </div>

          <incident-forms
            ref="forms"
            @sending="setSending"
            :incident="incident"
            @change-type-code="changeAccountChannelCode"
          >
            <template #default="{ sendFormData, openConsolidateModal }">

              <div
                class="incident-view__unavailable mb-4"
                v-if="unavailable"
              >
                <p class="prestige--text bryndza mb-1">
                  Работа с обращением недоступна
                </p>
                <p class="mb-2 mozzarella anie">
                  Сейчас в «Медиалогии» обращение недоступно.
                  Возможно, оно было удалено, объединено с другим или были ограничены права доступа.
                </p>
                <p class="mozzarella anie">
                  Вы можете завершить работы по обращению или вернуться к нему позже.
                </p>
              </div>

              <div
                class="incident-view__unavailable mb-4"
                v-if="isAttached"
              >
                <p class="prestige--text bryndza mb-1">
                 Внимание!
                </p>
                <p class="mb-2 mozzarella anie">
                  Любое действие над этим обращением приведёт к его отсоединению
                </p>
              </div>

              <div
                v-if="executionType"
                class="incident-view__buttons mb-10"
              >
                <r-button
                  v-if="consolidateButtonTitle"
                  class="incident-view__buttons-send-button"
                  type="secondary"
                  @click="openConsolidateModal"
                  :isLoading="sending"
                  :title="consolidateButtonTitle"
                >
                </r-button>
                <r-button
                  v-if="isShownSendFormButton"
                  class="incident-view__buttons-send-button"
                  :class="{'ml-6': consolidateButtonTitle}"
                  @click="sendFormData"
                  :isLoading="sending"
                  :disabled="isDisabledSubmitButton"
                  :title="buttonTitle"
                >
                </r-button>
                <div
                  v-if="remainingActions && remainingActions.length"
                  class="incident-view__buttons-context ml-6"
                  v-inner-click-outside="closeActionsPopover"
                >
                  <r-button-simple
                    size="larishae"
                    type="secondary"
                    icon-size="20"
                    icon="menu-context"
                    @click="toggleActionsPopover"
                  />
                  <actions-list
                    class="incident-view__buttons-popover"
                    :show="showActionsPopover"
                    :actions="remainingActions"
                    @perform="perform"
                    @close="closeActionsPopover"
                  />
                </div>
              </div>
              <action-buttons
                v-else
                :incident="incident"
                :actions="remainingActions"
                @perform="perform"
              />
            </template>
          </incident-forms>
        </div>
      </section>

      <section class="incident-view__story">
        <div
          v-if="storyLoading"
          class="mt-8 incident-view__loading-ring"
        >
          <r-spinner />
        </div>
        <div v-else>
          <div v-if="isShownTabs">
            <r-tabs
              class="mb-6"
              v-model="activeTab"
              :items="tabs"
              type="rounded"
              title-value="value"
            />
            <pipeline
              v-if="activeTab.id === 'pipeline' && isShownPipeline"
              :incident="incident"
              :pipeline-data="pipelineData"
            />
            <history
              v-if="activeTab.id === 'history'"
              :history="history"
            />
            <Map
              v-if="activeTab.id === 'map' && isShownMap"
              v-model="incident.location"
              :editable="isSmartCity"
              @input="saveLocation"
              saveButton
            />
          </div>
          <div v-else-if="pipelineData && pipelineData.length && isShownPipeline">
            <h4 class="mb-6 burrata">
              Ход работ
            </h4>
            <pipeline
              :incident="incident"
              :pipeline-data="pipelineData"
            />
          </div>
          <div v-else-if="isShownMap">
            <h4 class="mb-6">Карта</h4>
            <Map
              v-model="incident.location"
              @input="saveLocation"
              :editable="isSmartCity"
              saveButton
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import IncidentForms from '@/views/incident/components/forms/IncidentForms'
import Message from '@/views/incident/components/Message'
import IncidentViewCard from '@/views/incident/components/IncidentViewCard'
import Pipeline from '@/views/incident/components/Pipeline'
import Map from '@/views/incident/components/Map'
import History from '@/views/incident/components/History'
import ActionButtons from '@/views/incident/components/ActionButtons'
import DeleteModal from '@/views/incident/modal/DeleteModal'
import ActionsList from '@/views/incident/components/ActionsList'
import NotFound from '@/components/NotFound'
import { ACTIONS, STATES } from '@/constants'
import getEnv from '@/plugins/env'
import ConsolidateIncidentsModal from '@/views/incident/modal/ConsolidateIncidentsModal'
import SeparateSubmitModal from '@/views/incident/modal/SeparateSubmitModal'
import SeparateIncidentsModal from '@/views/incident/modal/SeparateIncidentsModal'
import DueDates from '@/views/incident/components/DueDates'
import GetSourceMessage from '@/views/incident/components/GetSourceMessage'
import BulbTags from '@/views/incident/components/BulbTags'
import { numDeclination } from '@/plugins/lib'

export default {
  name: 'IncidentView',
  components: {
    Message,
    ActionButtons,
    Pipeline,
    History,
    ActionsList,
    NotFound,
    IncidentForms,
    Map,
    IncidentViewCard,
    DueDates,
    GetSourceMessage,
    BulbTags,
  },
  data() {
    return {
      pipelineData: [],
      history: [],
      historyMeta: {},
      showActionsPopover: false,
      loading: false,
      storyLoading: false,
      sending: false,
      activeTab: '',
      tabs: [],
      attachedFiles: [],
      showNotFound: false,
      accountChannelCode: 'publication'
    };
  },
  watch: {
    $route() {
      this.getData();
    }
  },
  computed: {
    ...mapState('incident', ['executionType', 'appealActions', 'messages', 'incident']),
    ...mapState('session', ['areaCode']),
    isMedialogy() {
      return ['MEDIALOGIA-IM'].includes(this.incident.systemCode);
    },
    consolidatedCounter() {
      return `${this.consolidatedCount} ${numDeclination(this.consolidatedCount, ['обращение', 'обращения', 'обращений'])}`;
    },
    consolidatedCount() {
      return this.incident?.consolidatedAppeals?.length + 1;
    },
    title() {
      const titles = {
        [ACTIONS.EXECUTE_APPEAL]: `Запуск в работу обращения № ${this.incident?.number}`,
        [ACTIONS.EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER]: `Запуск в работу обращения №
         ${this.incident?.number} с промежуточным ответом`,
        [ACTIONS.WAIT_FOR_ANSWER]: `Дозапрос по обращению № ${this.incident?.number}`,
        [ACTIONS.COMPLETE_APPEAL]: `Быстрый ответ по обращению № ${this.incident?.number}`,
        [ACTIONS.WAIT_FOR_APPEAL_ACTIVATION]: `Отсрочка по обращению № ${this.incident?.number}`,
        [ACTIONS.DELEGATE_APPEAL]: `Делегирование обращения № ${this.incident?.number}`,
        [ACTIONS.DECLINE_DELEGATED_APPEAL]: `Отклонение обращения № ${this.incident?.number}`,
        [ACTIONS.REPLY_DELEGATED_APPEAL]: `Ответ по обращению № ${this.incident?.number}`,
        [ACTIONS.COMPLETE_WITHOUT_RESPONSE]: `Завершение обращения № ${this.incident?.number}`
      };
      return titles[this.executionType] || `Обращение № ${this.incident?.number}`;
    },
    buttonTitle() {
      const titles = {
        [ACTIONS.WAIT_FOR_ANSWER]: 'Дозапросить',
        [ACTIONS.WAIT_FOR_APPEAL_ACTIVATION]: 'Отложить',
        [ACTIONS.COMPLETE_WITHOUT_RESPONSE]: 'Завершить'
      };
      return titles[this.executionType] || 'Отправить';
    },
    consolidateButtonTitle() {
      const titles = {
        [ACTIONS.COMPLETE_APPEAL]: 'Выбрать получателей',
        [ACTIONS.COMPLETE_WITHOUT_RESPONSE]: 'Выбрать обращения для завершения'
      };
      return (this.isConsolidated && titles[this.executionType]) || null;
    },
    unavailable() {
      return this.appealActions?.publication?.includes(ACTIONS.MOVE_TO_RESOLVED);
    },
    isShownDelegationAction() {
      return !!this.incident.delegationAction && this.incident.areaCode === this.areaCode;
    },
    isShownPipeline() {
      return !!+getEnv('VUE_APP_SHOW_WORK_PROGRESS');
    },
    isShownMap() {
      return !!+getEnv('VUE_APP_SHOW_MAP') || this.isSmartCity
    },
    isSmartCity() {
      return this.incident.systemCode === 'SMART-CITY'
    },
    isShownKeywords() {
      return !!+getEnv('VUE_APP_SHOW_TAGS');
    },
    isShownTabs() {
      return this.tabs?.length > 1;
    },
    inProgress() {
      return this.$route.meta.type !== STATES.REMOVED
        && this.$route.meta.type !== STATES.SOLVED
        && this.$route.meta.type !== STATES.ERROR;
    },
    remainingActions() {
      return this.appealActions?.publication?.filter(action => action !== this.executionType);
    },
    isActionExist() {
      return !!this.appealActions[this.accountChannelCode.toLowerCase()].includes(this.executionType);
    },
    isDisabledSubmitButton() {
      return this.sending || !this.isActionExist;
    },
    isConsolidated () {
      return this.incident?.consolidatedAppeals?.length
    },
    isAttached () {
      return !!this.incident.consolidatedToAppealId
    },
    isShownSendFormButton() {
      return !(this.executionType === ACTIONS.COMPLETE_APPEAL && this.isConsolidated);
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapMutations('incident', ['setFilterProperty', 'setExecutionType']),
    ...mapActions('incident', [
      'getIncident',
      'getIncidents',
      'getIncidentMessaging',
      'moveIncident',
      'getWorkProgress',
      'getAdditional',
      'getIncidentActions',
      'addNote',
      'changeIncident'
    ]),
    async getData() {
      const { id, moreId } = this.$route.params;
      await this.getAppealData(moreId ? moreId : id);
      this.getStoryData(moreId ? moreId : id);
    },
    async getAppealData(id) {
      this.loading = true;
      this.storyLoading = true;
      await Promise.all([
        this.getIncidentById(id),
        this.getIncidentMessaging({ id }),
        this.getActions(id)
      ])
        .catch(() => { this.showNotFound = true; })
        .finally(() => {
          this.loading = false;
        });
      const messaging = document.getElementById('messaging');
      if (!messaging) return;
      messaging.scrollTo(0, messaging.scrollHeight);
    },
    async getStoryData (id) {
      this.tabs = []
      await this.getPipelineData(id)
      await this.getHistory(id)
      if (this.isShownMap) {
        this.tabs.push({ id: 'map', value: 'Карта' })
      }
      this.storyLoading = false
      if (this.tabs?.length) {
        this.isMedialogy
          ? this.changeTab({ id: 'history', value: 'История' })
          : this.changeTab({ id: 'pipeline', value: 'Ход работ' });
      }
    },
    async getIncidentById(id) {
      await this.getIncident({ id });
      if (!this.incident.consolidatedToAppealId) {
        if (this.incident.category !== this.$route.meta.type) {
          this.showNotFound = true;
        }
      }
    },
    async getPipelineData(id) {
      this.pipelineData = [];
      const stages = await this.getWorkProgress({ id });
      for (const stage of stages) {
        this.pipelineData.push({ id: this.$nanoid(), ...stage });
      }
      if (stages?.length && this.isShownPipeline) {
        this.tabs.push({ id: 'pipeline', value: 'Ход работ' });
      }
    },
    async getHistory(id) {
      this.history = [];
      if (this.isMedialogy) {
        await this.getAdditional({ id })
          .then(res => {
            this.historyMeta = res?.meta;
            for (const stage of res?.history) {
              this.history.push({ id: this.$nanoid(), ...stage });
            }
            if (res?.history?.length) {
              this.tabs.push({ id: 'history', value: 'История' });
            }
          })
          .catch(() => {
            this.$rir.notification.send({
              title: 'История обращения недоступна',
              seconds: 5
            })
          });
      }
    },
    async getActions(id) {
      if (this.$route.meta.type !== STATES.SOLVED) {
        await this.getIncidentActions({ id });
        if (this.isMedialogy && this.inProgress && this.appealActions?.publication) this.appealActions?.publication.push(ACTIONS.ADD_NOTE);
      }
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    toggleActionsPopover() {
      this.showActionsPopover = !this.showActionsPopover;
    },
    closeActionsPopover() {
      this.showActionsPopover = false;
    },
    perform(type) {
      this.setExecutionType(type);
      switch (type) {
        case ACTIONS.IGNORE_APPEAL:
          this.$rir.modal.open(DeleteModal, { incidents: [this.incident] }, {
            isClose: false,
          });
          break;
        case ACTIONS.CONSOLIDATE:
          this.$rir.modal.open(ConsolidateIncidentsModal, { incident: this.incident }, {
            isClose: false,
            isFixedActionsBlock: true,
          });
          break;
        case ACTIONS.SEPARATE:
          this.isConsolidated
            ? this.$rir.modal.open(SeparateIncidentsModal, { mainIncident: this.incident }, {
              isClose: false,
              isFixedActionsBlock: true,
            })
            : this.$rir.modal.open(SeparateSubmitModal, {
              mainIncidentId: this.incident?.consolidatedAppeals?.length
                ? this.incident.id
                : this.incident.consolidatedToAppealId,
              selectedIncidents: [this.incident]
            }, {
              isClose: false,
            });
          break;
        default:
          setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
          }, 150);
          break;
      }
      this.closeActionsPopover();
    },
    goToIncident(route) {
      this.$router.push(route);
      this.getData();
    },
    includeAttach(file) {
      this.$refs.forms.includeAttach(file);
    },
    setSending(sending) {
      this.sending = sending;
    },
    changeAccountChannelCode (type) {
      this.accountChannelCode = type
    },
    saveLocation () {
      this.changeIncident(this.incident)
    }
  }
};
</script>

<style lang="scss" scoped>
.incident-view{
  padding-top: 62px !important;
  padding: 0 32px;

  &__wrapper {
    display: flex;
  }

  &__messaging {
    max-height: 400px;
    overflow: scroll;
    padding: 16px 16px 0 16px;
    background-color: var(--rir-django);
    border-radius: 8px;
    margin-bottom: 24px;

    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }
    &::-webkit-scrollbar-corner {
      background: rgba(0,0,0,0);
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      box-shadow: inset 0 0 0 10px;
      border-radius: 9999px;
      background: none;
    }
  }

  &__content {
    flex: 5;
    min-width: 30%;
    overflow-wrap: break-word;
  }

  &__story {
    flex: 1;
    min-width: 30%;
    margin-left: 24px;
    padding-bottom: 32px;
    overflow-wrap: break-word;
  }

  &__loading-ring {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__unavailable {
    padding: 12px 20px;
    background-color: #fef8f9;
    border: 1px solid var(--rir-romance);
    border-radius: 16px;
  }

  &__tag {
    width: 74px;
    background-color: #E9A35D;
    border-radius: 16px;
    padding: 4px 12px;
    margin-right: 12px;
  }

  &__buttons {
    display: flex;

    &-send-button {
      flex: 1
    }

    &-context {
      position: relative;
    }

    &-popover {
      position: absolute;
      bottom: 48px;
      left: 0;
      z-index: 90;
    }
  }
}
</style>

<template>
  <r-popover
    class="multiselect-tags"
    position="left-bottom"
    @close="closePopover"
    @input="openPopover"
    :contentWidthActivator="!isSlotActivator"
  >
    <template #activator="{ on }">
      <slot
        v-if="isSlotActivator"
        name="activator"
        :on="on"
      ></slot>
      <div
        v-else
        v-on="on"
        class="multiselect-tags__input"
      >
        <r-input
          :after-icon="isOpen ? 'arrow-up' : 'arrow-down'"
          :value="selectedTags"
          label="Тег"
          @click.prevent.stop="togglePopover"
          :isClear="false"
          readonly
        />
      </div>
    </template>
    
    <template #content>
      <div class="px-1">
        <div
          class="px-3 pt-5 pb-4">
          <r-input
            before-icon="search"
            v-model="query"
            label="Поиск"
          />
        </div>
        <div class="px-3 multiselect-tags__list">
          <div v-if="isLoading" class="multiselect-tags__loading-ring">
            <r-spinner/>
          </div>
          <div v-else>
            <r-checkbox
              v-for="item in tagsList"
              :key="item.id"
              v-model="value"
              @input="changeTagsList"
              :val="item.name"
              :sub-title="item.tagCategory.name"
              class="mb-4"
            >
              <template #title>
                <div
                  class="multiselect-tags__tag amelie--text mb-1"
                  :style="`background-color: ${item.color}`"
                >
                  {{ item.name }}
                </div>
              </template>
            </r-checkbox>
          </div>
        </div>
      </div>
    </template>
  </r-popover>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { debounce } from '@/plugins/lib';

export default {
  name: 'MultiselectTags',
  props: {
    value: {
      type: Array,
      default: () => ([]),
      require: true
    }
  },
  data: () => ({
    isFullView: false,
    countHidden: 0,
    query: null,
    isOpen: false,
    isLoading: false
  }),
  watch: {
    query (val) {
      this.callSearch(val)
    },
  },
  computed: {
    ...mapState('directory', { tagsList: 'tags' }),
    isSlotActivator () {
      return !!this.$scopedSlots.activator
    },
    selectedTags () {
      return this.value.join(', ')
    }
  },
  methods: {
    ...mapActions('directory', ['getTagsList']),
    callSearch: debounce(async function (search) {
      await this.getTags(search)
    }, 1000),
    changeTagsList (tags) {
      this.$emit('input', tags)
    },
    openPopover () {
      this.isOpen = !this.isOpen
      const list = document.getElementsByClassName('RPopover__content')
      for (let popover of list) popover.remove();
      if (this.isOpen) {
        this.query = null
        this.getTags(this.query)
      }
    },
    async getTags (search) {
      this.isLoading = true
      await this.getTagsList({ search })
      this.isLoading = false
    },
    togglePopover () {
      this.isOpen = !this.isOpen
      this.$emit('changePopover', this.isOpen)
    },
    closePopover () {
      this.isOpen = false
      this.$emit('changePopover', this.isOpen)
    }
  },
};
</script>

<style lang="scss">

  .hidden {
    display: none !important;
  }

 .multiselect-tags {
  &__input {
    width: 100%;
    cursor: pointer !important;
    user-select: auto !important;
    pointer-events: auto !important;
    pointer-events: auto !important;
    & > .readonly {
      opacity: 1 !important;
    }

    &:hover {
      box-shadow: none;
      background-color: var(--rir-djangoHover);
      border-radius: 8px;
    }
    & > label {
      opacity: 1 !important;
    }
  }

  &__activator{
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__loading-ring {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__list {
    max-height: 436px;
    overflow: scroll;
    color: rgba(#CDD3DF, 0.48) !important;

    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }
    &::-webkit-scrollbar-corner {
      background: rgba(0,0,0,0);
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      box-shadow: inset 0 0 0 10px;
      border-radius: 9999px;
      background: none;
    }
  }

  &__tag {
    display: inline-block;
    width: fit-content;
    border-radius: 16px;
    padding: 4px 12px;
    margin-right: 12px;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 16px !important;

    &-category {
      font-size: 10px !important;
      font-weight: 400 !important;
      line-height: 12px !important;
    }
  }
}
</style>

<template>
  <section class="information-block">
    <div class="d-flex flex-direction-column information-block__info">
      <div v-if="isShownDelegationAction" class="d-flex align-items-center">
        <r-tooltip
          activator-hover
          color="amelie"
          :title="getTitle"
        >
          <template v-slot:activator>
            <r-icon
              :icon="getIcon"
              :fill="getFill"
              size="16"
            />
          </template>
        </r-tooltip>
        <span class="bryndza ml-2 mb-1">{{ incident.accountChannelName }}</span>
      </div>
      <span v-else class="bryndza mb-1">{{ title }}</span>
      <span class="mozzarella anie mb-1">№ {{ incident.number }}</span>
      <!-- <span class="mb-1 metioche anie">{{ incident.createdAt | dayMonthHours }}</span> -->
      <div class="d-flex align-items-center">
        <MultiselectTags
          v-if="isShownKeywords"
          :value="selectedTags"
          @input="changeSelectedTags"
          @click.native.stop
          :class="{'disabled': isSelectionMode}"
        >
          <template v-slot:activator="{ on }">
            <div
              @click="on.click"
              class="rocky--text briscola information-block__tag-activator"
              :class="{'ekas': !tagsLength}"
            >
              {{ selectedTagsString }}
            </div>
          </template>
        </MultiselectTags>
        <span v-if="incident.systemCode === 'FRONT'" class="ekas mozzarella ml-2">
          Создано вручную
        </span>
      </div>
      <div class="information-block__source">
        <div v-if="incident.consolidatedCount" class="d-flex align-items-center">
          <r-icon icon="layers" :size="16" />
          <p class="ml-2 feta">
            {{ consolidatedCounter }}
          </p>
        </div>
        <get-source-message
          v-else
          is-tooltip
          :incident="incident"
        />
        <!-- <span class="information-block__url" v-if="!!incident.url">{{incident.url}}</span> -->
      </div>
    </div>
    

    <div ref="infoBlock" class="d-flex flex-direction-column flex-auto">
      <r-tooltip
        activator-hover
        color="amelie"
        :title="authorName"
        maxWidth="800px"
        :disabled="isDisabledTooltip"
      >
        <template v-slot:activator>
          <div class="clamp-1 d-flex align-items-center mb-2">
            <span
              v-if="!incident.isRead" class="information-block__dot"
            />
            <span
              ref="authorName"
              :class="!incident.isRead ? 'bryndza' : 'sulguni ekas'"
              style="min-height: 20px"
            >
              {{ authorName }}
            </span>
          </div>
        </template>
      </r-tooltip>
      <due-dates :incident="incident" />
      <div class="parmigiano ekas information-block__desc" v-html="getText(incident.text)" />
    </div>
  </section>
</template>

<script>
import GetSourceMessage from '@/views/incident/components/GetSourceMessage'
import DueDates from '@/views/incident/components/DueDates'
import { mapActions, mapState } from 'vuex'
import Tooltip from '@/components/Tooltip'
import { DELEGATION_ACTIONS } from '@/constants'
import { numDeclination } from '@/plugins/lib'
import MultiselectTags from '@/components/MultiselectTags'
import getEnv from '@/plugins/env'

export default {
  name: 'information-block-incident',
  components: { GetSourceMessage, Tooltip, DueDates, MultiselectTags },
  props: {
    incident: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedTags: [],
      isDisabledTooltip: false
    }
  },
  computed: {
    ...mapState('session', ['areaCode']),
    ...mapState('incident', ['isSelectionMode']),
    tagsLength () {
      return this.selectedTags?.length
    },
    isShownDelegationAction () {
      return !!this.incident.delegationAction && this.incident.areaCode === this.areaCode
    },
    title () {
      return this.incident.consolidatedCount
        ? 'Объединённое'
        : this.incident.delegationAccountChannelName || this.incident.accountChannelName
    },
    consolidatedCounter () {
      return `${this.consolidatedCount} ${numDeclination(this.consolidatedCount, ['обращение', 'обращения', 'обращений'])}`
    },
    consolidatedCount () {
      return this.incident.consolidatedCount + 1
    },
    authorName () {
      return this.incident.consolidatedAuthorCount > 1
        ? `${this.incident.authorName} и еще ${this.incident.consolidatedAuthorCount - 1}`
        : this.incident.authorName
    },
    getIcon () {
      const icons = {
        [DELEGATION_ACTIONS.DELEGATE_APPEAL]: 'reply',
        [DELEGATION_ACTIONS.REPLY_DELEGATED_APPEAL]: 'send',
        [DELEGATION_ACTIONS.DECLINE_DELEGATED_APPEAL]: 'block'
      }
      return icons[this.incident.delegationAction] || null
    },
    getTitle () {
      const titles = {
        [DELEGATION_ACTIONS.DELEGATE_APPEAL]: 'Делегированное',
        [DELEGATION_ACTIONS.REPLY_DELEGATED_APPEAL]: 'Получен ответ',
        [DELEGATION_ACTIONS.DECLINE_DELEGATED_APPEAL]: 'Отклоненное'
      }
      return titles[this.incident.delegationAction] ? `${titles[this.incident.delegationAction]} ${this.incident.delegatingDivisionName}` : null
    },
    getFill () {
      return this.incident.delegationAction === DELEGATION_ACTIONS.DECLINE_DELEGATED_APPEAL
        ? 'lebowski'
        : 'rocky'
    },
    selectedTagsString () {
      return this.selectedTags?.length
        ? `${this.selectedTags.length} ${numDeclination(this.selectedTags.length, ['метка', 'метки', 'меток'])}`
        : 'Нет меток'
    },
    isShownKeywords() {
      return !!+getEnv('VUE_APP_SHOW_TAGS');
    },
  },
  mounted () {
    this.selectedTags = this.incident?.tags || []
    this.$nextTick(() => {
      const infoBlock = this.$refs.infoBlock
      const authorName = this.$refs.authorName
      this.isDisabledTooltip = authorName?.getBoundingClientRect()?.width + 16 <= infoBlock?.clientWidth
    })
  },
  methods: {
    ...mapActions('incident', ['addTags']),
    getText (text) {
      return text?.replace(/<(?!br\s*\/?)[^>]+>/g, '')
    },
    changeSelectedTags (tags) {
      this.selectedTags = tags
      this.addTags({
        id: this.incident.id,
        data: this.selectedTags || []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.information-block{
  display: flex;

  &__url {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-right: 15px;
  }

  &__info{
    overflow: hidden;
  }

  &__tag-activator {
    cursor: pointer;
    transition: opacity .2s;

    &:hover {
      opacity: 0.72;
    }
  }

  &__desc {
    overflow: hidden;
    height: 48px;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
  }

  &__source {
    margin-top: auto;
  }

  &__dot {
    width: 8px;
    height: 8px;
    background-color: var(--rir-rocky);
    display: inline-block;
    border-radius: 100%;
    margin-right: 8px;

    &-important {
      background-color: var(--rir-lebowski);
    }
  }
}

.disabled {
  opacity: 0.32;
  cursor: auto;
  pointer-events: none;
}
</style>

export default {
  changeActionbar(state, payload) {
    state.actionbar = payload;
  },
  toggleSidebar(state) {
    state.isSidebarOpen = !state.isSidebarOpen;
  },
  setScroll(state, payload) {
    state.scroll = payload;
  },
  setAnchor(state, payload) {
    state.anchor = payload;
  },
  setRoute(state, route) {
    state.route = route;
  },
  setLastRoute(state, route) {
    state.lastRoute = route;
  }
};

import IncidentListToolbar from '@/views/incident/toolbars/IncidentListToolbar';
import IncidentViewToolbar from '@/views/incident/toolbars/IncidentViewToolbar';
import IncidentView from '@/views/incident/IncidentView';
import IncidentList from '@/views/incident/IncidentList';
import DefaultLayout from '@/layout/DefaultLayout';
import { STATES } from '@/constants';

export const incidentNewRoutes = [
  {
    path: '/incident-new',
    name: 'incident-new',
    components: {
      default: IncidentList,
      toolbar: IncidentListToolbar
    },
    meta: {
      type: STATES.NEW,
      sortBy: 'UPDATED_AT',
      title: 'Новые обращения',
      layout: DefaultLayout
    }
  },
  {
    path: '/incident-new/:id',
    name: 'incident-new-view',
    components: {
      default: IncidentView,
      toolbar: IncidentViewToolbar
    },
    meta: {
      layout: DefaultLayout,
      type: STATES.NEW
    }
  },
  {
    path: '/incident-new/:id/more/:moreId?',
    name: 'incident-new-view-more',
    components: {
      default: IncidentView,
      toolbar: IncidentViewToolbar
    },
    meta: {
      layout: DefaultLayout,
      type: STATES.NEW
    }
  }
];

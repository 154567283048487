<template>
  <div
    class="complete-incidents-modal"
    id="modal"
  >
    <div class="complete-incidents-modal__wrapper">
      <div class="complete-incidents-modal__header">
        <div class="complete-incidents-modal__title">
          <h2 class="camembert mb-4 titanic--text">
            Выборочный ответ
          </h2>
          <p class="mb-4 parmigiano">
            Выберите обращения, на которые следует ответить,
            {{ selectedIncidents ? `(выбрано ${selectedIncidents.length})` : '' }}
          </p>
          <div class="d-flex align-items-center">
            <r-input
              style="flex: 1"
              v-model="searching"
              @input="debounceInput"
              label="Поиск"
            />
            <div class="ml-6">
              <r-checkbox
                title="Выбрать все"
                :value="isSelectAll"
                @input="selectAll"
              />
            </div>
          </div>
          
        </div>
      </div>

      <section class="mb-6">
        <div class="d-flex align-items-center mb-4">
          <p class="feta">Главное обращение</p>
          <div
            @mouseenter="toggleTooltip"
            @mouseleave="closeTooltip"
            class="complete-incidents-modal__tooltip-activator"
          >
            <r-icon
              class="ml-2"
              fill="rocky"
              icon="info"
              size="16"
            />
            <transition name="fade">
              <div
                v-if="isShowTooltip"
                class="complete-incidents-modal__tooltip"
              >
                <div class="bryndza titanic--text mb-2">
                  Это обращение — главное
                </div>
                <div v-if="isMainDisabled" class="mozzarella fargo--text">
                  Дать ответ на главное обращение невозможно, т.к. в объединении есть созданные вручную обращения
                </div>
                <div v-else>
                  <div class="mozzarella titanic--text mb-2">
                    Ответ на главное обращение будет отправлен и по остальным обращениям, входящим в объединённое.
                  </div>
                  <div class="mozzarella titanic--text">
                    Если выделить главное, то все остальные обращения тоже выделятся.
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <incident-item
          :incident="mainIncident"
          :is-shown-buttons="false"
          @select="selectIncident"
          :disabled="isMainDisabled"
        >
          <template>
            <div
              v-if="mainIncident.accSources && mainIncident.accSources.length"
              class="complete-incidents-modal__actions"
            >
              <FormSelect
                class="complete-incidents-modal__select"
                label="Аккаунт для ответа"
                :items="mainIncident.accSources"
                idValue="id"
                titleValue="description"
                :disabled="mainIncident.accSources.length < 2"
                v-model="mainIncident.publisherId"
              />
              <FormSelect
                v-if="['MEDIALOGIA-IM'].includes(mainIncident.systemCode)"
                class="complete-incidents-modal__select mt-5"
                label="Тип ответа"
                :items="responseTypes"
                idValue="id"
                titleValue="title"
                v-model="mainIncident.accountChannelCode"
              />
            </div>
          </template>
        </incident-item>
      </section>

      <section class="mb-6">
        <div class="d-flex align-items-center mb-4">
          <p class="feta">Прикреплённые обращения</p>
        </div>
        <div
          class="mt-4"
          v-if="innerConsolidatedAppeals.length"
        >
          <div
            v-for="inc in innerConsolidatedAppeals"
            :key="inc.id"
            class="complete-incidents-modal__incidents-wrapper"
          >
            <incident-item
              :incident="inc"
              :is-shown-buttons="false"
              @select="selectIncident"
            >
              <template>
                <div
                  v-if="inc.accSources && inc.accSources.length"
                  class="complete-incidents-modal__actions"
                >
                  <FormSelect
                    class="complete-incidents-modal__select"
                    label="Аккаунт для ответа"
                    :items="inc.accSources"
                    idValue="id"
                    titleValue="description"
                    :disabled="inc.accSources.length < 2"
                    v-model="inc.publisherId"
                  />
                  <FormSelect
                    v-if="['MEDIALOGIA-IM'].includes(inc.systemCode)"
                    class="complete-incidents-modal__select mt-5"
                    label="Тип ответа"
                    :items="responseTypes"
                    idValue="id"
                    titleValue="title"
                    v-model="inc.accountChannelCode"
                  />
                </div>
              </template>
            </incident-item>
          </div>
        </div>
      </section>

      <div
        v-if="isLoading"
        class="complete-incidents-modal__loading-ring"
      >
        <r-spinner/>
      </div>
      <empty
        v-else-if="!innerConsolidatedAppeals.length"
        class="mt-8"
        title="К сожалению, ничего не найдено"
      />


      <div id="RModal--actions" class="complete-incidents-modal__footer">
        <div class="complete-incidents-modal__buttons">
          <r-button
            class="complete-incidents-modal__button"
            @click="$rir.modal.close()"
            color="secondary"
            title="Отменить"
          >
          </r-button>
          <r-button
            class="complete-incidents-modal__button ml-8"
            @click="completeAppeal"
            :isLoading="sending"
            :disabled="isButtonDisabled"
            title="Отправить выбранным"
          >
          </r-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce, goBack } from '@/plugins/lib';
import IncidentItem from '@/views/incident/components/IncidentItem';
import { required } from '@/plugins/vuelidate/customValidate';
import { validationMixin } from 'vuelidate';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import Empty from '@/components/Empty';
import InfoModal from '@/views/incident/modal/InfoModal';
import { ACTIONS, ResponseTypes } from '@/constants';
import FormSelect from '@/components/FormSelect'

export default {
  name: 'CompleteIncidentsModal',
  components: {
    Empty,
    IncidentItem,
    FormSelect,
  },
  mixins: [validationMixin, validateMixin],
  props: {
    mainIncident: {
      type: Object,
      default: () => ({}),
      require: true
    },
    model: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      searching: null,
      innerConsolidatedAppeals: [],
      filteredIncidents: [],
      isLoading: false,
      sending: false,
      responseTypes: ResponseTypes,
      isShowTooltip: false,
      isSelectAll: false
    };
  },
  validations: {
    model: {
      feedback: {
        required: required('Обязательное поле')
      }
    }
  },
  computed: {
    ...mapState('incident', ['filters', 'incidents', 'isSelectionMode', 'selectedIncidents']),
    ...mapState('session', ['user']),
    isButtonDisabled () {
      return !this.selectedIncidents.length || this.sending
    },
    isMainDisabled () {
      return this.mainIncident?.consolidatedAppeals.find(incident => incident.systemCode === 'FRONT')
    }
  },
  async mounted() {
    await this.getFilteredIncidents();
    this.innerConsolidatedAppeals = [...this.filteredIncidents];
    this.setSelectionMode(true);
  },
  beforeDestroy() {
    this.setSelectionMode(false);
  },
  methods: {
    ...mapMutations('incident', ['setProperty', 'setFilterProperty', 'setSelectionMode', 'clearFilterProperties', 'setSelectedIncident', 'setSelectedIncidents']),
    ...mapActions('incident', ['getIncidents', 'consolidateIncidents', 'moveIncident', 'getPublishers']),
    async getFilteredIncidents() {
      this.isLoading = true;
      this.mainIncident = await this.setAdditionalFields(this.mainIncident)
      this.filteredIncidents = await Promise.all(
        this.mainIncident?.consolidatedAppeals
          .filter((incident) => incident.systemCode !== 'FRONT')
          .map(async incident => await this.setAdditionalFields(incident))
      );
      this.isLoading = false;
    },
    async setAdditionalFields (incident) {
      let accSources = null
      if (['TELEGRAM-BOT', 'VK', 'OK', 'MEDIALOGIA-IM', 'SMART-CITY'].includes(incident.systemCode)) {
        accSources = await this.getPublishers(incident);
      }
      return {
        ...incident,
        publisherId: accSources?.length ? accSources[0].id : null,
        typeCode: this.responseTypes[0].id || null,
        accSources: accSources || []
      };
    },
    selectIncident(incident) {
      const mainIncident = this.selectedIncidents.find(incident => incident.data.id === this.mainIncident.id);
      const innerIncidents = [this.mainIncident, ...this.filteredIncidents]
      if (incident.id === this.mainIncident.id) {
        const preparedIncidents = innerIncidents.map(incident => ({
          data: incident,
          actions: []
        }));
        if (mainIncident) {
          this.setSelectedIncidents(preparedIncidents);
        } else {
          const index = preparedIncidents?.findIndex(incident => incident.data.id === this.mainIncident.id);
          preparedIncidents.splice(index, 1);
          this.setSelectedIncidents(preparedIncidents);
        }
      } else {
        if (!mainIncident) return;
        this.setSelectedIncident({
          data: this.mainIncident,
          actions: []
        });
      }
      this.setIsSelectAll()
    },
    setIsSelectAll () {
      const incidentsLength = this.isMainDisabled
        ? this.filteredIncidents.length
        : [this.mainIncident, ...this.filteredIncidents].length
      this.isSelectAll = this.selectedIncidents.length === incidentsLength
    },
    selectAll () {
      if (this.isSelectAll) {
        this.setSelectedIncidents([]);
        this.isSelectAll = false
      } else {
        const incidents = this.isMainDisabled ? this.filteredIncidents : [this.mainIncident, ...this.filteredIncidents];
        const preparedIncidents = incidents.map(incident => ({
          data: incident,
          actions: []
        }));
        this.setSelectedIncidents(preparedIncidents);
        this.isSelectAll = true
      }
    },
    toggleTooltip() {
      this.isShowTooltip = !this.isShowTooltip;
    },
    closeTooltip() {
      this.isShowTooltip = false;
    },
    debounceInput: debounce(function () {
      this.isLoading = true;
      if (this.searching) {
        this.innerConsolidatedAppeals = this.filteredIncidents.filter(incident => incident.number.match(new RegExp(this.searching)) || incident.text.match(new RegExp(this.searching)) || incident.authorName.match(new RegExp(this.searching)));
      } else {
        this.innerConsolidatedAppeals = [...this.filteredIncidents];
      }
      this.isLoading = false;
    }, 1000),
    async completeAppeal() {
      this.$v.$touch();
      if (this.$v.$invalid || this.sending) return;
      const payload = this.selectedIncidents.map(incident => ({
        id: incident.data.id,
        statusCode: incident.data.action,
        moderatorId: this.user.id,
        feedback: {
          ...this.model.feedback,
          publisherId: incident.data.publisherId || null,
          typeCode: incident.data.typeCode || null
        },
        decision: ACTIONS.COMPLETE_APPEAL
      }));
      this.sending = true;
      await this.moveIncident(payload)
        .then(async () => {
          goBack();
          this.$root.$modal.closeModal()
          this.$rir.notification.send({
            title: 'Ответ успешно отправлен',
            seconds: 5
          });
        })
        .catch(() => this.errorModal())
        .finally(() => this.setSelectionMode(false));
      this.sending = false;
    },
    errorModal() {
      this.$root.$modal.openModal(InfoModal, {
        icon: 'close',
        fill: 'fargo',
        title: 'Ответ на обращение не отправлен',
        subtitle: `Произошла внутренняя ошибка сервера во время отправки.
        Пожалуйста, повторите отправку. Если проблема повторится, то обратитесь в службу поддержки.`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.complete-incidents-modal {
  height: calc(100% - 148px);
  position: relative;
  top: 160px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D4D5D932;
  }

  &__tooltip {
    background-color: var(--rir-amelie);
    box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
    border-radius: 16px;
    padding: 20px;
    top: 24px;
    width: 300px;
    left: 0;
    position: absolute;
    z-index: 500;

    &-activator {
      position: relative;
      top: 2px;
    }
  }

  &__wrapper {
    width: 99%;
    margin-right: auto;
  }

  &__header {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 64px;
    padding-bottom: 24px;
    width: 100%;
  }

  &__incidents-wrapper {
    display: flex;
    align-items: center;
  }

  &__title {
    max-width: 1360px;
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
    padding: 24px 120px 32px 120px;
    width: 100%;
  }

  &__buttons {
    display: flex;
    max-width: 1360px;
    width: 100%;
  }

  &__button {
    flex: 1;
  }

  &__loading-ring {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>

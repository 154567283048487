<template>
  <div class="tooltip" @mouseenter="setPosition">
    <div class="tooltip__activator">
      <slot name="activator" />
    </div>
    <div class="tooltip__wrapper" ref="wrapper">
      <div class="tooltip__content" :style="colors">
        <slot />
        <div
          ref="arrow"
          class="tooltip__content--arrow"
        >
          <div :style="colors" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    color: {
      type: String,
      default: 'amelie'
    },
    sidebar: {
      type: Boolean,
      defalut: false
    }
  },
  computed: {
    colors () {
      return {
        backgroundColor: `var(--rir-${this.color})`,
        borderBottomColor: `var(--rir-${this.color})`,
        borderTopColor: `var(--rir-${this.color})`
      }
    }
  },
  methods: {
    setPosition (e) {
      const rect = e.target?.getBoundingClientRect()
      const bottom = this.sidebar ? rect.bottom : rect.bottom + window.pageYOffset

      this.$refs.wrapper.style.left = `${this.sidebar ? 15 : 42}px`
      this.$refs.wrapper.style.top = `${bottom}px`
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  &__wrapper {
    position: absolute;
    visibility: hidden;
  }

  &__activator {
    &:hover + .tooltip__wrapper {
      visibility: visible;
    }
  }

  &__content {
    font-size: 13px;
    line-height: 16px;
    color: var(--rir-amelie);
    position: absolute;
    top: calc(100% + 6px);
    border-radius: 12px;
    padding: 4px 8px;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0px 4px 16px rgba(48, 3, 14, 0.16);
    width: max-content;
    & * {
      font-size: 13px;
      line-height: 16px;
      color: var(--rir-amelie);
    }
    &--arrow {
      position: absolute;
      width: 20px;
      height: 8px;
      bottom: 100%;
      overflow: hidden;
      > div {
        position: absolute;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        bottom: -6px;
        left: 4px;
      }
      &.top{
        border-bottom: 0;
        border-top: 6px solid;
        top: -6px;
      }
    }
  }
}
</style>

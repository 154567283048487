<template>
  <section class="incident-list">
    <p
      v-if="incidentCount"
      class="mozzarella metropolis--text mb-4"
    >
      Найдено {{ incidentCount }}
    </p>
    <div v-if="incidentLength">
      <incident-item
        v-for="(incident, index) in incidents"
        :key="incident.id + index"
        :incident="incident"
      />
    </div>
    <div
      v-if="isLoading"
      class="incident-list__loading-ring"
    >
      <r-spinner />
    </div>
    <empty
      v-else-if="!incidentLength"
      class="mt-8"
      :title="isFiltered ? 'К сожалению, ничего не найдено' : 'Обращения отсутствуют'"
      :details="isFiltered ? 'Попробуйте изменить параметры поиска' : ''"
    />
    <selection-mode-panel v-if="isSelectionMode" />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import IncidentItem from '@/views/incident/components/IncidentItem';
import Empty from '@/components/Empty';
import SelectionModePanel from '@/components/SelectionModePanel';

export default {
  name: 'IncidentList',
  components: { IncidentItem, Empty, SelectionModePanel },
  data: () => ({
    isFiltered: false
  }),
  computed: {
    ...mapState('incident', ['filters', 'isLoading', 'incidents', 'selectedIncidents', 'isSelectionMode', 'incidentCount']),
    ...mapState('apps', ['anchor']),
    filtersStringify() {
      return JSON.stringify(this.filters);
    },
    incidentLength () {
      return this.incidents?.length 
    }
  },
  watch: {
    filtersStringify() {
      this.checkIsFiltered();
    },
    $route() {
      this.setAnchor(0);
    }
  },
  async created() {
    this.$nextTick(() => {
      this.showNewIncidents();
    });
  },
  mounted() {
    this.scrollToAnchor(this.anchor);
    window.addEventListener('scroll', this.showNewIncidents);
    this.setExecutionType('')
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.showNewIncidents);
  },
  methods: {
    ...mapMutations('incident', ['setFilterProperty', 'setProperty', 'setActions']),
    ...mapMutations('apps', ['setAnchor']),
    ...mapMutations('incident', ['setExecutionType']),
    ...mapActions('incident', ['getIncidents']),
    checkIsFiltered() {
      const filters = [];
      for (const key in this.filters) {
        if (key !== 'pageIndex' && this.filters[key]) filters.push(this.filters[key]);
      }
      this.isFiltered = !!filters.length;
    },
    scrollToAnchor(anchor) {
      window.scrollTo({ top: anchor });
    },
    showNewIncidents() {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 50) {
        if (this.incidents.length > this.filters.pageIndex * 20) {
          this.setFilterProperty({ attr: 'pageIndex', value: this.filters.pageIndex + 1 });
          this.getIncidents({
            category: [this.$route.meta.type],
            sortBy: this.filters.sortBy || this.$route.meta.sortBy
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.incident-list{
  padding-top: 174px !important;
  position: relative;
  padding: 32px;

  &__loading-ring {
    display: flex;
    justify-content: center;
  }
}
</style>

<template>
  <div
    class="d-flex flex-direction-column justify-content-center align-items-center"
    style="position: relative; height: 100%; width: 100%;"
  >
    <div
      class="d-flex flex-direction-column pa-8">
      <r-icon
        fill="rocky"
        class="mb-6"
        icon="warning"
        :size="56"
      />
      <span
        class="mb-8 titanic--text"
        style="font-weight: bold; font-size: 32px;"
      >
        Создать новое обращение?
      </span>
      <div class="mb-8">
        <p>Будет создано новое обращение на основании выбранного ответа пользователя</p>
      </div>
      <div class="d-flex justify-content-between">
        <r-button
          style="flex: 1"
          @click="$root.$modal.closeModal()"
          color="secondary"
          title="Отменить"
        >
        </r-button>
        <r-button
          style="flex: 1"
          class="ml-6"
          @click="create"
          :isLoading="loading"
          :disabled="loading"
          title="Создать"
        >
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import InfoModal from '@/views/incident/modal/InfoModal';
import { mapActions } from 'vuex';

export default {
  name: 'IncidentNewModal',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions('incident', ['createRawIncident']),
    ...mapActions('directory', ['loadDirectories']),
    async create() {
      this.loading = true;
      await this.createRawIncident({ id: this.id })
        .then(res => {
          this.$root.$modal.openModal(InfoModal, {
            icon: 'selected',
            title: 'Обращение успешно создано'
          });
          this.loadDirectories();
        })
        .then(() => {
          this.$router.push({ name: 'incident-new' });
        })
        .catch(() => this.errorModal());
      this.loading = false;
    },
    errorModal() {
      this.$root.$modal.openModal(InfoModal, {
        icon: 'close',
        fill: 'fargo',
        title: 'Ошибка создания обращения',
        subtitle: `Произошла внутренняя ошибка сервера во время отправки.
        Пожалуйста, повторите отправку. Если проблема повторится, то обратитесь в службу поддержки.`
      });
    }
  }
};
</script>

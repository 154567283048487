<template>
  <div class="file-view">
    <div class="file-view__container">
      <img
        :src="file.url"
        ref="image"
        class="file-view__img file-view__preview"
      >
      <img :src="file.url" ref="image" class="file-view__origin file-view__img">
    </div>
    <section class="file-view__action">
      <div class="d-flex flex-direction-column flex-auto">
        <span class="bryndza file-view__name">{{ decodeURI(file.filename) }}</span>
        <span>{{ file.contentLength | formatFileSize }}</span>
      </div>
      <r-button-action 
        title="Скачать"
        icon="load"
        color="rocky"
        size="sulguni"
        @click="download"
      />
    </section>
  </div>
</template>

<script>
import { download } from '@/plugins/lib';

export default {
  name: 'FileView',
  props: {
    file: {
      type: Object
    }
  },
  data() {
    return {
      image: null
    };
  },
  mounted() {
    this.image = this.$refs.image;
  },
  beforeDestroy() {
    window.removeEventListener('resize');
  },
  methods: {
    async download() {
      const response = await fetch(this.file.url);
      const blob = await response.blob();
      download(decodeURI(this.file.filename), blob);
    },
  }
};
</script>

<style lang="scss" scoped>
.file-view {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  height: 100%;

  &__container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
  }

  &__img {
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }

  &__preview {
    object-fit: contain;
    z-index: 5;
  }

  &__origin {
    opacity: .5;
    background: rgba(0,0,0,.03);
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-filter: blur(90px);
    filter: blur(90px);
  }

  &__photo {
    height: calc(100% - 104px);
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__action {
    width: 100%;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: auto;
    margin-top: 16px;
  }

  &__name {
    max-width: 600px;
    overflow-wrap: break-word;
  }
}
</style>

<template>
  <div class="message">
    <div class="message__head mb-5">
      <div class="d-flex">
        <span class="message__head-names clamp-1">
          <div class="feta troy--text">
            <span class="bryndza">{{ sender }}</span>
            <span
              v-if="!isConsolidation"
              class="ml-2"
            >
              <r-icon
                class="message__icon"
                icon="arrow-right"
                fill="titanic"
                size="12"
              />
              <span class="feta troy--text ml-2">{{ receiver }}</span>
            </span>
          </div>
        </span>
        <r-popover
          v-if="isActionsActive"
          class="message__popover"
          click-close
          position="right-bottom"
        >
          <template #activator="{ on }">
            <div v-on="on">
              <r-icon
                icon="menu-context"
                fill="rocky"
                size="16"
              />
            </div>
          </template>
          <template #content>
            <transition name="fade">
              <div
                v-if="isShownPopover"
                class="message__actions"
              >
                <div
                  v-for="(action, index) in actions"
                  :key="action.title"
                  class="message__action"
                  :class="{'mt-6': index !== 0}"
                  @click="openModal(action)"
                >
                  <r-icon
                    :icon="action.icon"
                    :fill="action.color"
                    size="16"
                  />
                  <span class="sulguni ml-3">{{ action.title }}</span>
                </div>
              </div>
            </transition>
          </template>
        </r-popover>
      </div>
      <span class="mozzarella anie">{{ message.ts | dayMonthHours }}</span>
    </div>
    <div
      class="message__message mb-8"
      :class="`message__message--${color}`"
    >
      <div v-if="splittedDescription && splittedDescription.length">
        <accordion
          post
          :type="color"
        >
          <div
            class="parmigiano ekas"
            :style="'white-space: pre-wrap'"
          >
            <p v-if="splittedDescription[0]" :style="incidentNumber ? 'float: left' : ''">
              {{ splittedDescription[0] }}
            </p>
            <div v-if="incidentNumber">
              <a
                v-if="link"
                class="link" 
                :href="link"
              >
                {{ incidentNumber }}
              </a>
              <r-tooltip
                v-else
                activator-hover
                color="amelie"
                title="ссылка недоступна"
              >
                <template #activator>
                  <p class="link">
                    {{ incidentNumber }}
                  </p>
                </template>
              </r-tooltip>
            </div>
            <p v-if="splittedDescription[1]">
              {{ splittedDescription[1] }}
            </p>
          </div>
        </accordion>
        <div
          v-if="message.text"
          class="mt-6"
        >
          <p class="mb-2 mozzarella anie">
            Примечание
          </p>
          <accordion
            post
            :type="color"
          >
            <div
              class="parmigiano ekas"
              :style="'white-space: pre-wrap'"
              v-html="decodedText"
            />
          </accordion>
        </div>
      </div>
      <div v-else-if="message.text">
        <accordion
          post
          :type="color"
        >
          <div
            class="parmigiano ekas"
            :style="'white-space: pre-wrap'"
            v-html="decodedText"
          />
        </accordion>
      </div>
      <div
        class="message__message-files mt-6"
        v-if="message.attachments && message.attachments.length"
      >
        <p class="mb-2 mozzarella anie">
          Прикреплённые файлы
        </p>
        <FileUploader
          :value="message.attachments"
          @includeAttach="includeAttach"
          :isButtonSimple="!!executionType"
          toggler
        />
      </div>
    </div>
  </div>
</template>

<script>
import FileView from '@/components/FileView';
import { mapState } from 'vuex';
import { MESSAGE_TYPES, ACTIONS, StatesRoutes } from '@/constants';
import Accordion from '@/components/Accordion';
import DeleteMessageModal from '@/views/incident/modal/DeleteMessageModal';
import IncidentNewModal from '@/views/incident/modal/IncidentNewModal';
import FileUploader from '@/components/FileUploader'

export default {
  name: 'Message',
  components: { Accordion, FileUploader },
  props: {
    message: {
      type: Object,
      default: null
    },
    incident: {
      type: Object
    }
  },
  data() {
    return {
      actions: [
        {
          icon: 'add',
          title: 'Создать обращение на основе ответа',
          color: 'rocky',
          modal: IncidentNewModal
        },
        {
          icon: 'delete',
          title: 'Удалить ответ',
          color: 'fargo',
          modal: DeleteMessageModal
        }
      ]
    };
  },
  computed: {
    ...mapState('incident', ['executionType']),
    ...mapState('session', ['areaCode']),
    decodedText() {
      return this.message.text.replace(/<(?!br\s*\/?)[^>]+>/g, '');
    },
    splittedDescription () {
      return this.message.description.split(/№\s\d{9}/g)
    },
    incidentNumber () {
      return this.message.description.match(/№\s\d{9}/g)?.[0] || null
    },
    link () {
      if (!this.message.consolidatedToCategory) return
      if (!this.message.consolidatedToAppealId) return
      return `${window.location.origin}/${StatesRoutes[this.message.consolidatedToCategory]}/${this.message.consolidatedToAppealId}`
    },
    isSender() {
      return this.areaCode === this.message.senderAreaCode;
    },
    isReceiver() {
      return this.areaCode === this.message.receiverAreaCode;
    },
    sender() {
      return (this.message.sender || 'Без имени') + (this.message.senderPosition ? `, ${this.message.senderPosition}` : '');
    },
    receiver() {
      return (this.message.receiver || 'Без имени') + (this.message.receiverPosition ? `, ${this.message.receiverPosition}` : '');
    },
    isShownIncludeAttachButton() {
      return this.executionType && [
        ACTIONS.COMPLETE_APPEAL,
        ACTIONS.EXECUTE_APPEAL,
        ACTIONS.EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER,
        ACTIONS.WAIT_FOR_APPEAL_ACTIVATION,
        ACTIONS.WAIT_FOR_ANSWER,
        ACTIONS.ADD_NOTE
      ].includes(this.executionType);
    },
    color() {
      const colors = {
        [MESSAGE_TYPES.MESSAGE_FROM_SOURCE]: 'white',
        [MESSAGE_TYPES.NOTE_FROM_MEDIATOR]: 'white',
        [MESSAGE_TYPES.MESSAGE_TO_SOURCE]: 'white',
        [MESSAGE_TYPES.NOTE_TO_MEDIATOR]: 'white',
        [MESSAGE_TYPES.CONVERSATION_TO_EXECUTOR]: 'white',
        [MESSAGE_TYPES.CONVERSATION_FROM_EXECUTOR]: this.isReceiver ? 'green' : 'white',
        [MESSAGE_TYPES.DELEGATE_APPEAL]: this.isReceiver ? 'orange' : 'white',
        [MESSAGE_TYPES.REPLY_DELEGATED_APPEAL]: this.isReceiver ? 'green' : 'white',
        [MESSAGE_TYPES.DECLINE_DELEGATED_APPEAL]: this.isReceiver ? 'green' : 'white',
        [MESSAGE_TYPES.FEEDBACK_NOTE]: 'blue',
        [MESSAGE_TYPES.FEEDBACK_PUBLICATION]: 'blue',
        [MESSAGE_TYPES.COMPLETION_UNANSWERED]: 'blue',
        [MESSAGE_TYPES.POSTPONEMENT]: 'blue',
        [MESSAGE_TYPES.REQUERY_APPEAL]: 'white',
        [MESSAGE_TYPES.REPLY_REQUERIED_APPEAL]: this.isReceiver ? 'orange' : 'white',
        [MESSAGE_TYPES.CONSOLIDATION]: 'white',
        [MESSAGE_TYPES.SEPARATION]: 'white'
      };
      return this.isSender ? 'blue' : colors[this.message.type];
    },
    isActionsActive() {
      return this.message.type === MESSAGE_TYPES.REPLY_REQUERIED_APPEAL && this.incident.areaCode === this.areaCode;
    },
    isConsolidation() {
      return [MESSAGE_TYPES.CONSOLIDATION, MESSAGE_TYPES.SEPARATION].includes(this.message.type);
    },
    isShownPopover() {
      return !this.$root.$modal.isOpen;
    }
  },
  methods: {
    selectFile(file) {
      this.$root.$modal.openModal(FileView, { file });
    },
    openModal(action) {
      this.$root.$modal.openModal(action.modal, { id: this.message.id });
    },
    includeAttach(file) {
      this.$emit('includeAttach', file);
    },
  }
};
</script>

<style lang="scss" scoped>

.message {

  &::v-deep {
    .r-modal__content {
      align-items: normal !important
    }
  }

  &__icon {
    display: inline-flex !important;
  }

  &__popover {
    margin-left: auto;
    cursor: pointer;
    position: relative;
  }

  &__actions {
    background-color: var(--rir-amelie);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 9px 28px rgba(85, 112, 175, 0.18);
    position: absolute;
    left: -24px;
    z-index: 90;
  }

  &__action {
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    opacity: 0.72;
    transition: opacity, 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  @mixin message-arrow {
    content: '';
    height: 24px;
    width: 24px;
    border-radius: 2px;
    position: absolute;
    top: -5px;
    left: 12px;
    transform: rotate(-45deg);
  }

  &__message {
    position: relative;
    padding: 20px;
    border-radius: 8px;

    span {
      opacity: 0.72;
    }

    &--white {
      background-color: var(--rir-amelie);

      &::before {
        @include message-arrow;
        background-color: var(--rir-amelie);
      }

      span {
        color: var(--rir-titanic)
      }
    }

    &--blue {
      background-color: var(--rir-arrival);

      &::before {
        @include message-arrow;
        background-color: var(--rir-arrival);
      }

      span {
        color: var(--rir-titanic)
      }
    }

    &--green {
      background-color: var(--rir-amadeus);

      &::before {
        @include message-arrow;
        background-color: var(--rir-amadeus);
      }

      span {
        color: var(--rir-oldboy)
      }

      &::v-deep {
        .r-icon {
          path {
            fill: var(--rir-matrix);
          }
        }
        .r-file-list {
          &__item {
            background-color: rgba(#57A003, 0.08);
          }

          &__info {
            color: var(--rir-oldboy);
          }

          &__details {
            span {
              color: var(--rir-matrix);
            }
          }
        }
      }
    }

    &--orange {
      background-color: var(--rir-jaws);

      &::before {
        @include message-arrow;
        background-color: var(--rir-jaws);
      }

      span {
        color: var(--rir-schindler)
      }

      &::v-deep {
        .r-icon {
          path {
            fill: var(--rir-lebowski);
          }
        }
        .r-file-list {
          &__item {
            background-color: rgba(#D06E0B, 0.08);
          }

          &__info {
            color: var(--rir-schindler);
          }

          &__details {
            span {
              color: var(--rir-lebowski);
            }
          }
        }
      }
    }

    &-files {
      display: flex;
      flex-direction: column;

      &-icon {
        cursor: pointer;
      }

      &::v-deep {
        .r-file-list {
          &__item {
            min-width: 0;
          }

          &__info {
            width: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="d-flex flex-direction-column justify-content-center align-items-center"
    style="position: relative; height: 100%; width: 100%;"
  >
    <div class="d-flex flex-direction-column pa-8">
      <r-icon
        fill="rocky"
        class="mb-6"
        icon="help"
        :size="56"
      />
      <span
        class="mb-8 titanic--text"
        style="font-weight: bold; font-size: 32px;"
      >
        {{ title }}
      </span>
      <div class="mb-8">
        <p>{{ subtitle }}</p>
      </div>
      <div class="d-flex justify-content-between">
        <r-button
          style="flex: 1"
          @click="closeModal()"
          color="secondary"
          :title="`${this.selectedIncidents.length > 1 ? 'Не разъединять' : 'Не отсоединять'}`"
        >
        </r-button>
        <r-button
          style="flex: 1"
          class="ml-8"
          @click="separate"
          :isLoading="loading"
          :disabled="loading"
          :title="`${this.selectedIncidents.length > 1 ? 'Разъединить' : 'Отсоединить'}`"
        >
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import InfoModal from '@/views/incident/modal/InfoModal';
import { mapActions, mapMutations } from 'vuex';
import { StatesTitles } from '@/constants';
import { goBack } from '@/plugins/lib';

export default {
  name: 'SeparateSubmitModal',
  props: {
    mainIncidentId: {
      type: String
    },
    selectedIncidents: {
      type: Array
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    title () {
      return this.selectedIncidents.length > 1 ? 'Разъединить обращения?' : 'Отсоединить обращение?'
    },
    subtitle () {
      return this.selectedIncidents.length > 1
        ? 'Выбранные обращения будут отсоединены от текущего объединения'
        : `Обращение № ${this.selectedIncidents[0].number} будет отсоединено от объединения и перемещено в раздел ${StatesTitles[this.selectedIncidents[0].category]}`
    }
  },
  methods: {
    ...mapMutations('incident', ['setFilterProperty', 'setProperty', 'setExecutionType']),
    ...mapActions('incident', ['separateIncidents', 'getIncidents']),
    ...mapActions('directory', ['getCountBySystem']),
    async separate() {
      this.loading = true;
      await this.separateIncidents({
        id: this.mainIncidentId,
        data: this.selectedIncidents.map(incident => incident.id)
      })
        .then(async () => {
          if (this.$route.params.id) goBack();
          else {
            this.setProperty({ name: 'incidents', value: [] });
            this.setProperty({ name: 'appealActions', value: [] });
            this.setFilterProperty({ attr: 'pageIndex', value: 0 });
            await this.getIncidents({
              category: [this.$route.meta.type],
              sortBy: this.$route.meta.sortBy
            });
            await this.getCountBySystem();
          }
          this.$rir.modal.close(null, true)
          this.$rir.notification.send({
            title: 'Обращения разъединены',
            seconds: 5
          });
        })
        .catch(() => this.errorModal());
      this.loading = false;
    },
    closeModal () {
      this.setExecutionType('')
      this.$rir.modal.close()
    },
    errorModal() {
      this.$root.$modal.openModal(InfoModal, {
        icon: 'close',
        fill: 'fargo',
        title: 'Ошибка разъединения обращений',
        subtitle: `Произошла внутренняя ошибка сервера во время отправки.
        Пожалуйста, повторите отправку. Если проблема повторится, то обратитесь в службу поддержки.`
      });
    }
  }
};
</script>

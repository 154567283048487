import Vue from 'vue';
import getEnv from '@/plugins/env';
import axios from 'axios';

const reqInstance = axios.create({
  headers: {
    Authorization: 'Token ' + (getEnv('VUE_APP_MAPS_SUGGEST_PROVIDER') === 'dadata'
      ? getEnv('VUE_APP_MAPS_DADATA_SUGGEST_API_KEY')
      : getEnv('VUE_APP_MAPS_PBPROG_SUGGEST_API_KEY'))
  }
});

export default {
  async getHints ({ commit }, payload) {
    const data = {
      query: payload,
      count: 10
    };
    return await reqInstance.post(
      `${getEnv('VUE_APP_MAPS_SUGGEST_PROVIDER') === 'dadata'
        ? 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
        : 'https://data.pbprog.ru/api/address/full-address/parse'}`,
      data
    ).then(({ data }) => data?.suggestions?.map(el => ({
      id: el?.data?.fias_id,
      postal_code: el?.data?.postal_code,
      value: el?.value,
      geo_lat: el?.data?.geo_lat,
      geo_lon: el?.data?.geo_lon
    })) || null )
  },
  async reverseGeocode ({ commit }, address) {
    return await Vue.axios.get(
      `https://geocode-maps.yandex.ru/1.x/?apikey=${getEnv('VUE_APP_MAPS_API_KEY')}&geocode=${address}&format=json`
    ).then(({ data }) => data?.response?.GeoObjectCollection?.featureMember[0]?.GeoObject || {});
  },
  async geocode ({ dispatch, state, commit }, coords) {
    if (getEnv('VUE_APP_MAPS_SUGGEST_PROVIDER') === 'dadata') {
      const data = {
        lat: coords[0],
        lon: coords[1]
      };
      return await reqInstance.post(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address`, data)
      .then(({ data }) => {
        const res = data?.suggestions[0]
        return {
          fiasCodeId: res?.data?.fias_id,
          locality: res?.data?.city_with_type || res?.data?.region_with_type,
          address: res?.value,
          lat: res?.data?.geo_lat || getEnv('VUE_APP_DEFAULT_LATITUDE'),
          lng: res?.data?.geo_lon || getEnv('VUE_APP_DEFAULT_LONGITUDE')
        }
      })
    }
    if (getEnv('VUE_APP_MAPS_SUGGEST_PROVIDER') === 'pbprog') {
      return await Vue.axios.get(
        `https://geocode-maps.yandex.ru/1.x/?apikey=${getEnv('VUE_APP_MAPS_API_KEY')}&geocode=${coords.reverse().join(',')}&format=json`
      ).then(async({ data }) => {
        const res = data?.response?.GeoObjectCollection?.featureMember[0]?.GeoObject.metaDataProperty?.GeocoderMetaData
        const address = res?.text || ''
        const location = await dispatch('getHints', `${state.addressPrefix}, ${address}`)
        return {
          fiasCodeId: location?.length ? location[0]?.id : null,
          locality: res?.Address?.Components?.find(el => el.kind === 'locality').name,
          address: address || null,
          lat: coords[1] || getEnv('VUE_APP_DEFAULT_LATITUDE'),
          lng: coords[0] || getEnv('VUE_APP_DEFAULT_LONGITUDE')
        }
      });
    }
  },
};

import IncidentListToolbar from '@/views/incident/toolbars/IncidentListToolbar';
import IncidentViewToolbar from '@/views/incident/toolbars/IncidentViewToolbar';
import IncidentView from '@/views/incident/IncidentView';
import IncidentList from '@/views/incident/IncidentList';
import DefaultLayout from '@/layout/DefaultLayout';
import { STATES } from '@/constants';

export const incidentErrorRoutes = [
  {
    path: '/incident-error',
    name: 'incident-error',
    components: {
      default: IncidentList,
      toolbar: IncidentListToolbar
    },
    meta: {
      type: STATES.ERROR,
      sortBy: 'UPDATED_AT',
      title: 'Анализ ошибки',
      layout: DefaultLayout
    }
  },
  {
    path: '/incident-error/:id',
    name: 'incident-error-view',
    components: {
      default: IncidentView,
      toolbar: IncidentViewToolbar
    },
    meta: {
      layout: DefaultLayout,
      type: STATES.ERROR
    }
  },
  {
    path: '/incident-error/:id/more/:moreId?',
    name: 'incident-error-view-more',
    components: {
      default: IncidentView,
      toolbar: IncidentViewToolbar
    },
    meta: {
      layout: DefaultLayout,
      type: STATES.ERROR
    }
  }
];

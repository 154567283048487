<template>
  <section class="d-flex flex-direction-column response-form__actions">
    <div v-if="isShownInfo" class="response-form__info mb-6">
      <div class="bryndza fargo--text mb-1">
        Внимание!
      </div>
      <div class="mozzarella prestige--text">
        В составе объединённого обращения есть обращения из источника «Медиалогия». Ответ на них не будет отправлен, и они будут помещены в раздел «Решённые»
      </div>
    </div>
    <FormTextarea
      class="mb-6"
      :rows="5"
      :max-rows="5"
      :label="isCompleteWithoutResponse ? 'Комментарий, не виден автору' : 'Текст ответа'"
      v-model="model.feedback"
      :error="$v.model.feedback.$error"
      :errorMessage="mesErr($v.model.feedback)"
    />
    <FileUploader
      v-if="showDragUpload"
      class="mb-6"
      v-model="model.attachments"
      mode="edit"
    />
    <div v-if="isShownSelects" class="d-flex">
      <FormSelect
        v-if="accSources && accSources.length"
        class="response-form__select"
        label="Аккаунт для ответа"
        :items="accSources"
        idValue="id"
        titleValue="description"
        :disabled="accSources.length < 2"
        v-model="model.publisherId"
      />
      <FormSelect
        v-if="showResponseType"
        class="response-form__select ml-6"
        label="Тип ответа"
        :items="responseTypes"
        idValue="id"
        titleValue="title"
        :disabled="accSources.length < 2"
        v-model="model.typeCode"
        @input="changeTypeCode"
      />
    </div>
  </section>
</template>

<script>
import { required } from '@/plugins/vuelidate/customValidate'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import FileView from '@/components/FileView'
import { mapActions, mapState } from 'vuex'
import { ACTIONS, ResponseTypes } from '@/constants'
import FormSelect from '@/components/FormSelect'
import FormTextarea from '@/components/FormTextarea'
import FileUploader from '@/components/FileUploader'

export default {
  name: 'ResponceForm',
  mixins: [validationMixin, validateMixin],
  components: {
    FormSelect,
    FormTextarea,
    FileUploader,
  },
  props: {
    incident: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoadingSendForm: false,
    model: {
      typeCode: 'PUBLICATION',
      feedback: null,
      attachments: [],
      publisherId: null
    },
    accSources: [],
    incidentCodes: [],
    responseTypes: ResponseTypes
  }),
  validations () {
    return {
      model: {
        feedback: {
          required: required('Обязательное поле')
        }
      }
    }
  },
  mounted () {
    this.getAccSources()
  },
  computed: {
    ...mapState('incident', ['executionType']),
    isAttachAnswer () {
      return this.model.attachments.length < 2 && this.showDragUpload
    },
    showDragUpload () {
      return !['IG_COMMENT'].includes(this.incident.accountChannelCode)
    },
    showResponseType () {
      return ['MEDIALOGIA-IM'].includes(this.incident.systemCode)
    },
    isCompleteWithoutResponse () {
      return this.executionType === ACTIONS.COMPLETE_WITHOUT_RESPONSE
    },
    isConsolidated () {
      return !!(this.incident?.consolidatedAppeals?.length && !this.$route.name.match(/more/))
    },
    isMedialogy () {
      return this.incident?.consolidatedAppeals?.filter((incident) => incident.systemCode === 'MEDIALOGIA-IM').length || this.incident.systemCode === 'MEDIALOGIA-IM'
    },
    isShownSelects () {
      return !this.isConsolidated && !this.isCompleteWithoutResponse
    },
    isShownInfo () {
      return this.isMedialogy && this.isConsolidated && this.isCompleteWithoutResponse
    }
  },
  methods: {
    ...mapActions('incident', ['getPublishers']),
    async getAccSources () {
      this.accSources = await this.getPublishers(this.incident)
      if (this.accSources.length) this.model.publisherId = this.accSources[0].id
    },
    changeTypeCode (type) {
      this.$emit('change-type-code', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.response-form {
  &__select {
    flex: 1
  }

  &__info {
    background-color: #fef8f9;
    padding: 24px;
    border-radius: 16px;
  }
}
</style>

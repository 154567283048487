export const ACTIONS = Object.freeze({
  COMPLETE_WITHOUT_RESPONSE: 'COMPLETE_WITHOUT_RESPONSE',
  COMPLETE_APPEAL: 'COMPLETE_APPEAL',
  EXECUTE_APPEAL: 'EXECUTE_APPEAL',
  EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER: 'EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER',
  WAIT_FOR_APPEAL_ACTIVATION: 'WAIT_FOR_APPEAL_ACTIVATION',
  WAIT_FOR_ANSWER: 'WAIT_FOR_ANSWER',
  IGNORE_APPEAL: 'IGNORE_APPEAL',
  ADD_NOTE: 'ADD_NOTE',
  SELECT: 'SELECT',
  DELEGATE_APPEAL: 'DELEGATE_APPEAL',
  DECLINE_DELEGATED_APPEAL: 'DECLINE_DELEGATED_APPEAL',
  REPLY_DELEGATED_APPEAL: 'REPLY_DELEGATED_APPEAL',
  MOVE_TO_RESOLVED: 'MOVE_TO_RESOLVED',
  CONSOLIDATE: 'CONSOLIDATE',
  SEPARATE: 'SEPARATE'
});

export const ActionTitles = Object.freeze({
  [ACTIONS.COMPLETE_WITHOUT_RESPONSE]: 'Завершить без ответа',
  [ACTIONS.COMPLETE_APPEAL]: 'Ответить заявителю',
  [ACTIONS.EXECUTE_APPEAL]: 'Запустить в работу',
  [ACTIONS.EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER]: 'В работу с промежуточным ответом',
  [ACTIONS.WAIT_FOR_APPEAL_ACTIVATION]: 'Отложить',
  [ACTIONS.WAIT_FOR_ANSWER]: 'Дозапросить',
  [ACTIONS.IGNORE_APPEAL]: 'Удалить',
  [ACTIONS.ADD_NOTE]: 'Добавить примечание',
  [ACTIONS.SELECT]: 'Выбрать',
  [ACTIONS.DELEGATE_APPEAL]: 'Делегировать',
  [ACTIONS.DECLINE_DELEGATED_APPEAL]: 'Отклонить',
  [ACTIONS.REPLY_DELEGATED_APPEAL]: 'Ответить координатору',
  [ACTIONS.MOVE_TO_RESOLVED]: 'Завершить',
  [ACTIONS.CONSOLIDATE]: 'Объединить',
  [ACTIONS.SEPARATE]: 'Разъединить'
});

export const ActionIcons = Object.freeze({
  [ACTIONS.COMPLETE_WITHOUT_RESPONSE]: 'selection',
  [ACTIONS.COMPLETE_APPEAL]: 'send',
  [ACTIONS.EXECUTE_APPEAL]: 'in-progress',
  [ACTIONS.EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER]: 'message',
  [ACTIONS.WAIT_FOR_APPEAL_ACTIVATION]: 'timer',
  [ACTIONS.WAIT_FOR_ANSWER]: 'help',
  [ACTIONS.IGNORE_APPEAL]: 'delete',
  [ACTIONS.ADD_NOTE]: 'quote',
  [ACTIONS.SELECT]: 'button',
  [ACTIONS.DELEGATE_APPEAL]: 'reply',
  [ACTIONS.DECLINE_DELEGATED_APPEAL]: 'block',
  [ACTIONS.REPLY_DELEGATED_APPEAL]: 'send',
  [ACTIONS.MOVE_TO_RESOLVED]: 'selection',
  [ACTIONS.CONSOLIDATE]: 'steps',
  [ACTIONS.SEPARATE]: 'digital-panels'
});

export const DELEGATION_ACTIONS = Object.freeze({
  DELEGATE_APPEAL: 'DELEGATE_APPEAL',
  REPLY_DELEGATED_APPEAL: 'REPLY_DELEGATED_APPEAL',
  DECLINE_DELEGATED_APPEAL: 'DECLINE_DELEGATED_APPEAL'
});

export const DelegationActionTitles = Object.freeze({
  [DELEGATION_ACTIONS.DELEGATE_APPEAL]: 'Делегированное',
  [DELEGATION_ACTIONS.REPLY_DELEGATED_APPEAL]: 'Ответ на делегированное',
  [DELEGATION_ACTIONS.DECLINE_DELEGATED_APPEAL]: 'Отклонённое'
});

export const MESSAGE_TYPES = Object.freeze({
  MESSAGE_FROM_SOURCE: 'MESSAGE_FROM_SOURCE',
  NOTE_FROM_MEDIATOR: 'NOTE_FROM_MEDIATOR',
  MESSAGE_TO_SOURCE: 'MESSAGE_TO_SOURCE',
  NOTE_TO_MEDIATOR: 'NOTE_TO_MEDIATOR',
  CONVERSATION_TO_EXECUTOR: 'CONVERSATION_TO_EXECUTOR',
  CONVERSATION_FROM_EXECUTOR: 'CONVERSATION_FROM_EXECUTOR',
  DELEGATE_APPEAL: 'DELEGATE_APPEAL',
  REPLY_DELEGATED_APPEAL: 'REPLY_DELEGATED_APPEAL',
  DECLINE_DELEGATED_APPEAL: 'DECLINE_DELEGATED_APPEAL',
  FEEDBACK_NOTE: 'FEEDBACK_NOTE',
  FEEDBACK_PUBLICATION: 'FEEDBACK_PUBLICATION',
  REQUERY_APPEAL: 'REQUERY_APPEAL',
  REPLY_REQUERIED_APPEAL: 'REPLY_REQUERIED_APPEAL',
  CONSOLIDATION: 'CONSOLIDATION',
  SEPARATION: 'SEPARATION',
  COMPLETION_UNANSWERED: 'COMPLETION_UNANSWERED',
  POSTPONEMENT: 'POSTPONEMENT',
});

export const STATES = Object.freeze({
  NEW: 'NEW',
  IN_WORK: 'IN_WORK',
  WAITING_DECISION: 'WAITING_DECISION',
  ADDITIONAL_QUESTION: 'ADDITIONAL_QUESTION',
  PAUSED: 'PAUSED',
  ERROR: 'ERROR',
  SOLVED: 'SOLVED',
  REMOVED: 'REMOVED',
});

export const StatesTitles = Object.freeze({
  [STATES.NEW]: 'Новые',
  [STATES.IN_WORK]: 'В работе',
  [STATES.WAITING_DECISION]: 'Ожидают решения',
  [STATES.ADDITIONAL_QUESTION]: 'Дозапрос',
  [STATES.PAUSED]: 'Отложенные',
  [STATES.ERROR]: 'Ошибка отправки',
  [STATES.SOLVED]: 'Решённые',
  [STATES.REMOVED]: 'Удалённые',
});

export const StatesIcons = Object.freeze({
  [STATES.NEW]: 'new',
  [STATES.IN_WORK]: 'in-progress',
  [STATES.WAITING_DECISION]: 'waiting',
  [STATES.ADDITIONAL_QUESTION]: 'help',
  [STATES.PAUSED]: 'timer',
  [STATES.ERROR]: 'warning',
  [STATES.SOLVED]: 'selection',
  [STATES.REMOVED]: 'delete'
});

export const StatesRoutes = Object.freeze({
  [STATES.NEW]: 'incident-new',
  [STATES.IN_WORK]: 'incident-work',
  [STATES.WAITING_DECISION]: 'incident-analyze',
  [STATES.ADDITIONAL_QUESTION]: 'incident-additional-question',
  [STATES.PAUSED]: 'incident-paused',
  [STATES.ERROR]: 'incident-error',
  [STATES.SOLVED]: 'incident-completed',
  [STATES.REMOVED]: 'incident-deleted'
});

export const ResponseTypes = [
  {
    id: 'PUBLICATION',
    title: 'Автору'
  },
  {
    id: 'NOTE',
    title: 'В медиалогию'
  }
];

export const ACCEPTED_DOC_FILE_TYPES = ["pdf", "doc", "docx", "xls", "xlsx"];

export const ACCEPTED_IMAGE_FILE_TYPES = ["jpg", "jpeg", "png", "svg", "bmp", "ico", "gif"];
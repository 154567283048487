<template>
  <div class="complete-appeal-modal">
    <div class="complete-appeal-modal__wrapper">
      <r-icon
        fill="rocky"
        class="mb-6"
        icon="warning"
        :size="56"
      />
      <div class="mb-4 titanic--text camembert">
        {{ title }}
      </div>
      <div class="mb-6">
        {{ subtitle }}
      </div>
      <r-spinner
        v-if="isLoading"
        :size="32"
      />
      <div v-else>
        <div
          v-for="(incident, index) in filteredIncidents"
          :key="incident.data.id"
          class="complete-appeal-modal__appeal mb-4"
          :style="index === filteredIncidents.length - 1
            ? 'border-bottom: none;' : 'border-bottom: 1px solid var(--rir-arrival);'"
        >
          <information-block-incident
            :incident="incident.data"
            class="complete-appeal-modal__information"
          />
          <div>
            <FormSelect
              class="complete-appeal-modal__select"
              label="Аккаунт для ответа"
              :items="incident.data.accSources"
              idValue="id"
              titleValue="description"
              :disabled="incident.data.accSources.length < 2"
              v-model="incident.data.publisherId"
            />
            <FormSelect
              v-if="['MEDIALOGIA-IM'].includes(incident.data.systemCode)"
              class="complete-appeal-modal__select mt-5"
              label="Тип ответа"
              :items="responseTypes"
              idValue="id"
              titleValue="title"
              v-model="incident.data.typeCode"
            />
          </div>
        </div>
      </div>
      <FormTextarea
        class="mb-8"
        :rows="5"
        :max-rows="5"
        :label="'Текст ответа'"
        v-model="model.feedback"
        :error="$v.model.feedback.$error"
        :errorMessage="mesErr($v.model.feedback)"
      />
      <FileUploader
        class="mb-6"
        v-model="model.attachments"
        mode="edit"
      />
      <div class="complete-appeal-modal__footer">
        <div class="complete-appeal-modal__buttons">
          <r-button
            class="complete-appeal-modal__button"
            @click="$root.$modal.closeModal()"
            color="secondary"
            title="Отменить"
          />
          <r-button
            class="complete-appeal-modal__button ml-8"
            @click="completeAppeal"
            :isLoading="sending"
            :disabled="sending"
            title="Отправить"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { ACTIONS, ResponseTypes } from '@/constants';
import { required } from '@/plugins/vuelidate/customValidate';
import { validationMixin } from 'vuelidate';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import FileView from '@/components/FileView';
import InformationBlockIncident from '@/views/incident/components/InformationBlockIncident';
import FormSelect from '@/components/FormSelect'
import FileUploader from '@/components/FileUploader'
import FormTextarea from '@/components/FormTextarea'

export default {
  name: 'CompleteAppealModal',
  components: {
    InformationBlockIncident,
    FormSelect,
    FileUploader,
    FormTextarea
  },
  mixins: [validationMixin, validateMixin],
  props: {
    incidents: {
      type: Array
    }
  },
  data() {
    return {
      sending: false,
      model: {
        feedback: null,
        attachments: []
      },
      responseTypes: ResponseTypes,
      filteredIncidents: [],
      isLoading: false
    };
  },
  validations: {
    model: {
      feedback: {
        required: required('Обязательное поле')
      }
    }
  },
  computed: {
    ...mapState('session', ['user']),
    title() {
      return this.incidents.length > 1 ? 'Ответ выбранным' : 'Ответ выбранному';
    },
    subtitle() {
      return this.incidents.length > 1
        ? 'Выбранные обращения получат одинаковый ответ'
        : 'Введите текст ответа для выбранного обращения';
    }
  },
  async mounted() {
    await this.getFilteredIncidents();
  },
  methods: {
    ...mapMutations('incident', ['setFilterProperty', 'setProperty', 'setSelectionMode']),
    ...mapActions('incident', ['moveIncident', 'getIncidents', 'getPublishers']),
    ...mapMutations('apps', ['setAnchor']),
    selectFile(file) {
      this.$root.$modal.openModal(FileView, { file });
    },
    async getFilteredIncidents() {
      this.isLoading = true;
      this.filteredIncidents = await Promise.all(this.incidents.filter(incident => ['TELEGRAM-BOT', 'VK', 'OK', 'MEDIALOGIA-IM', 'SMART-CITY'].includes(incident.data.systemCode)).map(async incident => {
        const accSources = await this.getPublishers(incident.data);
        return {
          data: {
            ...incident.data,
            publisherId: accSources.length ? accSources[0].id : null,
            typeCode: this.responseTypes[0].id,
            accSources: accSources || []
          },
          actions: incident.actions
        };
      }));
      this.isLoading = false;
    },
    async completeAppeal() {
      this.$v.$touch();
      if (this.$v.$invalid || this.sending) return;
      const unfilteredIncidents = this.incidents.filter(incident => !['TELEGRAM-BOT', 'VK', 'OK', 'MEDIALOGIA-IM', 'SMART-CITY'].includes(incident.data.systemCode));
      const incidents = [...unfilteredIncidents, ...this.filteredIncidents];
      const payload = incidents.map(incident => ({
        id: incident.data.id,
        statusCode: incident.data.action,
        moderatorId: this.user.id,
        feedback: {
          ...this.model,
          publisherId: incident.data.publisherId || null,
          typeCode: incident.data.typeCode || null
        },
        decision: ACTIONS.COMPLETE_APPEAL
      }));
      this.sending = true;
      await this.moveIncident(payload)
        .then(async () => {
          if (this.$route.params.id) {
            this.setAnchor(0);
            const path = this.$route.path?.split('/');
            this.$router.push(`/${path[1]}`);
          } else {
            this.setProperty({ name: 'incidents', value: [] });
            this.setProperty({ name: 'appealActions', value: [] });
            this.setFilterProperty({ attr: 'pageIndex', value: 0 });
            await this.getIncidents({
              category: [this.$route.meta.type],
              sortBy: this.$route.meta.sortBy
            });
          }
          this.sending = false;
          this.$root.$modal.closeModal()
          this.$rir.notification.send({
            title: payload.length > 1 ? 'Обращения перемещены в решенные' : 'Обращение перемещено в решенные',
            seconds: 5
          });
        })
        .catch(() => {
          this.sending = false;
          this.$root.$modal.closeModal()
        })
        .finally(() => this.setSelectionMode(false));
    }
  }
};
</script>

<style lang="scss" scoped>
.complete-appeal-modal {
  height: calc(100% - 90px);
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D4D5D932;
  }

  &__wrapper {
    width: 98%;
    margin: auto;
  }

  &__footer {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
    padding: 24px 32px 32px 32px;
    width: 100%;
  }

  &__information {
    display: grid;
    grid-template-columns: minmax(171px, 26%) 74%;
    grid-column-gap: 32px;
    overflow: hidden;
    height: 100%;
    margin-right: 20px;
  }

  &__appeal {
    display: grid;
    grid-template-columns: 5fr 1fr;
    padding-bottom: 24px;
    margin-top: 24px;
  }

  &__buttons {
    display: flex;
    max-width: 1480px;
    width: 100%;
  }

  &__button {
    flex: 1;
  }
}
</style>

import moment from 'moment';

moment.locale('ru');
export default {
  install(Vue, options) {
    Vue.filter('formatFileSize', (size) => {
      if (!size) return "0 б";
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return `${(size / Math.pow(1024, i)).toFixed(1)} ${["б", "Кб", "Мб", "Гб", "Гб"][i]}`;
    });
  }
};

<template>
  <div class="not-found">
    <div class="not-found__img mb-2" />
    <span class="feta ekas">Страница не существует или была удалена</span>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 64px;

  &__img {
    margin: 32px;
    height: 144px;
    width: 403px;
    background-repeat: no-repeat;
    background-image: url('/img/img/404.svg');
  }
}
</style>

<template>
  <div
    class="selection-panel"
    :class="{'selection-panel--open': isSidebarOpen}"
  >
    <div class="d-flex align-items-center">
      <div class="feta ekas">
        Выбрано {{ selectedIncidents.length }}
      </div>
      <div
        v-for="action in actions"
        :key="action.title"
        class="ml-6"
        @click="openModal(action)"
      >
        <div
          v-if="action.isActive"
          class="selection-panel__action"
        >
          <r-icon
            :icon="action.icon"
            :fill="action.color"
            :size="16"
          />
          <div :class="`ml-2 ${action.color}--text`">
            {{ action.title }}
          </div>
        </div>
        <r-tooltip
          v-else
          activator-hover
          color="amelie"
          position="top"
          title="Действие недоступно для одного или нескольких выбранных обращений"
        >
          <template #activator>
            <div class="selection-panel__action--disabled">
              <r-icon
                :icon="action.icon"
                :fill="action.color"
                :size="16"
              />
              <div :class="`ml-2 ${action.color}--text`">
                {{ action.title }}
              </div>
            </div>
          </template>
        </r-tooltip>
      </div>
    </div>
    <div
      class="selection-panel__action reset"
      @click="discardSelectionMode"
    >
      <r-icon
        fill="rocky"
        icon="close"
        :size="16"
      />
      <div class="ml-2 rocky--text">
        Отменить
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { ACTIONS } from '@/constants';
import DeleteModal from '@/views/incident/modal/DeleteModal';
import MoveToResolvedModal from '@/views/incident/modal/MoveToResolvedModal';
import CompleteAppealModal from '@/views/incident/modal/CompleteAppealModal';

export default {
  name: 'SelectionModePanel',
  computed: {
    ...mapState('incident', ['selectedIncidents']),
    ...mapState('apps', ['isSidebarOpen']),
    actions() {
      return [
        {
          title: 'Завершить без ответа',
          icon: 'selection',
          color: 'rocky',
          modal: MoveToResolvedModal,
          isActive: this.checkActions(ACTIONS.COMPLETE_WITHOUT_RESPONSE)
        },
        {
          title: 'Ответить выбранным',
          icon: 'send',
          color: 'rocky',
          modal: CompleteAppealModal,
          isActive: this.checkActions(ACTIONS.COMPLETE_APPEAL)
        },
        {
          title: 'Удалить',
          icon: 'delete',
          color: 'fargo',
          modal: DeleteModal,
          isActive: this.checkActions(ACTIONS.IGNORE_APPEAL)
        }
      ];
    }
  },
  methods: {
    ...mapMutations('incident', ['setSelectionMode']),
    discardSelectionMode() {
      this.setSelectionMode(false);
    },
    checkActions(action) {
      if (!this.selectedIncidents?.length) return;
      const filteredIncidents = this.selectedIncidents?.filter(incident => incident.actions.includes(action));
      return filteredIncidents.length === this.selectedIncidents?.length;
    },
    openModal(action) {
      const { modal, isActive } = action;
      if (!isActive) return;
      this.$root.$modal.openModal(modal, { incidents: this.selectedIncidents });
    }
  }
};
</script>

<style lang="scss" scoped>

.reset {
  right: 0;
  position: sticky;
}

.selection-panel {
  transform: translateX(-32px);
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  padding: 0 16px;
  width: 100%;
  z-index: 10;
  transition: .3s ease;
  background-color: var(--rir-trainspotting);
  padding: 26px 32px;
  box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);


  &--open {
    width: calc(100% - 344px);
  }

  &__action {
    display: flex;
    align-items: center;
    transition: .3s ease;
    cursor: pointer;

    &--disabled {
      display: flex;
      align-items: center;
      transition: .3s ease;
      opacity: 0.32;
      cursor: default;
    }

    &:hover {
      opacity: 0.72;
    }
  }
}
</style>

<template>
  <div class="incident-new-toolbar">
    <r-button-action
      @click="returnButtonHandler"
      icon="arrow-left"
      title="Назад"
    />
    <user-info />
  </div>
</template>

<script>
import UserInfo from '@/layout/components/UserInfo';
import { goBack } from '@/plugins/lib';

export default {
  name: 'IncidentNewToolbar',
  components: { UserInfo },
  methods: {
    returnButtonHandler() {
      goBack();
    }
  }
};
</script>

<style lang="scss" scoped>
.incident-new-toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.return-button {
  display: block;
  line-height: 20px;
  color: var(--rir-rocky);
  cursor: pointer;
  background: none;
  border: none;

  &:hover {
    color: var(--rir-rocky--hover);

    &::v-deep {
      & path {
        fill: var(--rir-rocky--hover);
      }
    }
  }

  &__icon {
    margin-right: 9px;
  }
}
</style>

<template>
  <div class="actions-timer-incident">
    <section v-if="isShownExecutor">
      <p class="clamp-2 bryndza mb-1">{{
        isDelegatingDivisionName 
          ? incident.delegatingDivisionName 
          : executorDepartment
      }}</p>
      <p class="anie mozzarella mb-3">Ответственный</p>
      <r-tooltip
        v-if="isShownDueDate"
        activator-hover
        color="amelie"
        title="Внутренний срок исполнения"
      >
        <template #activator>
          <div class="mb-2 d-flex align-items-center">
            <r-icon
              icon="selected"
              class="mr-2"
              fill="rocky"
            />
            <span class="mozzarella">{{ dueDate }}</span>
          </div>
        </template>
      </r-tooltip>
      <r-tooltip
        v-else
        activator-hover
        color="amelie"
        title="Направлено в работу"
      >
        <template #activator>
          <div class="mb-2 d-flex align-items-center">
            <r-icon
              icon="in-progress"
              class="mr-2"
              fill="rocky"
            />
            <span class="mozzarella">{{ requiredDuration | hours }}</span>
          </div>
        </template>
      </r-tooltip>
      <span v-if="executorName" class="clamp-1 d-flex">
        <r-icon
          class="actions-timer-incident__executor-icon mr-2"
          icon="avatar"
          fill="rocky"
        />
        <span class="mozzarella">{{ executorName }}</span>
      </span>
    </section>
    <section v-else class="actions-timer-incident__empty">
      <div>
        <r-icon
          icon="block"
          size="32"
          fill="rocky"
          class="mb-2"
        />
        <p class="mozzarella metropolis--text">Исполнителя не было</p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { STATES } from '@/constants';

export default {
  name: 'ActionsTimerIncident',
  inject: ['incident'],
  data() {
    return {
      nowDate: Date.now(),
      nowDateInterval: null
    };
  },
  created() {
    this.nowDateInterval = setInterval(() => {
      this.nowDate = Date.now();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.nowDateInterval);
  },
  computed: {
    ...mapState('directory', ['executors', 'departments']),
    ...mapState('session', ['areaCode']),
    requiredDuration() {
      return this.incident.dueDate - this.incident.executorTs;
    },
    isShownDueDate () {
      return this.$route.meta.type === STATES.SOLVED || this.$route.meta.type === STATES.REMOVED;
    },
    executorDepartment () {
      return this.incident.executorDepartment
    },
    isShownExecutor () {
      return this.executorName || this.isDelegatingDivisionName
    },
    executorName () {
      return this.incident.executorName
    },
    isDelegatingDivisionName () {
      return !!this.incident.delegatingDivisionName
    },
    dueDate () {
      return this.incident.dueDate ? this.$options.filters.dateTime(this.incident.dueDate) : 'Нет даты'
    }
  }
};
</script>

<style lang="scss" scoped>
.actions-timer-incident{
  display: flex;
  flex-direction: column;
  flex: 0 0 165px;
  width: 165px;
  margin-right: 20px;
  height: 100%;

  &__empty {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__executor {
    &-icon {
      position: relative;
      top: 3px;
    }
  }

  &::v-deep {
    .r-tooltip__wrapper,
    .r-tooltip__wrapper--arrow {
      opacity: 1;
    }
  }
}
</style>

import Vue from 'vue';
import App from './App.vue';
import './plugins/axios';
import './registerServiceWorker';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';
import { nanoid } from 'nanoid';
import filterDate from '@/plugins/filters/filterDate';
import filterNumber from '@/plugins/filters/filterNumber';
import * as linkify from 'linkifyjs';

import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import getEnv from '@/plugins/env';
import YandexMap from 'vue-yandex-maps';
import router from './router';
import store from './store';
import RirLib from './plugins/ui/RirLib';
import clickOutside from './plugins/ui/clickOutside';

Sentry.init({
  environment: getEnv('NODE_ENV'),
  dsn: getEnv('VUE_APP_SENTRY'),
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true,
      tracingOptions: {
        trackComponents: true
      },
      attachProps: true
    }),
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 1
});

const settings = {
  apiKey: getEnv('VUE_APP_MAPS_API_KEY') || '',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
};

Vue.use(YandexMap, settings);

Vue.config.productionTip = false;
Vue.use(filterDate);
Vue.use(filterNumber);
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(clickOutside);
Vue.prototype.$nanoid = nanoid;

window.ATL_JQ_PAGE_PROPS = {
  triggerFunction(showCollectorDialog) {
    window.showCollectorDialog = showCollectorDialog;
  }
};

const initKeyCloak = () => new Promise(resolve => {
  Vue.use(VueKeyCloak, {
    init: {
      onLoad: 'login-required',
      checkLoginIframe: false
    },
    config: {
      authRealm: getEnv('VUE_APP_KC_REALM'),
      authUrl: getEnv('VUE_APP_KC_AUTH'),
      authClientId: getEnv('VUE_APP_KC_CLIENT')
    },
    onReady: async keycloak => {
      store.dispatch('directory/loadDirectories');
      await store.dispatch('session/getUserInfo');
      await store.dispatch('session/getUserAreaCode');
      const role = store.getters['session/rolePlatform'];
      !role ? keycloak.logout() : resolve();
    }
  });
});

export default new Vue({
  data: () => ({
    windowWidth: window.innerWidth,
    initSession: false
  }),
  async mounted() {
    await initKeyCloak();
    this.initSession = true;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
    const collector1 = document.createElement("script");
    const collector2 = document.createElement("script");
    collector1.type  = "text/javascript";
    collector1.src   = "https://brd.rusatom.dev/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-zhtur1/807001/6411e0087192541a09d88223fb51a6a0/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=4f3f0677"; 
    collector2.type  = "text/javascript";
    collector2.src   = "https://brd.rusatom.dev/s/a6569207c5850ecf9b40ebceec2f1239-T/-zhtur1/807001/6411e0087192541a09d88223fb51a6a0/4.0.0/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=ru-RU&collectorId=4f3f0677";
    document.body.appendChild(collector1);
    document.body.appendChild(collector2);
  },
  router,
  store,
  $rir: RirLib,
  $linkify: linkify,
  // eslint-disable-next-line vue/require-render-return
  render(h) {
    if (this.initSession) {
      return h(App);
    }
  }
}).$mount('#app');

import IncidentListToolbar from '@/views/incident/toolbars/IncidentListToolbar';
import SettingsView from '@/views/settings/SettingsView';
import DefaultLayout from '@/layout/DefaultLayout';

export const settingsRoutes = [
  {
    path: '/settings',
    name: 'settings',
    components: {
      default: SettingsView,
      toolbar: IncidentListToolbar
    },
    meta: {
      layout: DefaultLayout,
      title: 'Настройки'
    }
  }
];

import mixinsCommit from '@/store/mixins/mixinsCommit';

export default {
  ...mixinsCommit,
  pushNotifications (state, payload) {
    state.notifications.push(payload);
    state.notificationCount.unread++
  },
  concatNotifications (state, payload) {
    state.notifications = state.notifications.concat(payload);
  },
  clearFilters (state) {
    state.filters = {
      pageIndex: 0,
      size: 20
    }
  }
};

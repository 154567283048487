<template>
  <div class="incident-view-card">
    <incident-author
      :class="isConsolidated ? 'mb-2' : 'mb-5'"
      :incident="incident"
      @goToIncident="goToIncident"
    />

    <div v-if="isConsolidated">
      <div class="d-flex align-items-center mb-2">
        <div class="d-flex align-items-center">
          <span class="tulum">{{ isShownDelegationAction
            ? incident.accountChannelName
            : (incident.delegationaccountChannelName || incident.accountChannelName) }}
          </span>
        </div>
        
        <div class="incident-view-card__source ml-6">
          <get-source-message
            class="mr-2"
            :incident="incident"
          />
        </div>
      </div>
      <due-dates
        :incident="incident"
        class="mb-5"
      />
    </div>

    <div class="incident-view-card__post mb-6">
      <accordion post>
        <div
          class="schindler--text parmigiano ekas"
          :style="'white-space: pre-wrap'"
          v-html="getText(incident.text)"
        />
      </accordion>
      <div
        class="incident-view-card__post-files mt-6"
        v-if="incident.attachments && incident.attachments.length"
      >
        <span class="schindler--text mb-2 kleodora anie">Прикреплённые файлы</span>
        <FileUploader
          :value="incident.attachments"
          @includeAttach="includeAttach"
          :isButtonSimple="!!executionType"
          toggler
        />
      </div>
      <div
        v-if="incident.note"
        class="mt-6"
      >
        <div class="schindler--text mb-2 kleodora anie">
          Примечание:
        </div>
        <accordion post>
          <div class="schindler--text parmigiano ekas">
            {{ incident.note }}
          </div>
        </accordion>
      </div>
      <div
        v-if="isConsolidated"
        class="incident-view-card__more sulguni lebowski--text mt-6"
        @click="showMore"
      >
        Подробнее
      </div>
    </div>
  </div>
</template>

<script>
import IncidentAuthor from '@/views/incident/components/IncidentAuthor';
import Accordion from '@/components/Accordion';
import FileView from '@/components/FileView';
import DueDates from '@/views/incident/components/DueDates';
import GetSourceMessage from '@/views/incident/components/GetSourceMessage';
import { ACTIONS } from '@/constants';
import { mapState } from 'vuex';
import FileUploader from '@/components/FileUploader'

export default {
  name: 'IncidentViewCard',
  components: {
    IncidentAuthor,
    Accordion,
    DueDates,
    GetSourceMessage,
    FileUploader
  },
  props: {
    incident: {
      type: Object,
      default: null
    },
    isConsolidated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('incident', ['executionType']),
    ...mapState('session', ['areaCode']),
    isShownIncludeAttachButton() {
      return this.executionType && [
        ACTIONS.COMPLETE_WITHOUT_RESPONSE,
        ACTIONS.COMPLETE_APPEAL,
        ACTIONS.EXECUTE_APPEAL,
        ACTIONS.EXECUTE_APPEAL_WITH_AN_INTERMEDIATE_ANSWER,
        ACTIONS.WAIT_FOR_APPEAL_ACTIVATION,
        ACTIONS.WAIT_FOR_ANSWER,
        ACTIONS.ADD_NOTE
      ].includes(this.executionType);
    },
    isShownDelegationAction() {
      return !!this.incident.delegationAction && this.incident.areaCode === this.areaCode;
    }
  },
  methods: {
    goToIncident(route) {
      this.$emit('goToIncident', route);
    },
    includeAttach(file) {
      this.$emit('includeAttach', file);
    },
    getText(text) {
      return text?.replace(/<(?!br\s*\/?)[^>]+>/g, '');
    },
    selectFile(file) {
      this.$root.$modal.openModal(FileView, { file });
    },
    showMore() {
      const path = this.$route.path?.split('/');
      if (this.incident.consolidatedToAppealId) {
        this.goToIncident(`/${path[1]}/${this.incident.consolidatedToAppealId}/more/${this.incident.id}`);
      } else {
        this.goToIncident(`/${path[1]}/${this.incident.id}/more`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.schindler--text{
  color: #271402!important;
}
.incident-view-card {
  &__source {
    display: flex;
    align-items: center;

    &-link {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &-url {
      display: flex;
      align-items: center;

      span {
        max-width: 300px;
      }
    }
  }

  &__more {
    cursor: pointer;
    transition: .3s ease;

    &:hover {
      opacity: 0.72;
    }
  }

  &__post {
    position: relative;
    padding: 20px;
    background-color: var(--rir-jaws);
    border-radius: 8px;

    &::before {
      content: '';
      height: 24px;
      width: 24px;
      background-color: var(--rir-jaws);
      border-radius: 2px;
      position: absolute;
      top: -5px;
      left: 12px;
      transform: rotate(-45deg);
    }

    &-files {
      display: flex;
      flex-direction: column;

      &-icon {
        cursor: pointer;
      }

      &::v-deep {
        .r-file-list {
          &__item {
            background-color: rgba(#D06E0B, 0.08);
          }

          &__arrow {
            path {
              fill: var(--rir-lebowski);
            }
          }

          &__toggler {
            background-color: rgba(#D06E0B, 0.08);
          }

          &__title {
            width: 100%;
            overflow: hidden;
            color: var(--rir-schindler);
          }

          &__subtitle {
            color: var(--rir-lebowski);
          }
        }
      }
    }
  }
}
</style>

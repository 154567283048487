var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"required-duration"},[_c('FormGroup',{attrs:{"error":_vm.error,"errorMessage":_vm.errorMessage}},[_c('r-select',{staticClass:"mb-6",attrs:{"label":"Срок реакции","titleValue":"value","idValue":"id","items":_vm.types,"disabled":_vm.readonly},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),(_vm.type === 'select')?_c('r-popover',{staticClass:"required-duration__popover",attrs:{"contentWidthActivator":"","disabled":_vm.readonly,"position":"left-bottom"},on:{"close":function($event){_vm.isSelect = false}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"required-duration__input"},on),[_c('r-input',{class:[
              { disabled: _vm.readonly },
              { error: _vm.error }
            ],attrs:{"label":"Время до исполнения","value":_vm.formatDate,"after-icon":_vm.isSelect ? 'arrow-up' : 'arrow-down',"isClear":false,"readonly":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.changeViewList.apply(null, arguments)}}})],1)]}},{key:"content",fn:function(){return [_c('reaction-time',{staticClass:"required-duration__time",attrs:{"time":_vm.time,"button":""},on:{"select":_vm.submitReactionTime,"change":_vm.changeReactionTime}}),_c('div',{staticClass:"required-duration__items"},[(_vm.itemsList && _vm.itemsList.length)?_c('div',_vm._l((_vm.itemsList),function(item){return _c('r-list-item',{key:item.forUuId,class:{
                active: item.forUuId === _vm.modelId,
                disabled: item.disabled
              },attrs:{"title":item[_vm.textValue]},nativeOn:{"click":function($event){!item.disabled && _vm.selectItem(item)}}})}),1):_c('div',{staticClass:"required-duration__empty"},[_vm._v(" Список пустой ")])])]},proxy:true}],null,false,2672804952)}):_vm._e(),(_vm.type === 'calendar')?_c('FormDateTimePicker',{attrs:{"disabled":_vm.readonly,"startDate":_vm.startDate,"endDate":_vm.endDate,"error":_vm.error},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
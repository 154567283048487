export default {
  async getIncidents({ rootGetters, state, commit }, payload) {
    const { pageIndex } = state.filters;
    commit('setProperty', { name: 'isLoading', value: true });
    commit('setProperty', { name: 'isActionsLoading', value: true });
    const incidents = await rootGetters.POST('ui/appeal', {
      ...state.filters,
      ...payload
    });
    if (incidents?.length) {
      !pageIndex
        ? commit('setProperty', { name: 'incidents', value: incidents })
        : commit('concatIncident', incidents);
      commit('setActions');
    }
    commit('setProperty', { name: 'isLoading', value: false });
  },
  async getIncidentsByAuthor({ rootGetters, commit }, payload) {
    const { authorId } = payload;
    return await rootGetters.POST('ui/appeal', { authorId });
  },
  async getIncident({ rootGetters, commit }, payload) {
    const { id } = payload;
    return await rootGetters.GET(`ui/appeal/${id}`).then(data => {
      commit('setProperty', { name: 'incident', value: data });
    });
  },
  async getIncidentCount({ commit, rootGetters, state }, payload) {
    return rootGetters.POST('ui/appeal/count', {
      ...state.filters,
      ...payload,
      pageIndex: undefined,
      size: undefined,
    }).then(data => {
      const res = typeof data === 'object' ? 0 : data
      commit('setProperty', { name: 'incidentCount', value: res });
    });
  },
  async changeIncident({ rootGetters, commit }, payload) {
    const { id } = payload;
    return await rootGetters.PUT(`ui/appeal/${id}`, payload);
  },
  async getIncidentMessaging({ rootGetters, commit }, payload) {
    const { id } = payload;
    return await rootGetters.GET(`ui/appeal/${id}/messaging`).then(data => {
      commit('setProperty', { name: 'messages', value: data });
    });
  },
  async getIncidentActions({ rootGetters, commit }, payload) {
    const { id } = payload;
    return await rootGetters.GET(`ui/appeal/${id}/actions`).then(data => {
      commit('setProperty', { name: 'appealActions', value: data });
    });
  },
  async createIncident({ rootGetters }, payload) {
    return await rootGetters.POST('ui/appeal/create', payload);
  },
  async returnIncident({ rootGetters, commit }, payload) {
    await rootGetters.POST('ui/appeal/ignore', payload);
    commit('removeEntityArray', { name: 'incidents', value: payload.id });
  },
  async restoreIncident({ rootGetters, commit }, payload) {
    const { id } = payload;
    await rootGetters.POST(`ui/appeal/${id}/restore`);
  },
  async getWorkProgress({ rootGetters, commit }, payload) {
    const { id } = payload;
    return await rootGetters.GET(`ui/appeal/${id}/work-progress`);
  },
  async getAdditional({ rootGetters, commit }, payload) {
    const { id } = payload;
    return await rootGetters.GET(`ui/appeal/${id}/additional`);
  },
  async moveIncident({ rootGetters, commit }, payload) {
    return await rootGetters.POST('ui/appeal/move', payload);
  },
  async getPublishers({ rootGetters, commit }, payload) {
    const { id } = payload;
    return await rootGetters.GET(`ui/appeal/${id}/publisher`);
  },
  async getExecutor({ rootGetters, commit }, payload) {
    const { id } = payload;
    return await rootGetters.GET(`executor/${id}`);
  },
  async getIncidentTypes({ commit, rootGetters }, payload) {
    return rootGetters.GET('ui/account-channel', payload).then(data => {
      commit('setProperty', { name: 'appealTypes', value: data });
    });
  },
  async addNote({ rootGetters }, payload) {
    const { id, data } = payload;
    return await rootGetters.POST(`ui/appeal/${id}/notification`, data);
  },
  async delegate({ rootGetters }, payload) {
    return await rootGetters.POST(`ui/appeal/${payload.appealId}/delegate`, payload);
  },
  async markAsRead({ rootGetters, commit }, payload) {
    return await rootGetters.POST(`ui/appeal/${payload}/mark`);
  },
  async createRawIncident({ rootGetters }, payload) {
    const { id } = payload;
    return await rootGetters.POST(`ui/appeal-raw/${id}`);
  },
  async deleteRawIncident({ rootGetters }, payload) {
    const { id } = payload;
    return await rootGetters.DELETE(`ui/appeal-raw/${id}`);
  },
  async consolidateIncidents({ rootGetters }, payload) {
    const { id, data } = payload;
    return await rootGetters.POST(`ui/appeal/${id}/consolidate`, data);
  },
  async separateIncidents({ rootGetters }, payload) {
    const { id, data } = payload;
    return await rootGetters.POST(`ui/appeal/${id}/separate`, data);
  },
  async addTags({ rootGetters }, payload) {
    const { id, data } = payload;
    return await rootGetters.POST(`ui/appeal/${id}/tags`, data);
  }
};

<template>
  <div
    v-if="isShown"
    class="scroll-to-top-button"
    :class="[
      {'scroll-to-top-button--show': scroll > 150}
    ]"
    @click="scrollToTop"
  >
    <r-icon
      icon="arrow-bold-up"
      fill="harakiri"
      size="16"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ScrollToTopButton',
  computed: {
    ...mapState('apps', ['isSidebarOpen', 'scroll']),
    isShown () {
      return this.$route.name !== 'create'
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
body.widest .RLayoutDashboard__menu.compact .scroll-to-top-button {
  left: 60px;
}
.scroll-to-top-button {
  box-shadow: 0 4px 16px 0 var(--rir-hamiltonHover);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 16px;
  left: 344px;
  z-index: 500;
  width: 36px;
  height: 36px;
  background: #eff0f2; //var(--rir-godfather);
  border-radius: 50%;
 // transition: all 0.3s ease;
  cursor: pointer;
  transform: translate(-50%, 50%);
  pointer-events: none;
  opacity: 0;

  &--show {
    pointer-events: auto;
    transform: translate(-50%, 0);
    opacity: 1;
  }

  &--closed {
    left: 60px;
  }

  &:hover {
    transform: translate(-50%, -10%);
  }

  &:active {
    transition: 0.1s;
    transform: translate(-50%, 5%);
  }

  &__rotate {
    transform: rotate(-90deg);
  }
}
</style>
